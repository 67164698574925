import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent } from 'react';
import { MachineLocationForm } from 'types/feature/machine/machine';
import { ControllerProps } from './controller.type';

const DescriptionController = (props: ControllerProps) => {
  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setMachineLocationForm((prev: MachineLocationForm) => ({
      ...prev,
      description: value,
    }));
  };
  return (
    <div className='w-full p-5 border-b'>
      <FormControl
        name='상세위치'
        gap='gap-2.5'
        direction='column'
        control={<Input placeholder='상세 위치를 입력해주세요' onChange={handleChangeDescription} />}
      />
    </div>
  );
};

export default DescriptionController;
