import { TextType } from 'components/common/title/Title';
import useAccountInfo from 'hooks/common/useAccountInfo';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAccountInfoStore from 'stores/accountInfo';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';
import WorkspaceCard from './item/WorkspaceCard';

const WorkspaceListContainer = () => {
  // hook
  const { getWorkspaces } = useWorkspace();
  const navigate = useNavigate();
  const { getAccountInfo } = useAccountInfo();
  // state
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [viewAll, setViewAll] = useState<boolean>(false);
  const [isAccountInfoError, setAccountInfoError] = useState(false);
  const [isAccountLoading, setAccountLoading] = useState(true);
  // store
  const accountInfoStore = useAccountInfoStore();

  /**
   * 워크스페이스 목록을 조회한다.
   */
  const fetchWorkspaces = async (serviceOnly: boolean) => {
    const result = await getWorkspaces();
    if (!result) return;
    const filteredWorkspace = serviceOnly ? result.filter(workspace => workspace.useType === 'SERVICE') : result;
    setWorkspaces(filteredWorkspace);
  };

  /**
   * view all 여부에 따라 워크스페이스 목록을 필터링한다.
   */
  useEffect(() => {
    fetchWorkspaces(!viewAll);
  }, [viewAll]);

  /**
   * view all 을 클릭한다.
   */
  const onClickViewAll = () => {
    setViewAll(prev => !prev);
  };

  const fetchAccountInfo = async () => {
    try {
      setAccountLoading(true);
      await getAccountInfo();
    } catch (error) {
      console.error(error);
      setAccountInfoError(true);
    } finally {
      setAccountLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  return (
    <div className='flex items-center justify-center w-full h-full py-10'>
      <section className='flex flex-col gap-[12px] w-[1400px] h-full'>
        {/* 서비스별 필터 */}
        <div className='flex items-center justify-end h-6'>
          <div className='flex items-center gap-2'>
            {/* 진짜 체크박스 */}
            <input type='checkbox' className='hidden peer' checked={viewAll} readOnly />
            {/* 스타일링을 위한 체크박스 */}
            <div
              onClick={onClickViewAll}
              className='cursor-pointer w-5 h-5 transition-all bg-gray-200 border-2 border-gray-300 rounded-sm peer-checked:bg-[#92D5DE] peer-checked:border-[#92D5DE]'
            >
              {/* 체크표시 */}
              {viewAll && (
                <svg
                  className='w-4 h-4'
                  fill='none'
                  stroke='#272A36'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' d='M5 13l4 4L19 7' />
                </svg>
              )}
            </div>
            {/* 체크박스 라벨 */}
            <label onClick={onClickViewAll} htmlFor='checkbox' className={`cursor-pointer ${TextType.h4_bold}`}>
              View All
            </label>
          </div>
        </div>

        {/* 워크스페이스 목록 */}
        <div className='grid w-full h-full grid-cols-4 gap-5 large:grid-cols-4 medium:grid-cols-2 small:grid-cols-2'>
          {workspaces.map(workspace => (
            <WorkspaceCard key={workspace.id} workspace={workspace} />
          ))}

          {!isAccountLoading && (
            <>
              {/* 워크스페이스가 없고 accountInfo를 불러오지 못했거나 불러왔지만 어드민이 아닐경우 관리자에게 문의 */}
              {workspaces.length < 1 && (isAccountInfoError || !accountInfoStore.accountInfo?.admin) && (
                <div
                  className='w-full h-[214px] cursor-default rounded-xl flex flex-col justify-center items-center
                      bg-bg-f1 shadow gap-3 border-2 border-bg-f1'
                >
                  <pre className={`text-gray-cc ${TextType.h3_bold} font-sans text-center`}>
                    {`사용할 수 있는 워크스페이스가 없습니다.\n관리자에게 문의해주세요.`}
                  </pre>
                </div>
              )}

              {/* 어드민일때만 새 워크스페이스 생성 가능 */}
              {accountInfoStore.accountInfo?.admin && (
                <div
                  onClick={() => navigate(RoutePath.workspaceNew)}
                  className='w-full h-[214px] cursor-pointer rounded-xl flex flex-col justify-center items-center
                 bg-bg-f1 shadow border-2 border-bg-f1 group gap-3
                 hover:bg-state-blue_bg hover:border-state-blue'
                >
                  <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M8 1H6V6H1V8H6V13H8V8H13V6H8V1Z'
                      className='fill-[#CCCCCC] group-hover:fill-primary-DA_Blue_light'
                    />
                  </svg>

                  <span className={`text-gray-cc group-hover:text-primary-DA_Blue_light ${TextType.h3_bold}`}>
                    새 워크스페이스
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};
export default WorkspaceListContainer;
