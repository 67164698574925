import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import Switch from 'components/common/switch/Switch';
import Title, { TextType } from 'components/common/title/Title';
import useModal from 'hooks/common/useModal';
import usePoint from 'hooks/feature/point/usePoint';
import { ChangeEvent, Fragment, useState } from 'react';
import { HoursType, Point } from 'types/feature/workspace/workspace';
import AltText from './alt-text/AltText';
import TimeForm from './time/TimeForm';
import ConfirmPopup from 'components/feature/workspace/popup/ConfirmPopup';
import AlertPopup from 'components/feature/workspace/popup/AlertPopup';

type Props = {
  point: Point;
  pointId: string;
  fetchPoint: (id: string) => void;
};

const BusinessHour = ({ point, pointId, fetchPoint }: Props) => {
  const { openModalPopup, openConfirm, openAlert, closeModalPopup } = useModal();
  const { patchPointOperation } = usePoint();
  const [pointForm, setPointForm] = useState<Point>(point);

  /**
   * 운영시간 사용여부를 변경한다.
   * @param {boolean} hoursUsed 사용 여부
   */
  const onChangeHoursUsed = (hoursUsed: boolean) => {
    setPointForm({
      ...pointForm,
      operation: {
        ...pointForm.operation,
        hoursUsed,
      },
    });
  };

  /**
   * 운영시간 노출 타입을 변경한다.
   */
  const onChangeHoursType = (e: ChangeEvent<HTMLInputElement>) => {
    setPointForm({
      ...pointForm,
      operation: {
        ...pointForm.operation,
        hoursType: e.target.value as HoursType,
      },
    });
  };

  // 저장
  const handleSave = async () => {
    try {
      await patchPointOperation(pointForm.id, {
        hoursType: pointForm.operation.hoursType,
        hoursText: pointForm.operation.hoursText,
        hoursUsed: pointForm.operation.hoursUsed,
        hoursStart: pointForm.operation.hoursStart,
        hoursEnd: pointForm.operation.hoursEnd,
      });
      await fetchPoint(pointForm.id);

      closeModalPopup('CONFIRM');
    } catch (error) {
      if (error instanceof AxiosError && error.status === 400) {
        closeModalPopup('CONFIRM');
        openModalPopup('ALERT');
      }
    }
  };

  const handlePoint = (point: Point) => {
    setPointForm(point);
  };
  const props = {
    point: pointForm,
    handlePoint,
  };

  return (
    <>
      <Title text='운영시간 설정' textType={TextType.h3_bold} />

      <div className='flex flex-col gap-10'>
        <FormControl
          required
          name='운영시간 사용여부'
          control={
            <Switch
              color={pointForm.operation.hoursUsed ? 'BLUE' : 'WHITE'}
              type='USE'
              value={pointForm.operation.hoursUsed}
              onChangeOption={onChangeHoursUsed}
            />
          }
        />

        {/* 운영시간을 사용할 때만 노출 */}
        {pointForm.operation.hoursUsed && (
          <FormControl
            name='노출 타입'
            required
            control={
              <div className='flex flex-col w-[100%] border-t border-b'>
                {hourTypeComponents.map((hourType, index) => {
                  const Component = hourType.component;
                  const checked = pointForm.operation.hoursType === hourType.type;
                  return (
                    <Fragment key={hourType.type}>
                      <section className={`flex items-center w-full px-4 ${checked && 'bg-state-blue_bg'}`}>
                        {/* 라디오 버튼 */}
                        <input
                          id={`hourType-${hourType.type}`}
                          type='radio'
                          value={hourType.type}
                          checked={checked}
                          onChange={onChangeHoursType}
                          className='w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500'
                        />

                        <label
                          htmlFor={`hourType-${hourType.type}`}
                          className='inline-flex items-center w-full cursor-pointer'
                        >
                          <section className='flex items-center w-full p-8'>
                            {/* 제목 */}
                            <span className={`${TextType.h4} text-gray-77 w-[80px] text-nowrap`}>{hourType.title}</span>
                            {/* 컴포넌트 */}
                            <Component {...props} />
                          </section>
                        </label>
                      </section>

                      {index !== hourTypeComponents.length - 1 && <div className='w-full border-b' />}
                    </Fragment>
                  );
                })}
              </div>
            }
          />
        )}
      </div>

      <div className='flex justify-end'>
        <Button text='저장' color={ButtonColor.point} size={80} onClick={() => openModalPopup('CONFIRM')} />
      </div>

      {openConfirm && (
        <ConfirmPopup
          message='저장 하시겠습니까?'
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={handleSave}
        />
      )}
      {openAlert && <AlertPopup message='오류가 발생했습니다.' onClick={() => closeModalPopup('ALERT')} />}
    </>
  );
};

export default BusinessHour;

// 노출 타입 컴포넌트 데이터
const hourTypeComponents = [
  {
    type: 'DATE',
    title: '시간',
    component: TimeForm,
  },
  {
    type: 'TEXT',
    title: '텍스트',
    component: AltText,
  },
];
