import FormControl from 'components/common/form-control/FormControl';
import AlertModal from 'components/common/modal/alert/AlertModal';
import SimpleSelectBox from 'components/common/select/SimpleSelectBox';
import { TextType } from 'components/common/title/Title';
import { SCREEN_TYPE_FILTERS } from 'constants/filters';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ScreenType, ScreenTypeCodeKo } from 'types/feature/content/contents';
import { handle400Error } from 'utils/error/handle400Error';
import { ControllerProps } from './controller.type';

const ScreenTypeEditController = (props: ControllerProps) => {
  // hook
  const { machineId } = useParams();
  const { patchMachine } = useMachine();
  // state
  const [editMode, setEditMode] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);

  const onChange = (value: string) => {
    props.setScreenType(value as ScreenType);
  };

  const updateScreenType = async () => {
    try {
      if (machineId) {
        await patchMachine(machineId, {
          screenType: props.screenType,
        });
        await props.fetchMachine(machineId);
        setEditMode(false);
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
    }
  };

  const onClickCancel = () => {
    setEditMode(false);
    props.setScreenType(props.machine.screenType);
  };

  return (
    <div className='p-5 border-b'>
      <FormControl
        name='기기 타입'
        labelColor='text-gray-77'
        labelStyle={TextType.h4_bold}
        control={
          // 수정 모드
          editMode ? (
            <div className='flex gap-2.5 items-center justify-between'>
              <SimpleSelectBox
                height='h-[30px]'
                textType={TextType.h5}
                selected={props.screenType}
                options={SCREEN_TYPE_FILTERS}
                onChange={onChange}
              />

              <div className='flex items-center gap-2.5'>
                <div className='w-6 h-6' onClick={updateScreenType}>
                  <img className='h-full' src={ICON.SUCCESS_BUTTON} alt='edit' />
                </div>
                <div className='w-6 h-6' onClick={onClickCancel}>
                  <img className='h-full' src={ICON.CANCEL_BUTTON} alt='edit' />
                </div>
              </div>
            </div>
          ) : (
            // view 모드
            <div className='flex gap-2.5 items-center justify-between'>
              <div className='px-2 w-[100px] h-[30px] flex items-center justify-start bg-bg-f9 text-h5 text-gray-99'>
                {ScreenTypeCodeKo[props.machine.screenType]}
              </div>
              <div className='h-6' onClick={() => setEditMode(!editMode)}>
                <img className='h-full' src={ICON.PENCIL_BUTTON} alt='edit' />
              </div>
            </div>
          )
        }
      />
      {openAlertPopup && (
        <AlertModal
          message='기기 정보를 수정하던 중 에러가 발생했습니다.'
          closeModal={() => setOpenAlertPopup(false)}
        />
      )}
    </div>
  );
};

export default ScreenTypeEditController;
