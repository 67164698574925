import { useEffect, useState } from 'react';
import { ClosedDayType } from 'types/feature/workspace/workspace';
import HolidayGuide from './guide/HolidayGuide';
import { convertDate, convertToDate, firstDayOfMonth } from 'utils/date/dateUtils';
import SettingHoliday from './setting/SettingHoliday';
import usePoint from 'hooks/feature/point/usePoint';
import ConfirmPopup from 'components/feature/workspace/popup/ConfirmPopup';
import dayjs from 'dayjs';
import Calendar from './calrender/Calendar';

type Props = {
  pointId: string;
};
export type Option = { id: ClosedDayType; text: string };

const HolidayForm = ({ pointId }: Props) => {
  // state
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const [selectedType, setSelectedType] = useState<ClosedDayType>('SOON');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [calendarDays, setCalendarDays] = useState<Date[]>([]); // 달력
  const [holiDays, setHoliDays] = useState<Date[]>([]); // 휴일

  // hook
  const { addClosedDay, deleteClosedDay, patchClosedDayType, getCalender, getHoliDays } = usePoint();

  // 날짜 초기 설정
  const fetchPoint = async (currentDate: Date, selectedType: ClosedDayType) => {
    try {
      const targetMonth = firstDayOfMonth(currentDate);
      const calendar = await getCalender(pointId, targetMonth);

      // 휴무일을 날짜로 변환 후 시간순으로 정렬
      const dates = calendar.days
        .map((day: string) => convertToDate(day))
        .sort((a: Date, b: Date) => {
          return a.getTime() - b.getTime();
        });
      setCalendarDays(dates);

      const holiDay = await getHoliDays(pointId, selectedType);
      const days = holiDay.days
        .map((day: string) => convertToDate(day))
        .sort((a: Date, b: Date) => {
          return a.getTime() - b.getTime();
        });
      setHoliDays(days);
    } catch (error) {
      console.error(error);
    }
  };

  // 달력 선택 시
  const handleAddClosedDay = async (date: Date) => {
    // 같은게 있으면 삭제
    if (calendarDays.some(calendarDay => dayjs(calendarDay).isSame(dayjs(date), 'day'))) {
      handleDeleteDay(convertDate(date));
    } else {
      // 없으면 추가
      // 휴무일 4개 넘은 경우 리턴
      if (calendarDays.length >= 4) return;
      try {
        await addClosedDay(pointId, {
          closedDay: convertDate(date),
        });
        await fetchPoint(date, selectedType);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDeleteDay = async (date: string) => {
    try {
      await deleteClosedDay(pointId, {
        closedDay: date.replaceAll('.', ''),
      });
      await fetchPoint(currentDate, selectedType);
    } catch (error) {
      console.error(error);
    }
  };

  const updateChangeType = async () => {
    try {
      await patchClosedDayType(pointId, { closedDayType: selectedType });
      await fetchPoint(currentDate, selectedType);
      setOpenConfirm(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPoint(currentDate, selectedType);
  }, []);

  const handleClickItem = async (exposureOption: Option) => {
    if (exposureOption?.id) {
      setSelectedType(exposureOption.id);
      // 재 조회
      await fetchPoint(currentDate, exposureOption.id);
    }
  };

  const handleMonthChange = async (date: Date) => {
    setCurrentDate(date);
    await fetchPoint(date, selectedType);
  };

  return (
    <>
      <div className='flex flex-col gap-2.5'>
        <div className='flex h-full gap-5'>
          <div>
            {/* 가이드 */}
            <HolidayGuide />
            {/* 달력 */}
            <Calendar
              startDate={startDate}
              handleAddClosedDay={handleAddClosedDay}
              calendarDays={calendarDays}
              handleMonthChange={handleMonthChange}
            />
          </div>
          {/* border-r-gray-cc */}
          {/* <div className='border-gray-cc' style={{ borderRight: '1px solid #CCCCCC' }} /> */}
          {/* 설정 */}
          <SettingHoliday
            selectedCalendarDates={holiDays}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            handleDeleteDay={handleDeleteDay}
            openConfirm={openConfirm}
            setOpenConfirm={setOpenConfirm}
            handleClickItem={handleClickItem}
          />
        </div>
      </div>
      {openConfirm && (
        <ConfirmPopup
          message='저장 하시겠습니까?'
          onClickCancel={() => setOpenConfirm(false)}
          onClickOk={updateChangeType}
        />
      )}
    </>
  );
};

export default HolidayForm;
