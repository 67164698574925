import BasicSetting from 'components/feature/workspace/setting/basic/BasicSetting';
import LanguageSettingContainer from 'components/feature/workspace/setting/language/LanguageSettingContainer';
import PointSettingContainer from 'components/feature/workspace/setting/point/PointSettingContainer';
import ThemeSettingContainer from 'components/feature/workspace/setting/theme/ThemeSettingContainer';
import ErrorPage from 'pages/ErrorPage';
import NotFountPage from 'pages/NotFountPage';
import GuideBook from 'pages/guide/GuideBook';
import BasicGuide from 'pages/guide/page/BasicGuide';
import ContentsGuide from 'pages/guide/page/ContentsGuide';
import LoginGuide from 'pages/guide/page/LoginGuide';
import ScheduleGuide from 'pages/guide/page/ScheduleGuide';
import StoreGuide from 'pages/guide/page/StoreGuide';
import WorkspaceGuide from 'pages/guide/page/WorkspaceGuide';
import HomePage from 'pages/home/HomePage';
import MachineAddPage from 'pages/machine/MachineAddPage';
import MachineDetailPage from 'pages/machine/MachineDetailPage';
import MachineManagePage from 'pages/machine/MachineManagePage';
import MenuContentsAddPage from 'pages/menu/MenuContentsAddPage';
import MenuContentsEditPage from 'pages/menu/MenuContentsEditPage';
import MenuContentsPage from 'pages/menu/MenuContentsPage';
import PlanPage from 'pages/plan/PlanPage';
import ScreensaverContentAddPage from 'pages/screensaver/content/ScreensaverContentAddPage';
import ScreensaverContentDetailPage from 'pages/screensaver/content/ScreensaverContentDetailPage';
import ScreensaverContentsPage from 'pages/screensaver/content/ScreensaverContentsPage';
import ScreensaverSchedulePage from 'pages/screensaver/schedule/ScreensaverSchedulePage';
import SettingPage from 'pages/setting/SettingPage';
import CategoryUsageStatsPage from 'pages/statistics/CategoryUsageStatsPage';
import MachineUsageStatsPage from 'pages/statistics/MachineUsageStatsPage';
import TenantUsageStatsPage from 'pages/statistics/TenantUsageStatsPage';
import TotalUsageStatsPage from 'pages/statistics/TotalUsageStatsPage';
import TenantInfoPage from 'pages/tenant/TenantInfoPage';
import WorkspaceAddPage from 'pages/workspace/WorkspaceAddPage';
import WorkspaceListPage from 'pages/workspace/WorkspaceListPage';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import { RoutePath } from '../types/common/paths';

export const router = createBrowserRouter([
  {
    path: RoutePath.workspaces,
    element: <App />,
    errorElement: <NotFountPage />,
    children: [
      { index: true, handle: '워크스페이스', path: RoutePath.workspaces, element: <WorkspaceListPage /> },
      { handle: '새 워크스페이스', path: RoutePath.workspaceNew, element: <WorkspaceAddPage /> },
      { handle: '플랜', path: RoutePath.plan, element: <PlanPage /> },

      {
        handle: '대시보드',
        path: RoutePath.dashboard,
        element: <HomePage />,
      },

      {
        handle: '기본정보 관리',
        path: RoutePath.setting,
        element: <SettingPage />,
        children: [
          { index: true, handle: '기본', path: RoutePath.basicSetting, element: <BasicSetting /> },
          { handle: '건물 관리', path: RoutePath.pointSetting, element: <PointSettingContainer /> },
          { handle: '언어', path: RoutePath.languageSetting, element: <LanguageSettingContainer /> },
          { handle: '테마', path: RoutePath.themeSetting, element: <ThemeSettingContainer /> },
        ],
        // children: [{ path: RoutePath.pointBasicSetting, element: <SettingPage /> }],
      },
      {
        handle: '스크린세이버',
        path: RoutePath.screensaver,
        children: [
          {
            path: RoutePath.content,
            children: [
              { index: true, handle: '콘텐츠 관리', path: RoutePath.content, element: <ScreensaverContentsPage /> },
              { handle: '콘텐츠 등록', path: RoutePath.contentAdd, element: <ScreensaverContentAddPage /> },
              { handle: '콘텐츠 상세', path: RoutePath.contentDetail, element: <ScreensaverContentDetailPage /> },
            ],
          },
          { handle: '스케줄 관리', path: RoutePath.schedule, element: <ScreensaverSchedulePage /> },
        ],
      },
      {
        handle: 'POI',
        path: RoutePath.tenantInfo,
        element: <TenantInfoPage />,
        // children: [
        //   { index: true, handle: '매장정보 관리', path: RoutePath.tenantInfo, element: <TenantInfoPage /> },
        // { handle: '변경정보 관리', path: RoutePath.tenantChangedInfo, element: <TenantChangedInfoPage /> },
        // ],
      },
      {
        handle: '사용자 메뉴',
        path: RoutePath.customPages,
        element: <MenuContentsPage />,
        children: [
          {
            path: RoutePath.pointCustomPages,
            element: <MenuContentsPage />,
            children: [
              {
                path: RoutePath.pointCustomBoards,
                element: <MenuContentsPage />,
              },
            ],
          },
        ],
      },
      { handle: '콘텐츠 등록', path: RoutePath.pointCustomBoardAdd, element: <MenuContentsAddPage /> },
      { handle: '콘텐츠 상세', path: RoutePath.pointCustomBoardDetail, element: <MenuContentsEditPage /> },

      {
        handle: '설치형 기기 관리',
        path: RoutePath.machines,
        children: [
          { index: true, element: <MachineManagePage /> },
          { handle: '기기 등록', path: RoutePath.machineAdd, element: <MachineAddPage /> },
          { handle: '기기 상세', path: RoutePath.machineDetail, element: <MachineDetailPage /> },
        ],
      },

      {
        handle: '통계',
        path: RoutePath.stats,
        children: [
          { index: true, handle: '전체 사용량', path: RoutePath.totalUsageStats, element: <TotalUsageStatsPage /> },
          { handle: '기기별 사용량', path: RoutePath.machineUsageStats, element: <MachineUsageStatsPage /> },
          { handle: '인기 매장 순위', path: RoutePath.tenantUsageStats, element: <TenantUsageStatsPage /> },
          { handle: '인기 카테고리', path: RoutePath.categoryUsageStats, element: <CategoryUsageStatsPage /> },
        ],
      },

      {
        path: RoutePath.guide,
        element: <GuideBook />,
        children: [
          { index: true, handle: '', path: RoutePath.guide, element: <LoginGuide /> },
          { index: true, handle: '', path: RoutePath.loginGuide, element: <LoginGuide /> },
          { handle: '', path: RoutePath.workspaceGuide, element: <WorkspaceGuide /> },
          { handle: '', path: RoutePath.basicGuide, element: <BasicGuide /> },
          { handle: '', path: RoutePath.contentsGuide, element: <ContentsGuide /> },
          { handle: '', path: RoutePath.scheduleGuide, element: <ScheduleGuide /> },
          { handle: '', path: RoutePath.storeGuide, element: <StoreGuide /> },
        ],
      },
    ],
  },

  { path: RoutePath.errorWithWorkspaceId, element: <ErrorPage /> },
  { path: RoutePath.error, element: <ErrorPage /> },
  { path: RoutePath.notFoundWithWorkspaceId, element: <NotFountPage /> },
  { path: RoutePath.notFound, element: <NotFountPage /> },
]);
