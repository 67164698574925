import { useState } from 'react';
import { InputProps, InputSize, InputText } from './Input.type';
import LimitLength from '../word-length/LimitLength';

const Input = ({
  placeholder,
  type,
  readOnly,
  value,
  defaultValue,
  min,
  max,
  onChange,
  id,
  autoComplete,
  inputRef,
  name,
  textStyle = InputText.default,
  disabled,
  icon,
  inputStyle,
  size = 'default',
  useWordLimit,
  limit,
}: InputProps) => {
  const [onFocus, setOnFocus] = useState(false);

  return (
    <div
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
      className={`flex items-center justify-center gap-2 h-9 bg-white ${
        readOnly ? 'border-none' : 'border'
      } ${inputStyle} ${InputSize[size]} ${onFocus ? 'border-state-blue' : 'border-gray-99'}`}
    >
      {icon && <div className='flex items-center justify-center pl-3'>{icon}</div>}
      <input
        className={`
        ${readOnly && 'border-0'}
        ${textStyle}
      text-gray-44
      placeholder:text-gray-cc 
        placeholder:text-body1
        placeholder:font-normal
        focus:outline-none
        border-none w-full h-full px-2
        `}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
        id={id}
        min={min}
        max={max}
        onChange={onChange}
        defaultValue={defaultValue}
        readOnly={readOnly}
        type={type ?? 'text'}
        value={value}
        ref={inputRef}
        name={name}
        maxLength={limit}
      />
      {useWordLimit && (
        <div className='w-10'>
          <LimitLength length={defaultValue?.length || value?.length} limit={limit || 0} />
        </div>
      )}
    </div>
  );
};
export default Input;
