/* eslint-disable no-useless-return */
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty, isNaN } from 'lodash';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { MachineLocationForm, MachinePlaceType } from 'types/feature/machine/machine';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  placeType: MachinePlaceType;
  machinePlaceForm: MachineLocationForm;
  floorId: string;
};

const MachineAddButtons = ({ machinePlaceForm, placeType, floorId }: Props) => {
  // state
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  // hook
  const { workspaceId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { postMachine } = useMachine();

  /**
   * 기기 목록으로 이동한다.
   */
  const moveToMachineListPage = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.machines, workspaceId), {
        state: {
          ...state,
          placeType,
          floorId: floorId,
        },
      });
    }
  };

  // 포인트별로 기기를 저장합니다.
  const postKioskMachine = async () => {
    try {
      const postForm = {
        ...machinePlaceForm,
        pointId: state.point?.id,
        placeType,
      };
      await postMachine(postForm);
      moveToMachineListPage();
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlertPopup(true);
      });
      setAlertMessage('기기 정보를 등록하는 중에\n 문제가 발생하였습니다.');
    }
  };

  const handleRegisterMachine = async () => {
    setOpenConfirmPopup(false);

    if (state?.point) {
      if (
        machinePlaceForm.mapPosition.zoom > state.point.mapMaxZoom ||
        machinePlaceForm.mapPosition.zoom < state.point.mapMinZoom
      ) {
        setAlertMessage('줌 레벨이 건물의 min, max 값 내의 값으로 변경 되어야 합니다.');
        setOpenAlertPopup(true);
        return;
      }
    }

    if (
      isEmpty(machinePlaceForm.positionX) ||
      isEmpty(machinePlaceForm.positionY) ||
      isEmpty(machinePlaceForm.description) ||
      isEmpty(machinePlaceForm.mapPosition.centerPositionX) ||
      isEmpty(machinePlaceForm.mapPosition.centerPositionY) ||
      isEmpty(machinePlaceForm.mapPosition.rotation) ||
      isEmpty(machinePlaceForm.mapPosition.zoom)
    ) {
      setAlertMessage('기기 위치 정보를 입력해주세요.');
      setOpenAlertPopup(true);
      return;
    }

    if (
      isNaN(Number(machinePlaceForm.positionX)) ||
      isNaN(Number(machinePlaceForm.positionY)) ||
      isNaN(Number(machinePlaceForm.mapPosition.centerPositionX)) ||
      isNaN(Number(machinePlaceForm.mapPosition.centerPositionY)) ||
      isNaN(Number(machinePlaceForm.mapPosition.rotation)) ||
      isNaN(Number(machinePlaceForm.mapPosition.zoom))
    ) {
      setOpenAlertPopup(true);
      setAlertMessage('숫자만 입력해주세요');
      return;
    }
    await postKioskMachine();
  };

  return (
    <>
      <div className='flex justify-center gap-2.5 pt-10'>
        <Button text='취소' color={ButtonColor.secondary} size={120} onClick={moveToMachineListPage} />
        <Button text='저장' color={ButtonColor.primary} size={120} onClick={() => setOpenConfirmPopup(true)} />
      </div>
      {openConfirmPopup && (
        <ConfirmModal
          message='등록한 정보로 기기를 등록 하시겠습니까?'
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={handleRegisterMachine}
        />
      )}
      {openAlertPopup && <AlertModal message={alertMessage} closeModal={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default MachineAddButtons;
