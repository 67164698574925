/* eslint-disable no-param-reassign */
import { ICON } from 'constants/icons';
import { IMAGES } from 'constants/images';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import usePointStore from 'stores/point';
import { FLEX_CENTER } from 'styles/flex';
import { RoutePath } from 'types/common/paths';
import { MainMenu, MainMenuIcon } from 'types/feature/menu/menu';
import ExposureBtn from './exposure/ExposureBtn';
import DetailMenuPopup from './popup/DetailMenuPopup';
import DraggableButton from 'components/common/draggable-button/DraggableButton';
import { replaceContentId, replaceCustomPageId, replacePointId, replaceWorkspaceId } from 'utils/common/replaceId';

type Props = {
  provided: DraggableProvided;
  menu: MainMenu;
  menuIcons: MainMenuIcon[];
  fetchMainMenus: (pointId: string) => Promise<void>;
};

const MenuItem = ({ provided, menu, menuIcons, fetchMainMenus }: Props) => {
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();

  const navigate = useNavigate();
  const [openDetailPopup, setOpenDetailPopup] = useState(false);

  const pointStore = usePointStore();

  const closeDetailPopup = async () => {
    await fetchMainMenus(pointStore.currentPoint?.id || '');
    setOpenDetailPopup(false);
  };

  const navigateCustomPage = (customPageId: string) => {
    // navigateToReplacedPath(RoutePath.pointCustomBoards, workspaceId, ':customPageId', customPageId);

    const pointId = pointStore.currentPoint?.id;
    if (workspaceId && pointId && customPageId) {
      navigate(
        replaceCustomPageId(
          replacePointId(replaceWorkspaceId(RoutePath.pointCustomBoards, workspaceId), pointId),
          customPageId,
        ),
      );
    }
  };

  // 순서 변경 버튼 노출 조건
  const showDragBtn = menu.type === 'CUSTOM_BOARD';

  // 노출 여부 버튼 노출 조건
  const showOnOffBtn = menu.type === 'CUSTOM_BOARD';

  return (
    <>
      <div
        key={menu.id}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className={`w-[140px] h-52 px-5 pt-[14px] pb-6 border border-gray-eb flex flex-col gap-3 shadow-md `}
      >
        <div className='flex items-center justify-between'>
          <div className='flex justify-between'>
            <div className='flex gap-[6px]'>
              {/* 순서 변경 버튼 */}
              {showDragBtn && <DraggableButton />}

              {/* 노출 토글 버튼 */}
              {showOnOffBtn && <ExposureBtn menu={menu} isDefault={menu.default} fetchMainMenus={fetchMainMenus} />}
            </div>
          </div>

          {/* 설정 버튼 */}
          <div onClick={() => setOpenDetailPopup(true)} className={`bg-bg-f9 px-2 rounded-full ${FLEX_CENTER.row}`}>
            <img src={ICON.SETTING} alt='setting' className='h-[18px]' />
          </div>
        </div>

        <div className={`flex flex-col justify-center items-center h-full gap-2`}>
          {/* 아이콘 */}
          <div className='flex justify-center'>
            {menu.iconUsed ? (
              // 아이콘 사용
              <img src={menu.mainIconUri} alt='icon' />
            ) : (
              // 아이콘 미사용
              <div className='text-xs text-gray-400 h-[40px] flex items-center'>등록된 아이콘이 없습니다.</div>
            )}
          </div>

          {/* 타이틀 */}
          {menu.name.ko ? (
            <div className='text-sm font-semibold text-gray-44 h-[40px] flex items-center'>{menu.name.ko}</div>
          ) : (
            <div className='text-xs font-semibold text-gray-99 h-[40px] flex items-center'>
              등록된 타이틀이 없습니다.
            </div>
          )}
        </div>

        <div className='flex justify-center'>
          {/* 커스텀 페이지 이동 버튼 */}
          {menu.customPage?.id && (
            <div
              onClick={e => {
                e.stopPropagation();
                navigateCustomPage(menu.customPage?.id ?? '');
              }}
              className={`flex items-center justify-center gap-2 bg-bg-f9 h-6 px-3 rounded-3xl`}
            >
              <div className='text-xs font-bold text-gray-99 whitespace-nowrap'>메뉴 페이지</div>
              <div>
                <img src={ICON.ARROW_RIGHT} alt='arrow' />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* 메뉴 설정 팝업 */}
      {openDetailPopup && (
        <DetailMenuPopup
          fetchMainMenus={fetchMainMenus}
          menu={menu}
          closePopup={closeDetailPopup}
          menuIcons={menuIcons}
        />
      )}
    </>
  );
};

export default MenuItem;
