import GuideDescription from 'components/common/description/GuideDescription';
import FormControl from 'components/common/form-control/FormControl';
import { CustomPageTypeKo } from 'types/feature/menu/customPage';
import { ConvertMenuTypeInWord } from 'types/feature/menu/menu';

type Props = {
  menuType: string;
  customPageType: string;
};

const EditMenuType = ({ menuType, customPageType }: Props) => {
  return (
    <div className='flex w-full h-24 gap-3 px-10 py-3 border-b'>
      <FormControl
        name='메뉴 타입'
        // labelTextSize='h3_bold'
        control={
          <div className='flex flex-col'>
            <div
              className={`flex gap-2 items-center justify-center h-10 text-sm border w-44 cursor-default bg-point-light border-gray-44 text-gray-44`}
            >
              {customPageType && <span>{CustomPageTypeKo[customPageType]}</span>}
              <span>{ConvertMenuTypeInWord[menuType]}</span>
            </div>

            {/* 가이드 */}
            <GuideDescription manualInfo={'메뉴 타입은 수정할 수 없습니다.'} bgColor='none' infoColor='red' />
          </div>
        }
      />
    </div>
  );
};
export default EditMenuType;
