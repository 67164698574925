import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import { TextType } from 'components/common/title/Title';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useCustomPageStore from 'stores/customPage';
import { Workspace } from 'types/feature/workspace/workspace';
import GlobalMenuContainer from './gnb/menu/GlobalMenuContainer';
import { RoutePath } from 'types/common/paths';
import { ICON } from 'constants/icons';
import useSsoAuth from 'hooks/common/useSsoAuth';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useAccountInfo from 'hooks/common/useAccountInfo';

const InnerGnb = () => {
  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceList, setWorkspaceList] = useState<Workspace[]>([]);
  const [openList, setOpenList] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [type, setType] = useState<string>('');

  // hook
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { getWorkspace, getWorkspaces } = useWorkspace();
  const { logout } = useSsoAuth();
  const location = useLocation();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { getAccountInfo } = useAccountInfo();

  // store
  const customPageStore = useCustomPageStore();

  // TODO: workspace select box 로 이동
  // 워크스페이스 조회
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);

      if (result) {
        setWorkspace(result);
      }
    }
  };

  // 워크스페이스 목록 조회
  const fetchWorkspaces = async () => {
    const workspaces = await getWorkspaces();

    if (workspaces) {
      setWorkspaceList(workspaces);
    }
  };

  const handleClickWorkspaceList = () => {
    if (workspaceList.length === 1) {
      return;
    }

    setOpenList(!openList);
  };

  const moveToWorkspaces = () => {
    navigate(RoutePath.workspaces);
  };

  const moveToDashboard = () => {
    if (workspaceId) {
      navigateToReplacedPath(RoutePath.dashboard, workspaceId);
    }
  };

  const moveToSetting = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.basicSetting, workspaceId));
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  const fetchAccountInfo = async () => {
    try {
      await getAccountInfo();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWorkspaces();

    return () => {
      customPageStore.resetCustomPages();
    };
  }, []);

  useEffect(() => {
    fetchWorkspace();
  }, [workspaceId]);

  useEffect(() => {
    const pathType = location.pathname.includes('/setting');
    if (pathType) {
      setType('HOME');
    } else {
      setType('SETTING');
    }
  }, [location]);

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  return (
    <>
      <div className='z-50 fixed top-0 left-0 flex items-center justify-center w-full h-20 bg-primary-White px-[60px]'>
        <div className='relative flex items-center justify-between w-full max-w-[1400px]'>
          <div
            onClick={handleClickWorkspaceList}
            className={`flex items-center gap-2 h-full cursor-pointer hover:bg-state-blue_bg px-1 ${
              openList && 'bg-state-blue_bg'
            }`}
          >
            <div className={`${TextType.h2} truncate shrink-0 text-gray-22 mt-0.5`} onClick={moveToDashboard}>
              {workspace?.name}
            </div>
          </div>

          <div className='flex items-center gap-10'>
            <GlobalMenuContainer />

            <div className={`flex items-center gap-5 shrink-0 pr-5`}>
              {/* 워크스페이스 목록으로 이동하는 버튼 */}
              <div className='cursor-pointer w-[24px] h-[24px]' onClick={moveToWorkspaces}>
                <img src={ICON.WORKSPACE} alt='워크스페이스' />
              </div>

              {type === 'HOME' ? (
                <div
                  className='w-[24px] h-[24px] cursor-pointer'
                  onClick={moveToDashboard}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <img src={ICON.HOME2} alt='홈' style={{ aspectRatio: '1', height: '20px' }} />
                </div>
              ) : (
                <div
                  className='w-[24px] h-[24px] cursor-pointer'
                  onClick={moveToSetting}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <img src={ICON.SETTING} alt='설정' />
                </div>
              )}

              {/* 로그아웃 */}
              <div
                className='w-[24px] h-[24px] cursor-pointer'
                onClick={() => {
                  setOpenConfirm(true);
                }}
              >
                <img src={ICON.LOGOUT2} alt='logout' />
              </div>
            </div>
          </div>
        </div>

        <Border borderColor={BorderColor.black} position='absolute top-20 left-0' />
      </div>
      {openConfirm && (
        <ConfirmModal
          onClickOk={handleLogout}
          onClickCancel={() => setOpenConfirm(false)}
          message='로그아웃 하시겠습니까?'
        />
      )}
    </>
  );
};

export default InnerGnb;
