import { MultiLangs } from '../multi-lang/language';

export type MapStatus = {
  floor: string;
  ready: boolean;
  tenant: string;
};

export type MapObject = {
  id: string;
  status: MapStatus;
  version: string;
  temp1: string;
  temp2: string;
};

export type PointDetail = {
  hoursStart: string;
  hoursEnd: string;
  closedDays: string[];
};

export type Building = {
  id: string;
  name: MultiLangs;
  type: 'INDOOR' | 'OUTDOOR';
};

export type HoursType = 'DATE' | 'TEXT';

export type ClosedDayType = 'SOON' | 'MONTH';

export type ClosedDay = {
  type: ClosedDayType;
  days: string[];
};
export type PointOperation = {
  hoursDescription: string;
  hoursUsed: boolean;
  hoursStart: string;
  hoursEnd: string;
  closedDaysUsed: boolean;
  closedDays: string[];
  hoursText: MultiLangs;
  hoursType: HoursType;
};

export type Point = {
  id: string;
  name: MultiLangs;
  description: string;
  building: Building;
  mapMinZoom: string;
  mapMaxZoom: string;
  logo: Logo | null;
  operation: PointOperation;
  main?: boolean;
  mapPosition: MapPosition;
  machinePositionX: string;
  machinePositionY: string;
  mobile: Mobile;
  closedDay: ClosedDay;
  closedDaysUsed: boolean;
};

export type BusinessHours = {
  hoursType: HoursType;
  hoursText: MultiLangs;
  hoursUsed: boolean;
  hoursStart: string;
  hoursEnd: string;
};

export type ClosedDays = {
  closedDaysUsed: boolean;
  closedDays: string[];
};

export type Logo = {
  name?: string;
  originalName?: string;
  delete?: boolean;
  uri?: string;
  size?: number;
  width?: number;
  height?: number;
};

export type WorkspaceType = 'RETAIL' | 'EXHIBITION' | 'OFFICE' | 'ETC';
export type WorkspaceUseType = 'SERVICE' | 'DRAFT' | 'ARCHIVE' | 'TEST';

export enum NaviSpeedOptionKo {
  LINEAR = '일정한 속도 유지',
  EASE_IN = '점점 빠르게',
}

export type NaviGationSpeedOption = 'LINEAR' | 'EASE_IN';

export type NavigationSetting = {
  lineThickness: number;
  navigationSpeed: number;
  navigationSpeedOption: NaviGationSpeedOption;
};

export type MapPosition = {
  centerPositionX?: string;
  centerPositionY?: string;
  zoom: string;
  rotation: string;
};

export type Mobile = {
  id: string;
  machinePositionX: string;
  machinePositionY: string;
  mapPosition: MapPosition;
  mapMinZoom: string;
  mapMaxZoom: string;
  pointId: string;
};

export type Workspace = {
  id: string;
  name: string;
  description: string;
  machineType: string;
  type: WorkspaceType;
  useType: WorkspaceUseType;
  points: Point[];
  createdDate: string;
  updatedDate: string;
  map: MapObject;
  mobileUsed: boolean;
  mobileEndpoint: string;
  webUsed: boolean;
  webEndpoint: string;
  clientType: 'DID' | 'OLIVE_YOUNG';
  autoSync: boolean;
  legacy: boolean;
  lineThickness: number;
  navigationSpeed: number;
  navigationSpeedOption: NaviGationSpeedOption;
  zoomButtonUsed: boolean;
  mobile: Mobile;
  parkingUsed: boolean;
  clientPath: string;
  clientPathModified: boolean;
};

export type MachineType = 'WEB' | 'DESKTOP';
export type WorkspaceForm = {
  name: string;
  description: string;
  mobileUsed: boolean;
  webUsed: boolean;
  zoomButtonUsed: boolean;
  mapId?: string;
  machineType?: MachineType;
  parkingUsed: boolean;
  type: WorkspaceType | string;
  useType: WorkspaceUseType | string;
  clientPath?: string;
};
export type InputStatus = 'default' | 'success' | 'error';

export type WorkspaceBasicForm = {
  name: string;
  description: string;
  zoomButtonUsed: boolean;
  type: WorkspaceType;
  useType: WorkspaceUseType;
  parkingUsed: boolean;
};

export type PointPutForm = Pick<Point, 'logo'>;
export type PointKiosk = Pick<Point, 'mapPosition' | 'mapMinZoom' | 'mapMaxZoom'>;
export type Zoom = {
  mapMinZoom: string;
  mapMaxZoom: string;
  zoom: string;
};

export type CommonForm = {
  mapPosition: {
    rotation: string;
    centerPositionX: string;
    centerPositionY: string;
  };
  machinePositionX: string;
  machinePositionY: string;
};

export type ClosedDayTypeObject = {
  closedDayType: ClosedDayType;
};

export type ClosedDayObject = {
  closedDay: string;
};
