import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import Title from 'components/common/title/Title';
import AlertPopup from 'components/feature/workspace/popup/AlertPopup';
import ConfirmPopup from 'components/feature/workspace/popup/ConfirmPopup';
import usePoint from 'hooks/feature/point/usePoint';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { TextType } from 'types/common/text';
import { CommonForm } from 'types/feature/workspace/workspace';
import { validNumber } from 'utils/common/validNumber';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  commonForm: CommonForm;
  setCommonForm: Dispatch<SetStateAction<CommonForm | null>>;
  pointId: string;
  fetchPoint: (id: string) => void;
};

const PointCommonMapPosition = ({ commonForm, setCommonForm, pointId, fetchPoint }: Props) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { putPointMapPosition } = usePoint();

  const onSaveKiosk = async () => {
    try {
      if (!commonForm) return;

      await putPointMapPosition(pointId, commonForm);
      await fetchPoint(pointId);
      setOpenConfirm(false);
      setOpenAlert(true);
      setAlertMessage('지도 기본 설정값을 저장하였습니다.');
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlert(true);
        setAlertMessage('지도 기본 설정값을 수정할 수 없습니다.');
      });
    }
  };

  const onChangeCommonForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }

    setCommonForm(prev => {
      if (!prev) return null;
      return { ...prev, [name]: value };
    });
  };

  const onChangeCommonMapPositionForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }
    setCommonForm(prev => {
      if (!prev) return null;
      return {
        ...prev,
        mapPosition: {
          ...prev.mapPosition,
          [name]: value,
        },
      };
    });
  };

  return (
    <section className='flex flex-col items-start gap-8 px-10 py-6 border border-[#CCC] w-full'>
      <Title text='공통' textType={TextType.h3_bold} />

      <div className='flex flex-col gap-5'>
        <FormControl
          name='기기 좌표'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>X</div>
                <Input
                  size={96}
                  name='machinePositionX'
                  placeholder='1000.123'
                  onChange={onChangeCommonForm}
                  value={commonForm.machinePositionX}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>Y</div>
                <Input
                  size={96}
                  name='machinePositionY'
                  placeholder='1000.123'
                  onChange={onChangeCommonForm}
                  value={commonForm.machinePositionY}
                />
              </div>
            </div>
          }
        />
        <FormControl
          name='센터 좌표'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>X</div>
                <Input
                  size={96}
                  name='centerPositionX'
                  placeholder='1000.123'
                  onChange={onChangeCommonMapPositionForm}
                  value={commonForm.mapPosition.centerPositionX}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>Y</div>

                <Input
                  size={96}
                  name='centerPositionY'
                  placeholder='1000.123'
                  onChange={onChangeCommonMapPositionForm}
                  value={commonForm.mapPosition.centerPositionY}
                />
              </div>
            </div>
          }
        />

        <FormControl
          name='방향각'
          control={
            <div className='pl-[70px]'>
              <Input
                size={96}
                name='rotation'
                placeholder='rotation'
                onChange={onChangeCommonMapPositionForm}
                value={commonForm.mapPosition.rotation}
              />
            </div>
          }
        />
      </div>

      <div className={`flex justify-center w-full mt-10`}>
        <Button text='저장' type='button' color={ButtonColor.primary} onClick={() => setOpenConfirm(true)} />
      </div>

      {openConfirm && (
        <ConfirmPopup
          message='지도 기본 설정을 저장하시겠습니까?'
          onClickOk={onSaveKiosk}
          onClickCancel={() => {
            setOpenConfirm(false);
            setOpenAlert(false);
          }}
        />
      )}

      {/* 성공/실패 팝업 */}
      {openAlert && <AlertPopup onClick={() => setOpenAlert(false)} message={alertMessage} />}
    </section>
  );
};

export default PointCommonMapPosition;
