import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import Title, { TextType } from 'components/common/title/Title';
import usePoint from 'hooks/feature/point/usePoint';
import { isEmpty } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { Logo, Point } from 'types/feature/workspace/workspace';
import PointLogo from './PointLogo';
import PointName from './PointName';
import ConfirmPopup from 'components/feature/workspace/popup/ConfirmPopup';
import AlertPopup from 'components/feature/workspace/popup/AlertPopup';
import useAccountInfoStore from 'stores/accountInfo';

type Props = {
  pointId: string;
  point: Point | null;
  fetchPoint: (id: string) => void;
};

const PointBasicSetting = ({ pointId, point, fetchPoint }: Props) => {
  // state
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [pointForm, setPointForm] = useState<Point | null>(point);

  // hook
  const { putPointV2 } = usePoint();

  // store
  const accountInfoStore = useAccountInfoStore();
  /**
   * 빌딩 이름을 수정한다.
   */
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!point) return;

    const { value, id } = e.target;

    setPointForm(prev => {
      if (!prev) return null;
      return {
        ...prev,
        name: {
          ...prev.name,
          [id]: value,
        },
      };
    });
  };

  /**
   * 빌딩 정보를 업데이트한다.
   */
  const updatePoint = async () => {
    try {
      if (!pointForm) return;
      const useLogo = isEmpty(pointForm.logo?.originalName);

      await putPointV2(pointForm.id, {
        // name: point.name,
        logo: {
          delete: useLogo,
          name: pointForm.logo?.name || '',
          originalName: pointForm.logo?.originalName || '',
        },
      });
      await fetchPoint(pointId);

      setOpenConfirm(false);
      setOpenSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setOpenFail(true);
        }
      }
    }
  };

  /**
   * point의 로고를 변경한다.
   * @param {Logo} logo
   */
  const onChangePointLogo = (logo: Logo) => {
    setPointForm(prev => {
      if (!prev) return null;
      return {
        ...prev,
        logo,
      };
    });
  };

  if (!pointForm) {
    return <> </>;
  }

  return (
    <section className='flex flex-col gap-10 p-10 bg-white border border-gray-ea'>
      <Title text='기본 정보' textType={TextType.h2} />

      <FormControl name='이름' control={<PointName pointName={pointForm.name} onChange={onChangeInput} />} />

      <FormControl
        name='로고 이미지'
        control={
          accountInfoStore.accountInfo?.admin ? (
            <PointLogo onChangePointLogo={onChangePointLogo} point={pointForm} />
          ) : (
            <PointLogo onChangePointLogo={onChangePointLogo} point={pointForm} readOnly />
          )
        }
      />

      <div className='flex justify-end'>
        {accountInfoStore.accountInfo?.admin && (
          <Button text='저장' color={ButtonColor.point} size={80} onClick={() => setOpenConfirm(true)} />
        )}
      </div>

      {/* 확인 팝업 */}
      {openConfirm && (
        <ConfirmPopup
          message='포인트 정보를 수정하시겠습니까?'
          onClickCancel={() => setOpenConfirm(false)}
          onClickOk={updatePoint}
        />
      )}
      {/* 성공 팝업 */}
      {openSuccess && <AlertPopup message='저장 완료되었습니다.' onClick={() => setOpenSuccess(false)} />}

      {/* 실패 팝업 */}
      {openFail && <AlertPopup message={'포인트 정보를 수정할 수 없습니다.'} onClick={() => setOpenFail(false)} />}
    </section>
  );
};
export default PointBasicSetting;
