import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Machine, MachinePlaceType, MachinePostForm, MachineStatus, PatchMachine } from 'types/feature/machine/machine';
import { revokeCsvDownloadURL } from 'utils/file/csv/revokeCsvDownloadURL';

const useMachines = () => {
  const { api } = useApi();

  const getMachines = async (pointId: string, placeType: MachinePlaceType, status: MachineStatus, floorId: string) => {
    const result = await api.get<ApiResponse<Machine[]>>(Endpoint.machines, {
      params: {
        pointId,
        placeType,
        status,
        floorId,
      },
    });
    return result.data;
  };

  const getMachine = async (machineId: string) => {
    const result = await api.get<ApiResponse<Machine>>(Endpoint.machine.replace(':machineId', machineId));
    return result.data;
  };

  // 재시작
  const resetMachine = async (machineId: string) => {
    await api.post(Endpoint.machineReset.replace(':machineId', machineId));
  };

  const postMachine = async (machinePostForm: MachinePostForm) => {
    await api.post(Endpoint.machines, machinePostForm);
  };

  // 폐기
  const discardMachine = async (machineId: string) => {
    await api.patch(Endpoint.machineDiscard.replace(':machineId', machineId));
  };

  // 초기화
  const initMachine = async (machineId: string) => {
    await api.patch(Endpoint.machineInit.replace(':machineId', machineId));
  };

  // 수정
  const patchMachine = async (machineId: string, patchMachine: PatchMachine) => {
    await api.patch(Endpoint.machine.replace(':machineId', machineId), patchMachine);
  };

  // 기기 목록 CSV 다운로드
  const downloadMachinesCsv = async (
    pointId: string,
    placeType: MachinePlaceType,
    status: MachineStatus,
    floorId: string,
  ) => {
    const response = await api.get<Blob>(Endpoint.machinesCsv, {
      responseType: 'blob',
      params: {
        pointId,
        placeType,
        status,
        floorId,
      },
    });

    if (response) {
      revokeCsvDownloadURL(response, 'machines');
    }
  };

  return {
    getMachines,
    resetMachine,
    postMachine,
    discardMachine,
    getMachine,
    patchMachine,
    initMachine,
    downloadMachinesCsv,
  };
};

export default useMachines;
