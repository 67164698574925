import Badge from 'components/common/badge/Badge';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import { ICON } from 'constants/icons';
import useModal from 'hooks/common/useModal';
import { Machine, MachineStatusKo } from 'types/feature/machine/machine';
import { MachineConnectStatusKo } from '../../../../types/feature/machine/machine';
import useAccountInfoStore from 'stores/accountInfo';

type Props = {
  machineIndex: number;
  connectedMachine: Machine;
  handleReloadMachine: (machineId: string) => Promise<void>;
  moveToDetailPage: (machineId: string) => void;
};

const MachineListTableRow = ({ connectedMachine, machineIndex, handleReloadMachine, moveToDetailPage }: Props) => {
  // hook
  const { closeModalPopup, openConfirm, openModalPopup } = useModal();
  // var
  const isFileManagerOn = connectedMachine.connectStatus !== 'OFF';
  // store
  const accountInfoStore = useAccountInfoStore();
  return (
    <>
      <tbody>
        <tr
          onClick={() => moveToDetailPage(connectedMachine.id)}
          className={`h-[52px] text-[14px]
          ${accountInfoStore?.accountInfo?.admin ? 'cursor-pointer' : 'cursor-default'}
          `}
        >
          {/* No */}
          <td className='border-b text-center p-2.5'>{machineIndex}</td>

          {/* 층 */}
          <td className='border p-2.5'>{connectedMachine.floor.name.ko || ''}</td>

          {/* 기기코드 */}
          <td className='border p-2.5'>{connectedMachine.idSuffix}</td>

          {/* 상세위치 */}
          <td className='border p-2.5'>{connectedMachine.description}</td>

          {/* 상태 */}
          <td className='border p-2.5'>
            <Badge
              type={connectedMachine.status}
              label={MachineStatusKo[connectedMachine.status]}
              cursorStyle='pointer'
            />
          </td>

          {/* 연결 */}
          <td className='border p-2.5'>
            {connectedMachine.status === 'RUNNING' && (
              <Badge
                type={connectedMachine.connectStatus}
                label={MachineConnectStatusKo[connectedMachine.connectStatus]}
                cursorStyle='pointer'
              />
            )}
          </td>

          {/* 재시작 */}
          <td className='border-b p-2.5'>
            {isFileManagerOn ? (
              <div
                className='flex items-center justify-center'
                onClick={e => {
                  e.stopPropagation();
                  openModalPopup('CONFIRM');
                }}
              >
                <img src={ICON.RELOAD_MACHINE} alt='reload' />
              </div>
            ) : (
              <div className='flex items-center justify-center cursor-default'>
                <img src={ICON.RELOAD_MACHINE_DISABLED} alt='reload' />
              </div>
            )}
          </td>
        </tr>
      </tbody>

      {openConfirm && (
        <ConfirmModal
          message='기기를 재시작 하시겠습니까?'
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={() => {
            closeModalPopup('CONFIRM');
            handleReloadMachine(connectedMachine.id);
          }}
        />
      )}
    </>
  );
};

export default MachineListTableRow;
