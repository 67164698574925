/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-constant-condition */
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { ScreenType } from 'types/feature/content/contents';
import { Floor } from 'types/feature/floor/floor';
import { Machine } from 'types/feature/machine/machine';
import { Point, Workspace } from 'types/feature/workspace/workspace';
import { drawKioskMarker } from 'utils/map/control/marker';
import MapDraw from 'utils/map/mapDraw';
import KioskScreenTypeGuide from '../common/KioskScreenTypeGuide';
import MapCenterSign from '../common/MapCenterSign';
import MapLoading from '../common/MapLoading';
import FloorSelectBox from './FloorSelectBox';

type Props = {
  floorList: Floor[];
  workspace: Workspace;
  machine: Machine;
  setMapLoading: Dispatch<SetStateAction<boolean>>;
  mapLoading: boolean;
  screenType: ScreenType;
  point: Point;
};

const MachineDetailMap = ({ floorList, workspace, machine, setMapLoading, mapLoading, screenType, point }: Props) => {
  // ref
  const mapRef = useRef<HTMLDivElement>(null);
  // var
  const mapInstance = new MapDraw();

  /**
   * 지도를 붙인다.
   */
  const appendMap = async () => {
    if (!mapRef.current) return;
    const { name, description, position, screenType, mapPosition } = machine;
    await mapInstance.showMachineMap(
      mapRef.current,
      machine.floor.id,
      {
        name,
        description,
        positionX: position.x,
        positionY: position.y,
        screenType,
        mapPosition: {
          ...mapPosition,
        },
        floorId: machine.floor.id,
      },
      point.mapMinZoom,
      point.mapMaxZoom,
    );

    await drawKioskMarker({
      x: Number(position.x),
      y: Number(position.y),
    });
  };

  const fetchWorkspaceAndMapData = async (workspace: Workspace) => {
    try {
      if (!mapRef.current) return;
      const mapData = await MapDraw.dabeeoMaps.getMapData({
        clientId: workspace.map.temp1,
        clientSecret: workspace.map.temp2,
        version: workspace.map.version,
        serverType: 'SERVER_STUDIO4',
      });

      if (mapData) {
        MapDraw.mapData = mapData;
        await appendMap();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setMapLoading(false);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      fetchWorkspaceAndMapData(workspace);
    }

    /**
     * cleanup
     */
    return () => {
      if (mapRef.current) {
        MapDraw.cleanup();
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      setMapLoading(true);
    };
  }, []);

  return (
    <div className='w-[900px] h-[666px] shrink-0 relative overflow-hidden'>
      {mapLoading && <MapLoading />}

      <>
        <MapCenterSign />
        <KioskScreenTypeGuide screenType={screenType} />

        {/* 층 선택 */}
        <FloorSelectBox floors={floorList} floorId={machine.floor.id} />

        {/* 지도 Viewer */}
        <div ref={mapRef} className='w-full h-full' />
      </>
    </div>
  );
};

export default MachineDetailMap;
