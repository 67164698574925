import React, { ChangeEvent } from 'react';
import { TextType } from 'types/common/text';

type Props = {
  value?: string;
  name: string;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  width?: string;
  height?: string;
  readOnly?: boolean;
};

const Textarea = ({
  value,
  defaultValue,
  onChange,
  name,
  disabled = false,
  placeholder,
  width,
  height,
  readOnly,
}: Props) => {
  return (
    <textarea
      className={`
      ${disabled && 'opacity-50'}
      ${width || 'w-full'}
      ${height || 'h-[76px]'}
      focus:outline-none 
     placeholder:text-gray-cc 
      placeholder:text-body1
      placeholder:font-normal
    text-gray-44 
      border border-gray-99 p-2 bg-white
      ${TextType.body1}
      `}
      placeholder={placeholder}
      disabled={disabled}
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      readOnly={readOnly}
    />
  );
};
export default Textarea;
