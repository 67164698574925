import { AxiosError } from 'axios';
import useMenu from 'hooks/feature/menu/useMenu';
import usePointStore from 'stores/point';
import { MainMenu } from 'types/feature/menu/menu';

type Props = {
  menu: MainMenu;
  isDefault: boolean;
  fetchMainMenus: (pointId: string) => Promise<void>;
};

const ExposureBtn = ({ menu, isDefault, fetchMainMenus }: Props) => {
  const { patchMainMenu } = useMenu();

  const pointStore = usePointStore();

  // 메뉴 활성화 상태 수정
  const updateMenuActiveState = async (menuId: string) => {
    try {
      await patchMainMenu(menuId, {
        active: !menu.active,
      });
      await fetchMainMenus(pointStore.currentPoint?.id || '');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          console.error('오류가 발생했습니다', error);
        }
      }
    }
  };

  return (
    <button
      disabled={isDefault ?? true}
      type='button'
      className={`flex justify-center items-center rounded-full px-2  text-xs
      ${menu.default && menu.active && 'bg-[#ddd] text-[#999]'}
      ${menu.active ? 'bg-[#F2F5FF] text-[#586DB9]' : 'bg-[#f4f4f4] text-[#999]'} border-none ${
        isDefault && 'opacity-50'
      } `}
      onClick={e => {
        e.stopPropagation();
        updateMenuActiveState(menu.id);
      }}
    >
      {menu.active ? 'ON' : 'OFF'}
    </button>
  );
};

export default ExposureBtn;
