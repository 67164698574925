import React, { Dispatch, SetStateAction } from 'react';
import SelectedDay from '../selected/SelectedDay';
import { TextType } from 'types/common/text';
import { ClosedDayType } from 'types/feature/workspace/workspace';
import { closedDayToFormat } from 'utils/date/dateUtils';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import RadioSimple from 'components/common/radio/RadioSimple';
import { Option } from '../HolidayForm';

type Props = {
  selectedCalendarDates: Date[];
  selectedType: ClosedDayType;
  setSelectedType: Dispatch<SetStateAction<ClosedDayType>>;
  handleDeleteDay: (day: string) => void;
  openConfirm: boolean;
  setOpenConfirm: Dispatch<SetStateAction<boolean>>;
  handleClickItem: (exposureOption: Option) => void;
};

const ExposureOptions: Option[] = [
  { id: 'SOON', text: '다가오는 휴일' },
  { id: 'MONTH', text: '해당달의 휴일' },
];

const SettingHoliday = ({
  selectedCalendarDates,
  selectedType,
  setSelectedType,
  handleDeleteDay,
  setOpenConfirm,
  handleClickItem,
}: Props) => {
  return (
    <div
      className='w-full'
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ flex: 1 }}>
        <div className={`${TextType.h4} shrink-0 flex h-9 items-center justify-start gap-1`}>
          <span className={TextType.h4}>노출 방법</span>
        </div>
        {ExposureOptions?.map(exposureOption => (
          <div
            onClick={() => handleClickItem(exposureOption)}
            key={exposureOption.id}
            className={`${
              exposureOption?.id === selectedType && 'bg-state-blue_bg'
            } h-[52px] px-5 flex items-center justify-start border-b border-b-gray-ea gap-4 hover:bg-state-blue_bg cursor-pointer`}
          >
            <RadioSimple
              value={String(exposureOption?.id === selectedType)}
              checked={exposureOption?.id === selectedType}
              onChange={() => {}}
            />
            <span className='shrink-0 text-[14px] text-gray-22 leading-5 truncate text-ellipsis w-[154px]'>
              {exposureOption.text}
            </span>
          </div>
        ))}
      </div>
      <div className={`h-full`} style={{ flex: 1, height: '100%' }}>
        <div className={`${TextType.h4} shrink-0 flex h-9 items-center justify-start gap-1`}>
          <span className={TextType.h4}>아래와 같이 노출 됩니다.</span>
        </div>
        <div className='w-full'>
          {selectedCalendarDates.length > 0 ? (
            <>
              <div className='flex flex-wrap gap-2.5'>
                {selectedCalendarDates
                  // 날짜순으로 sorting
                  .sort((a, b) => {
                    return a.getTime() - b.getTime();
                  })
                  .map((date, dateIndex) => (
                    <SelectedDay key={dateIndex} day={closedDayToFormat(date)} handleDeleteDay={handleDeleteDay} />
                  ))}
              </div>
            </>
          ) : (
            <div className={`${TextType.h4} flex items-center h-9 text-gray-77`}>선택된 휴무일이 없습니다.</div>
          )}
        </div>
      </div>
      <div className='flex justify-center'>
        <Button text='저장' color={ButtonColor.point} size={80} onClick={() => setOpenConfirm(true)} />
      </div>
    </div>
  );
};

export default SettingHoliday;

//  {
//    selectedCalendarDates.length > 0 ? (
//      <>
//        <div className='flex gap-2.5'>
//          {selectedCalendarDates
//            // 날짜순으로 sorting
//            .sort((a, b) => {
//              return a.getTime() - b.getTime();
//            })
//            .map((date, dateIndex) => (
//              <SelectedDay
//                key={dateIndex}
//                selectedDays={selectedDates}
//                setSelectedDay={setSelectedDates}
//                setSelectedCalendarDay={setSelectedCalendarDates}
//                day={closedDayToFormat(date)}
//                point={point}
//                handlePoint={handlePoint}
//              />
//            ))}

//          {selectedCalendarDates.length > 0 && (
//            <div
//              onClick={handleClickDeleteAllDate}
//              className='flex items-center gap-1 pl-3 pr-2 bg-white border rounded-full cursor-pointer shrink-0 border-gray-44 h-9'
//            >
//              <div className={` text-gray-44 ${TextType.h4}`}>전체 삭제</div>
//              <div>
//                <img src={ICON.DELETE} alt='delete-white' />
//              </div>
//            </div>
//          )}
//        </div>
//      </>
//    ) : (
//      <div className={`${TextType.h4} flex items-center h-9 text-gray-44`}>선택된 휴무일이 없습니다.</div>
//    );
//  }
//  // 전체 삭제
//   const handleClickDeleteAllDate = () => {
//     setSelectedDates([]);
//     setSelectedCalendarDates([]);
//     handlePoint({
//       ...point,
//       operation: {
//         ...point.operation,
//         closedDays: [],
//       },
//     });
//   };
