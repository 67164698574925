import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import AlertModal from 'components/common/modal/alert/AlertModal';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handle400Error } from 'utils/error/handle400Error';
import { changeMapZoom } from 'utils/map/control/camera';
import { ControllerProps } from './controller.type';

const ZoomEditController = (props: ControllerProps) => {
  // hook
  const { patchMachine } = useMachine();
  const { machineId } = useParams();
  // state
  const [editMode, setEditMode] = useState(false);
  const [zoom, setZoom] = useState<string>(props.machine.mapPosition.zoom);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [zoomError, setZoomError] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const defaultZoom = Number(value);
    const minZoom = Number(props.point.mapMinZoom);
    const maxZoom = Number(props.point.mapMaxZoom);

    if (defaultZoom < minZoom || defaultZoom > maxZoom) {
      setZoomError(true);
    } else {
      setZoomError(false);
    }

    changeMapZoom(defaultZoom);
    setZoom(String(defaultZoom));
  };

  const updateZoom = async () => {
    try {
      if (!zoom) {
        setOpenAlertPopup(true);
        setAlertMessage('입력된 값이 없습니다.');
        return;
      }
      if (machineId) {
        await patchMachine(machineId, {
          mapPosition: {
            zoom: String(zoom),
          },
        });
        await props.fetchMachine(machineId);
        setEditMode(false);
      }
    } catch (e) {
      handle400Error(e, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };

  const onClickCancel = () => {
    setEditMode(false);

    const zoom = props.machine.mapPosition.zoom;
    setZoom(zoom);
    changeMapZoom(Number(zoom));
    setZoomError(false);
  };

  return (
    <div className='flex flex-col gap-2.5 px-5 p-3'>
      <div className='flex justify-between h-6'>
        <FormControl
          name='줌 레벨'
          labelColor='text-gray-77'
          labelStyle={TextType.h4_bold}
          control={
            <div className='flex flex-col gap-3'>
              <div className='flex items-start py-1 px-3 gap-1 bg-bg-f9 text-[12px] rounded-full'>
                <span>Min {props.point.mapMinZoom}</span>
                <span>Max {props.point.mapMaxZoom}</span>
              </div>

              {editMode ? (
                <div className='flex flex-col gap-1'>
                  <Input
                    size={100}
                    textStyle={TextType.h5}
                    value={zoom || ''}
                    onChange={onChange}
                    min={props.point.mapMinZoom}
                    max={props.point.mapMaxZoom}
                  />
                  {zoomError && (
                    <div className={`${zoomError ? 'text-[#D94545]' : 'text-gray-cc'} text-[11px] whitespace-nowrap`}>
                      {`${props.point.mapMinZoom}과 ${props.point.mapMaxZoom}사이의 수만 가능합니다.`}
                    </div>
                  )}
                </div>
              ) : (
                <div className='flex items-center justify-start bg-bg-f9 w-[100px] h-[30px] px-2 text-h5 text-gray-99 '>
                  {props.machine.mapPosition.zoom}
                </div>
              )}
            </div>
          }
        />

        {editMode ? (
          <div className='flex gap-2.5'>
            <div className='h-6' onClick={updateZoom}>
              <img className='h-full' src={ICON.SUCCESS_BUTTON} alt='edit' />
            </div>
            <div className='h-6' onClick={onClickCancel}>
              <img className='h-full' src={ICON.CANCEL_BUTTON} alt='edit' />
            </div>
          </div>
        ) : (
          <div className='h-6' onClick={() => setEditMode(!editMode)}>
            <img className='h-full' src={ICON.PENCIL_BUTTON} alt='edit' />
          </div>
        )}
      </div>

      {openAlertPopup && <AlertModal message={alertMessage} closeModal={() => setOpenAlertPopup(false)} />}
    </div>
  );
};
export default ZoomEditController;
