import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { MachineLocationForm } from 'types/feature/machine/machine';
import { changeMapZoom } from 'utils/map/control/camera';
import { ControllerProps } from './controller.type';

const ZoomController = (props: ControllerProps) => {
  const { state } = useLocation();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    changeMapZoom(Number(value));
    props.setMachineLocationForm((prev: MachineLocationForm) => ({
      ...prev,
      mapPosition: {
        ...prev.mapPosition,
        zoom: value,
      },
    }));
  };

  return (
    <div className='flex flex-col items-start justify-center p-5 h-[158px]'>
      <FormControl
        name='줌 레벨'
        control={
          <div className='flex flex-col items-start gap-5'>
            <div className='flex items-start py-1 px-3 gap-1 bg-bg-f9 text-[12px] rounded-full'>
              <span>Min {state?.point.mapMinZoom}</span>
              <span>Max {state?.point.mapMaxZoom}</span>
            </div>

            <Input
              placeholder='기본 줌 레벨'
              type='text'
              value={props.machineLocationForm.mapPosition.zoom}
              onChange={onChange}
            />
          </div>
        }
      />
    </div>
  );
};

export default ZoomController;
