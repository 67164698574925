import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import ModalContainer from 'components/common/modal/container/ModalContainer';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import { OptionType } from 'components/common/select/SelectBox';
import Title, { TextType } from 'components/common/title/Title';
import useKeyboard from 'hooks/common/useKeyboard';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { useEffect, useState } from 'react';
import useFilterStore from 'stores/filter';
import { ModalType } from 'types/common/modalType';
import { ScreenType } from 'types/feature/content/contents';
import { DisplayType } from 'types/feature/screensaver/getScreensaver';
import { PostScreensaver } from 'types/feature/screensaver/postScreensaver';
import { WaitingList } from 'types/feature/screensaver/waitingList';
import { Point } from 'types/feature/workspace/workspace';
import { handle400Error } from 'utils/error/handle400Error';
import AddModalContents from './contents/AddModalContents';
import DisplaySetting from './display/DisplaySetting';

type Props = {
  closeModal: () => void;
  selectedContent: WaitingList | null;
  contents: WaitingList[];
  fetchContents: (screenType: ScreenType) => Promise<void>;
  handleClickItem: (content: WaitingList) => void;
  fetchScreensavers: (pointId: string, screenType: ScreenType) => Promise<void>;
  openDoneAlert: () => void;
  onChangeScreenType: (option: OptionType) => void;
  points: Point[];
  onChangePoint: (option: CommonOptionType) => void;
  selectedPoint: CommonOptionType | undefined;
};

const ScheduleAddModal = ({
  closeModal,
  selectedContent,
  contents,
  fetchContents,
  handleClickItem,
  fetchScreensavers,
  openDoneAlert,
  onChangeScreenType,
  points,
  onChangePoint,
  selectedPoint,
}: Props) => {
  const { postScreensaver } = useScreensaver();

  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isClicked, setClicked] = useState(false);

  const filterStore = useFilterStore();

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  // 저장 버튼 클릭
  const clickSave = () => {
    const valid = validScreensaver();

    if (valid) {
      setOpenConfirmPopup(true);
    }
  };

  useKeyboard({ modalType: ModalType.confirm, closeModal, callback: clickSave });

  // 기간 valid
  const validScreensaver = () => {
    if (!selectedContent) return;

    const { displayType, endDate } = selectedContent;

    // 기간노출인데
    if (displayType === DisplayType.DURATION) {
      // 종료 날짜가 없을 경우
      if (!endDate || endDate === 'Invalid Date') {
        setAlertMessage('종료날짜가 필요합니다.');
        setOpenAlert(true);
        return false;
      }
    }
    return true;
  };

  // 스케줄 추가
  const handleSubmit = async () => {
    try {
      setClicked(true);

      if (!selectedContent) return;

      const { id, displayType, startDate, endDate } = selectedContent;

      const screensaverToSubmit: PostScreensaver = {
        pointId: selectedPoint?.value || '',
        contentId: id,
        displayType,
        startDate,
        endDate: !endDate ? startDate : endDate, // ! 상시노출일 경우라도 endDate를 빈 값으로 넘겨주면 안됌
      };

      await postScreensaver(screensaverToSubmit);
      await fetchScreensavers(selectedPoint?.value || '', filterStore.scheduleScreenType);

      closeModal();
      openDoneAlert();

      setClicked(false);
    } catch (error) {
      handle400Error(error, handleOpenAlert);
      setAlertMessage(`스크린세이버 스케줄을 추가하는 중에\n문제가 발생하였습니다.`);
    }
  };

  useEffect(() => {
    fetchContents(filterStore.scheduleScreenType);
  }, [filterStore.scheduleScreenType]);

  return (
    <>
      <ModalContainer>
        <div className='w-[820px] pt-9 h-[720px] gap-6 flex flex-col'>
          <header className='px-10'>
            <Title text='스케줄 추가' textType={TextType.h3_bold} />
          </header>

          <Border borderColor={BorderColor.light_gray} />

          <AddModalContents
            contents={contents}
            selectedContent={selectedContent}
            handleClickItem={handleClickItem}
            onChangeScreenType={onChangeScreenType}
            points={points}
            onChangePoint={onChangePoint}
            selectedPoint={selectedPoint}
          />

          <Border borderColor={BorderColor.light_gray} />

          <DisplaySetting selectedContent={selectedContent} handleClickItem={handleClickItem} />

          <Border borderColor={BorderColor.light_gray} />

          <div className='flex items-center justify-center h-14 gap-2.5'>
            <Button size={120} text='취소' color={ButtonColor.secondary} onClick={closeModal} />
            <Button
              disabled={!selectedContent}
              size={120}
              text='저장'
              color={ButtonColor.primary}
              onClick={clickSave}
            />
          </div>
        </div>
      </ModalContainer>

      {openConfirmPopup && (
        <ConfirmModal
          disabled={isClicked}
          message='스크린세이버 스케줄을 추가하시겠습니까?'
          onClickOk={handleSubmit}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}

      {openAlert && <AlertModal width='min-w-[380px]' message={alertMessage} closeModal={() => setOpenAlert(false)} />}
    </>
  );
};
export default ScheduleAddModal;
