import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { MONITORING_MACHINE_TABLE_HEADER } from 'data/table/monitoring/monitoringMachineHeader';
import useMachines from 'hooks/feature/machine/useMachine';
import { Machine } from 'types/feature/machine/machine';
import MachineListTableRow from './MachineListTableRow';

type Props = {
  machineList: Machine[];
  moveToDetailPage: (machineId: string) => void;
};

const MachineList = ({ machineList, moveToDetailPage }: Props) => {
  const { resetMachine } = useMachines();

  const handleReloadMachine = async (machineId: string) => {
    await resetMachine(machineId);
  };

  return (
    <>
      <div className='px-10 py-6 bg-white border'>
        <table className='w-full border-collapse'>
          <CommonTableHeader tableHead={MONITORING_MACHINE_TABLE_HEADER} />

          {machineList.map((machine, index) => (
            <MachineListTableRow
              key={machine.id}
              machineIndex={index + 1}
              connectedMachine={machine}
              handleReloadMachine={handleReloadMachine}
              moveToDetailPage={moveToDetailPage}
            />
          ))}
        </table>

        {machineList.length === 0 && (
          <div className='flex items-center justify-center w-full h-16 border cursor-default text-gray-77 text-h4'>
            등록된 기기가 없습니다.
          </div>
        )}
      </div>
    </>
  );
};

export default MachineList;
