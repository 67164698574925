import Badge from 'components/common/badge/Badge';
import { Dispatch, SetStateAction, useState } from 'react';
import { WorkspaceForm } from 'types/feature/workspace/workspace';

type Props = {
  mobileUsed: boolean;
  setWorkspaceForm: Dispatch<SetStateAction<WorkspaceForm>>;
};

const ToggleMobileUsed = ({ mobileUsed, setWorkspaceForm }: Props) => {
  const [toggleMobileUsed, setToggleMobileUsed] = useState(mobileUsed);

  const handleToggleMobileUsed = () => {
    setToggleMobileUsed(!toggleMobileUsed);
    setWorkspaceForm((prev: WorkspaceForm) => ({
      ...prev,
      mobileUsed: !toggleMobileUsed,
    }));
  };

  return (
    <div
      className={`
      w-20 
      flex justify-start items-center
      `}
      onClick={handleToggleMobileUsed}
    >
      <Badge
        height='h-9'
        label={toggleMobileUsed ? '사용' : '미사용'}
        type={toggleMobileUsed ? 'USED' : 'NONE'}
        cursorStyle='pointer'
      />
    </div>
  );
};

export default ToggleMobileUsed;
