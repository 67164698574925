import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import LoadingBackground from 'components/common/loading/background/LoadingBackgroud';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import SimpleSelectBox from 'components/common/select/SimpleSelectBox';
import Textarea from 'components/common/textarea/Textarea';
import Title, { TextType } from 'components/common/title/Title';
import { WORKSPACE_TYPE_FILTERS, WORKSPACE_USE_TYPE_FILTERS } from 'constants/filters';
import useAccount from 'hooks/common/useAccount';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONTENTS_BG } from 'styles/background';
import { RoutePath } from 'types/common/paths';
import { Workspace, WorkspaceForm } from 'types/feature/workspace/workspace';
import { replacePointId, replaceWorkspaceId } from 'utils/common/replaceId';
import ToggleMobileUsed from './ToggleMobileUsed';
import ToggleParkingButtonUsed from './ToggleParkingButtonUsed';
import ToggleWebUsed from './ToggleWebUsed';
import ToggleZoomButtonUsed from './ToggleZoomButtonUsed';

const WorkspaceAddForm = () => {
  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceForm, setWorkspaceForm] = useState<WorkspaceForm>({
    name: '',
    description: '',
    mapId: '',
    mobileUsed: false,
    webUsed: false,
    zoomButtonUsed: false,
    machineType: 'DESKTOP',
    parkingUsed: false,
    type: '',
    useType: '',
  });
  const [openAlert, setOpenFailPopup] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmAfterSave, setOpenConfirmAfterSave] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [userMaps, setUserMaps] = useState<{ id: string; name: string }[]>([]);
  const [mapId, setMapId] = useState('');
  const [useType, setUseType] = useState('');
  const [type, setType] = useState('');
  // hook
  const { getAccount } = useAccount();
  const { saveWorkspace, getWorkspace } = useWorkspace();
  const navigate = useNavigate();

  // 페이지 상단으로 스크롤
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // 계정 정보
  useEffect(() => {
    const fetchAccount = async () => {
      const result = await getAccount();
      if (result) {
        setUserMaps(result.maps);
      }
    };

    fetchAccount();
  }, []);

  // validate
  const validateForm = (form: WorkspaceForm): boolean => {
    if (isEmpty(form.name)) {
      setOpenConfirm(false);
      handleAlert('워크스페이스 이름을 입력해주세요.');
      return false;
    }

    if (isEmpty(form.mapId)) {
      setOpenConfirm(false);
      handleAlert('지도를 선택해주세요.');
      return false;
    }

    if (isEmpty(form.useType)) {
      setOpenConfirm(false);
      handleAlert('상태를 선택해주세요.');
      return false;
    }

    if (isEmpty(form.type)) {
      setOpenConfirm(false);
      handleAlert('타입을 선택해주세요.');
      return false;
    }

    return true;
  };

  const onClickAddWorkspace = () => {
    if (validateForm(workspaceForm)) {
      setOpenConfirm(true);
    }
  };

  const handleAlert = (message: string) => {
    setOpenFailPopup(true);
    setSaveLoading(false);
    setOpenConfirmAfterSave(false);
    setAlertMessage(message);
  };

  const fetchWorkspace = async (workspaceId: string) => {
    const workspace = await getWorkspace(workspaceId);
    if (workspace) {
      setWorkspace(workspace);
    }
  };

  // 워크스페이스 생성
  const createWorkspace = async () => {
    try {
      setOpenConfirm(false);
      setSaveLoading(true);
      const data = await saveWorkspace(workspaceForm);

      if (data) {
        await fetchWorkspace(data.id);

        setOpenConfirmAfterSave(true);
        setSaveLoading(false);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          handleAlert('워크스페이스를 생성할 수 없습니다.');
        }
      }
    } finally {
      setOpenConfirm(false);
      setSaveLoading(false);
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setWorkspaceForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setWorkspaceForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeMapId = (value: string) => {
    setMapId(value);
    setWorkspaceForm(prev => ({
      ...prev,
      mapId: value,
    }));
  };

  const onChangeUseType = (value: string) => {
    setUseType(value);
    setWorkspaceForm(prev => ({
      ...prev,
      useType: value,
    }));
  };

  const onChangeType = (value: string) => {
    setType(value);
    setWorkspaceForm(prev => ({
      ...prev,
      type: value,
    }));
  };

  const moveToPointSettingAfterSave = () => {
    if (!workspace) return;

    navigate(
      replacePointId(
        //
        replaceWorkspaceId(RoutePath.pointSetting, workspace.id),
        workspace.points[0].id,
      ),
    );
  };

  return (
    <section className={`pl-[280px] pt-[40px] flex flex-col gap-6`}>
      {saveLoading && <LoadingBackground message='워크스페이스를 생성 중입니다...' />}
      <Title text='새 워크스페이스' textType={TextType.h1} />

      <div className={`${CONTENTS_BG} p-10 w-[1400px] flex flex-col gap-20`}>
        <div className={`grid grid-cols-2`}>
          <div className='flex flex-col gap-4'>
            <FormControl
              name='워크스페이스 이름'
              labelSize={168}
              required
              control={
                <Input size={382} name='name' placeholder='워크스페이스 이름을 입력해주세요' onChange={onChangeInput} />
              }
            />

            <FormControl
              name='상세 정보'
              labelSize={168}
              control={
                <Textarea
                  placeholder='상세 정보를 입력해주세요'
                  name='description'
                  width='w-[382px]'
                  height='h-[140px]'
                  onChange={onChangeTextArea}
                />
              }
            />

            <FormControl
              name='지도'
              labelSize={168}
              required
              control={
                <SimpleSelectBox
                  name='mapId'
                  width={384}
                  onChange={onChangeMapId}
                  selected={mapId}
                  options={[
                    { label: '지도를 선택해주세요', value: '' },
                    ...userMaps.map(({ id, name }) => {
                      return {
                        label: name,
                        value: id,
                      };
                    }),
                  ]}
                />
              }
            />

            <FormControl
              name='상태'
              labelSize={168}
              required
              control={
                <SimpleSelectBox
                  name='useType'
                  width={276}
                  selected={useType}
                  onChange={onChangeUseType}
                  options={[
                    { value: '', label: '상태를 선택해주세요' },
                    ...WORKSPACE_USE_TYPE_FILTERS.map(({ id, name }) => {
                      return {
                        label: name,
                        value: id,
                      };
                    }),
                  ]}
                />
              }
            />

            <FormControl
              name='타입'
              labelSize={168}
              required
              control={
                <SimpleSelectBox
                  name='type'
                  width={276}
                  selected={type}
                  onChange={onChangeType}
                  options={[
                    { value: '', label: '타입을 선택해주세요' },
                    ...WORKSPACE_TYPE_FILTERS.map(({ id, name }) => {
                      return {
                        label: name,
                        value: id,
                      };
                    }),
                  ]}
                />
              }
            />
          </div>

          <div className='flex flex-col gap-4'>
            <FormControl
              name='모바일 키오스크'
              labelSize={168}
              control={<ToggleMobileUsed mobileUsed={workspaceForm.mobileUsed} setWorkspaceForm={setWorkspaceForm} />}
            />

            <FormControl
              name='웹 키오스크'
              labelSize={168}
              control={<ToggleWebUsed webUsed={workspaceForm.webUsed} setWorkspaceForm={setWorkspaceForm} />}
            />

            <FormControl
              name='주차위치 지정'
              labelSize={168}
              control={
                <ToggleParkingButtonUsed
                  zoomButtonUsed={workspaceForm.parkingUsed}
                  setWorkspaceForm={setWorkspaceForm}
                />
              }
            />
            <FormControl
              name='줌버튼'
              labelSize={168}
              control={
                <ToggleZoomButtonUsed
                  zoomButtonUsed={workspaceForm.zoomButtonUsed}
                  setWorkspaceForm={setWorkspaceForm}
                />
              }
            />
          </div>
        </div>

        <div className='w-full flex justify-center items-center gap-2.5'>
          <Button
            size={120}
            text='취소'
            type='button'
            color={ButtonColor.secondary}
            onClick={() => navigate(RoutePath.workspaces)}
          />
          <Button size={120} text='생성' type='button' color={ButtonColor.primary} onClick={onClickAddWorkspace} />
        </div>
      </div>

      {openAlert && <AlertModal width='w-[340px]' closeModal={() => setOpenFailPopup(false)} message={alertMessage} />}
      {openConfirm && (
        <ConfirmModal
          okMessage='저장'
          cancelMessage='취소'
          message={`새로운 워크스페이스를 생성하시겠습니까?`}
          onClickOk={async () => await createWorkspace()}
          onClickCancel={() => setOpenConfirm(false)}
        />
      )}
      {openConfirmAfterSave && (
        <ConfirmModal
          okMessage='빌딩 설정하기'
          cancelMessage='목록으로 이동하기'
          message={`워크스페이스 저장이 완료되었습니다.\n빌딩 정보를 설정하시겠습니까?`}
          onClickOk={moveToPointSettingAfterSave}
          onClickCancel={() => navigate(RoutePath.workspaces)}
        />
      )}
    </section>
  );
};
export default WorkspaceAddForm;
