import { SETTING_MENUS } from 'data/guide/menu';
import useAccountInfo from 'hooks/common/useAccountInfo';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import usePoint from 'hooks/feature/point/usePoint';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Point } from 'types/feature/workspace/workspace';
import { replacePointId, replaceWorkspaceId } from 'utils/common/replaceId';

type Props = {
  setCurrentMenu: Dispatch<SetStateAction<RoutePath>>;
};

type ChildrenMenu = {
  id: string;
  name: string;
  path?: string;
};

type Menu = {
  id: string;
  name: string;
  path: RoutePath;
  children?: ChildrenMenu[];
};

const SettingNavigation = ({ setCurrentMenu }: Props) => {
  // state
  const [menus, setMenus] = useState<Menu[]>();
  const [currentId, setCurrentId] = useState<string>('');
  const [currentSubMenu, setCurrentSubMenu] = useState('');
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  // hook
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { getPoints } = usePoint();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { getAccountInfo } = useAccountInfo();

  const handleClick = (menu: Menu) => {
    setCurrentId(menu.id);
    setCurrentSubMenu('');

    if (menu.children) {
      setOpenDropdown(prev => !prev);
    } else {
      setCurrentMenu(menu.path);
      navigateToReplacedPath(menu.path, workspaceId);
      setOpenDropdown(false);
    }
  };

  const handleSubClick = (menu: Menu, child: ChildrenMenu) => {
    setCurrentMenu(menu.path);
    setCurrentSubMenu(child.id);

    if (menu.id === 'point' && workspaceId) {
      navigate(replacePointId(replaceWorkspaceId(RoutePath.pointSetting, workspaceId), child.id));
    }
  };

  const fetchPoints = async (id: string) => {
    const points = await getPoints(id);
    const account = await getAccountInfo();

    if (!points) return;

    const children = points.map((point: Point) => ({
      id: point.id,
      name: point.name.ko,
    }));

    // menu setting
    // setMenus(prev => {
    const filteredMenus = SETTING_MENUS.map(menu => {
      if (menu.id === 'point') {
        return { ...menu, children };
      }
      return menu;
    });

    if (account?.admin) {
      setMenus(filteredMenus);
    } else {
      const USER_MENUS = ['language', 'theme'];
      const filteredRoleMenu = filteredMenus.filter(filteredMenu => !USER_MENUS.includes(filteredMenu.id));
      setMenus(filteredRoleMenu);
    }

    // });
  };

  useEffect(() => {
    if (workspaceId) {
      fetchPoints(workspaceId);
    }
  }, [workspaceId]);

  useEffect(() => {
    const filteredMenu = SETTING_MENUS.filter(menu => location.pathname.includes(menu.id))[0];
    setCurrentId(filteredMenu.id);
  }, []);

  return (
    <div className='flex flex-col justify-start shrink-0 w-[200px] pt-5 fixed h-full'>
      <ul className='menu-horizontal'>
        {menus?.map(menu => (
          <li key={menu.id}>
            <div
              className={`${
                currentId === menu.id && 'font-bold'
              } cursor-pointer text-base hover:font-bold pr-5 h-[48px] flex items-center text-gray-44`}
              onClick={() => handleClick(menu)}
            >
              {menu.name}
            </div>
            <ul
              className={`overflow-hidden transition-all duration-300 ease-in-out ${
                menu.children && openDropdown ? `opacity-100` : 'opacity-0'
              }`}
              style={{
                marginTop: '-5px',
                maxHeight: menu.children && openDropdown ? `${menu.children.length * 48}px` : '0',
              }}
            >
              {menu.children?.map((child: ChildrenMenu) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={child.id}
                  className={`pl-2 h-[28px] flex items-center`}
                  onClick={() => handleSubClick(menu, child)}
                >
                  <div
                    className={`${
                      currentSubMenu === child.id ? 'font-medium text-gray-77' : 'text-gray-99'
                    } cursor-pointer hover:font-medium pr-5 h-[48px] flex items-center text-gray-77`}
                  >
                    {child.name}
                  </div>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingNavigation;
