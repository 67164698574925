import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import FormControl from 'components/common/form-control/FormControl';
import SimpleSelectBox from 'components/common/select/SimpleSelectBox';
import Title, { TextType } from 'components/common/title/Title';
import { MACHINE_PLACE_TYPE_FILTERS } from 'constants/filters';
import useFloor from 'hooks/feature/floor/useFloor';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Floor } from 'types/feature/floor/floor';
import { MachineLocationForm, MachinePlaceType } from 'types/feature/machine/machine';
import { Workspace } from 'types/feature/workspace/workspace';
import MachineAddButtons from './MachineAddButtons';
import MachineAddControllers from './MachineAddControllers';
import MachineAddMap from './MachineAddMap';
import { ControllerProps } from './controllers/controller.type';
import useAccountInfoStore from 'stores/accountInfo';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import { RoutePath } from 'types/common/paths';

const MachineAddContainer = () => {
  // hook
  const { getWorkspace } = useWorkspace();
  const { getFloors } = useFloor();
  const { workspaceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [floorList, setFloorList] = useState<Floor[]>([]);
  const [selectedFloorId, setSelectedFloorId] = useState<string>(location.state?.floorId || floorList?.[0]?.id || '');
  const [selectedPlaceType, setSelectedPlaceType] = useState<MachinePlaceType>(location.state?.placeType || 'CLIENT');
  const [machineLocationForm, setMachineLocationForm] = useState<MachineLocationForm>({
    name: '',
    floorId: '',
    screenType: 'VERTICAL',
    description: '',
    positionX: '0',
    positionY: '0',
    mapPosition: {
      centerPositionX: '0',
      centerPositionY: '0',
      rotation: '0',
      zoom: '',
    },
  });
  // store
  const accountInfoStore = useAccountInfoStore();

  useEffect(() => {
    if (!workspaceId) return;
    if (!accountInfoStore.accountInfo?.admin) {
      navigate(replaceWorkspaceId(RoutePath.dashboard, workspaceId));
    }
  }, [accountInfoStore.accountInfo]);

  const fetchWorkspaceAndFloors = async (workspaceId: string) => {
    // 워크스페이스 조회
    const workspace = await getWorkspace(workspaceId);
    setWorkspace(workspace);

    const pointId = location.state?.point ? location.state.point.id : workspace.points[0].id;
    const floors = await getFloors(pointId);
    if (floors) {
      setFloorList(floors);
    }

    if (location.state?.floorId) {
      setMachineLocationForm((prev: MachineLocationForm) => ({
        ...prev,
        floorId: location.state.floorId,
      }));
    } else {
      // 메인 층 설정
      const mainFloor = floors.find(floor => floor.main);
      if (mainFloor) {
        setMachineLocationForm((prev: MachineLocationForm) => ({
          ...prev,
          floorId: mainFloor.id,
        }));
      }
    }
  };

  const onChangePlaceType = (placeType: string) => {
    setSelectedPlaceType(placeType as MachinePlaceType);
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspaceAndFloors(workspaceId);
    }
  }, [workspaceId]);

  // props
  const controllerProps: ControllerProps = {
    machineLocationForm,
    setMachineLocationForm,
    floors: floorList,
    floorId: selectedFloorId,
    setSelectedFloorId,
  };

  if (!workspace) {
    return <> </>;
  }

  const pointName = location.state?.point ? location.state.point.name.ko : workspace.points[0].name.ko;

  return (
    <section className='flex flex-col w-full gap-10 pb-20'>
      {/* 기본 정보 */}
      <div className='flex flex-col gap-5'>
        <Title text='기본 정보' textType={TextType.h3_bold} />
        <main className='flex w-full gap-3'>
          <section className='flex flex-col w-full gap-3'>
            <FormControl
              name='구분'
              control={
                <SimpleSelectBox
                  width={160}
                  selected={selectedPlaceType}
                  onChange={onChangePlaceType}
                  options={[...MACHINE_PLACE_TYPE_FILTERS].filter(filter => filter.value)}
                />
              }
            />
          </section>
        </main>
      </div>

      <Border borderColor={BorderColor.gray} />

      {/* 지도 정보 */}
      <section className='flex flex-col gap-5'>
        <div className='flex gap-2.5 items-center'>
          <Title text='기기 위치 정보' textType={TextType.h3_bold} />
          <div className='text-xs'>
            기기가 등록될 빌딩은 <b>{pointName || ''}입니다.</b>
          </div>
        </div>

        <div className='h-[666px] flex border'>
          {/* 지도 */}
          <MachineAddMap
            machineLocationForm={machineLocationForm}
            point={location.state?.point ? location.state.point : workspace.points[0]}
          />

          {/* 설정들 */}
          <MachineAddControllers {...controllerProps} />
        </div>
      </section>

      {/* 저장, 취소 버튼 */}
      <MachineAddButtons
        floorId={selectedFloorId}
        placeType={selectedPlaceType}
        machinePlaceForm={machineLocationForm}
      />
    </section>
  );
};
export default MachineAddContainer;
