import { ICON } from 'constants/icons';
import { IMAGES } from 'constants/images';
import { TextType } from 'types/common/text';
import { ColorSet } from 'types/feature/setting/theme/theme.type';
import ThemePreviewFooter from '../common/footer/ThemePreviewFooter';
import ThemePreviewMenu from '../common/menu/ThemePreviewMenu';
import ThemePreviewSearchBlock from '../common/search/ThemePreviewSearchBlock';

type Props = {
  usedColorSet: ColorSet | undefined;
};

const ThemeFloorPreview = ({ usedColorSet }: Props) => {
  const mainColor = usedColorSet?.colors.find(color => color.type === 'MAIN')?.color ?? '';
  const subColor = usedColorSet?.colors.find(color => color.type === 'SUB')?.color ?? '';
  const naviColor = usedColorSet?.colors.find(color => color.type === 'NAVI')?.color ?? '';

  return (
    <div className='w-[280px] h-full'>
      {/* 층 이름 */}
      <div className='h-[68px] bg-white px-5 flex items-center justify-start border-b-2 border-gray-ea'>
        <span className={TextType.h4_bold}>1F</span>
      </div>

      {/* 지도 */}
      <div className='flex'>
        <div className='relative flex items-center justify-center'>
          <img src={IMAGES.PREVIEW_MAP} alt='미리보기 지도' />

          <div className='absolute flex flex-col self-center pt-3 pl-6'>
            <svg xmlns='http://www.w3.org/2000/svg' width='132' height='172' viewBox='0 0 132 172' fill='none'>
              <path d='M15 166H66.94V14H113' stroke={naviColor} strokeWidth='5' />
            </svg>
          </div>
        </div>

        {/* 층 목록 */}
        <div className='w-[50px] flex flex-col'>
          <div>
            {['3F', '2F', '1F', 'B1', 'B2'].map(floor => (
              <div
                key={floor}
                style={{ background: `${floor === '1F' ? mainColor : '#fff'}` }}
                className={`h-[53px] flex items-center justify-center w-full`}
              >
                <span
                  className={` ${floor === '1F' ? `${TextType.h5_bold} text-white` : `${TextType.h5} text-gray-44`}`}
                >
                  {floor}
                </span>
              </div>
            ))}
          </div>

          {/* 현재위치 버튼 */}
          <div style={{ background: subColor }} className='flex items-center justify-center w-full h-full'>
            <img src={ICON.CURRENT_POSITION} alt='현재위치 아이콘' />
          </div>
        </div>
      </div>

      <ThemePreviewSearchBlock blockTitle='편의시설' />

      <ThemePreviewMenu activeMenu='floor' usedColorSet={usedColorSet} />
      <ThemePreviewFooter />
    </div>
  );
};
export default ThemeFloorPreview;
