/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import { ErrorCode, FileError, FileRejection, useDropzone } from 'react-dropzone';
import { FileType } from 'types/common/file/file';
import { TextType } from 'types/common/text';
import { convertMbToByte } from 'utils/file/size/convertMbToByte';
import FileLoading from '../loading/FileLoading';
import FileName from '../name/FileName';
import FilePreview from '../preview/FilePreview2';

type Accept = {
  'image/jpeg'?: string[];
  'image/png'?: string[];
  'video/mp4'?: string[];
};

type Props = {
  maxSize: number;
  uploadFile: (file: File) => Promise<void>;
  fileUri: string;
  fileType: FileType;
  deleteFile: () => void;
  accept: Accept;
  openFileErrorAlert: (message: string) => void;
  loading: boolean;
  fileName?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  previewWidth?: string;
  defaultValue?: string;
  disabledText?: string;
  isLogo?: boolean;
  readOnly?: boolean;
};

const FileDropZone2 = ({
  maxSize,
  uploadFile,
  fileUri,
  fileType,
  deleteFile,
  accept,
  openFileErrorAlert,
  loading,
  fileName,
  disabled = false,
  width = 'w-full',
  height = 'h-[160px]',
  previewWidth,
  defaultValue,
  disabledText,
  isLogo,
  readOnly,
}: Props) => {
  // drop 했을 경우
  const onDrop = async (acceptedFiles: File[]) => {
    await uploadFile(acceptedFiles[0]);
  };

  // drop 실패 시
  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections[0].errors.forEach(({ code }: FileError) => {
      switch (code) {
        case ErrorCode.FileInvalidType:
          openFileErrorAlert('파일 확장자를 확인해주세요.');
          break;

        case ErrorCode.FileTooSmall:
          openFileErrorAlert('파일 용량이 너무 작습니다.');
          break;

        case ErrorCode.FileTooLarge:
          openFileErrorAlert(`파일 용량은 ${maxSize}MB를 넘어갈 수 없습니다.`);
          break;

        case ErrorCode.TooManyFiles:
          openFileErrorAlert('파일을 최대 1개만 등록할 수 있습니다.');
          break;

        default:
          openFileErrorAlert('파일을 업로드할 수 없습니다.');
          break;
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    maxSize: convertMbToByte(maxSize),
    maxFiles: 1,
  });

  return (
    <div className={`flex-1 shrink-0 ${width}`}>
      {/* 파일 이름 / defaultValue */}
      <div className='flex flex-col gap-2'>
        <FileName
          fileName={fileName}
          deleteFile={deleteFile}
          defaultValue={defaultValue}
          disabled={disabled}
          disabledText={disabledText}
          readOnly={readOnly}
        />
      </div>

      {
        <div className={`flex flex-col flex-1 object-contain h-full shrink-0 ${width}`}>
          {loading ? (
            // 로딩중일 경우
            <div className={`${height}`}>
              <FileLoading />
            </div>
          ) : disabled ? (
            // 파일 업로드 불가능할 경우
            <> </>
          ) : fileUri ? (
            // 파일 미리보기
            <div
              className={`border flex items-center justify-center ${width} ${height} ${
                isLogo ? 'bg-[#272A36] border-[#272A36]' : 'bg-white border-gray-99'
              }`}
            >
              <FilePreview fileType={fileType} fileUri={fileUri} width={previewWidth} />
            </div>
          ) : (
            <>
              {/* 드래그 영역 */}
              <div
                {...getRootProps()}
                className={`items-center cursor-pointer 
                rounded border border-dashed border-gray-44
                flex flex-col justify-center
                hover:bg-[#f1f1f1] flex-1 shrink-0
                ${width} ${height}
              `}
              >
                <div className={`${width} ${height} flex items-center justify-center text-xs text-center`}>
                  <input {...getInputProps()} />

                  {/* 파일 업로드 가능한 상태 */}
                  {isDragAccept && <p className={`text-xs text-gray-99`}>파일을 여기에 놓아주세요!</p>}

                  {/* 업로드 불가한 상태 */}
                  {isDragReject && <p className={`text-xs text-gray-99`}>업로드할 수 없는 파일입니다.</p>}

                  {/* default 상태 */}
                  {!isDragActive && (
                    <div className='flex flex-col items-center gap-1'>
                      <div className='h-6 w-[57px] bg-gray-22 rounded text-white flex items-center justify-center'>
                        파일선택
                      </div>
                      <div className={`${TextType.body2} text-gray-44`}>또는 파일을 여기로 드래그해주세요</div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      }
    </div>
  );
};

export default FileDropZone2;
