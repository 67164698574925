import Badge from 'components/common/badge/Badge';
import { Dispatch, SetStateAction, useState } from 'react';
// import { WorkspaceBasicForm } from 'types/feature/workspace/workspace';

type Props = {
  readOnly?: boolean;
  zoomButtonUsed: boolean;
  setWorkspaceForm: Dispatch<SetStateAction<any>>;
};

const ToggleParkingButtonUsed = ({ zoomButtonUsed, setWorkspaceForm, readOnly }: Props) => {
  const [toggleZoomButtonUsed, setToggleZoomButtonUsed] = useState(zoomButtonUsed);

  const handleToggleMobileUsed = () => {
    if (readOnly) return;
    setToggleZoomButtonUsed(!toggleZoomButtonUsed);
    setWorkspaceForm((prev: any) => ({
      ...prev,
      parkingUsed: !toggleZoomButtonUsed,
    }));
  };

  return (
    <div
      className={`
      w-20 
      flex justify-start items-center
      `}
      onClick={handleToggleMobileUsed}
    >
      <Badge
        height='h-9'
        label={toggleZoomButtonUsed ? '사용' : '미사용'}
        type={toggleZoomButtonUsed ? 'USED' : 'NONE'}
      />
    </div>
  );
};

export default ToggleParkingButtonUsed;
