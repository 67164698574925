import CenterPositionController from './controllers/CenterPositionController';
import { ControllerProps } from './controllers/controller.type';
import DescriptionController from './controllers/DescriptionController';
import FloorController from './controllers/FloorController';
import PositionController from './controllers/PositionController';
import RotationController from './controllers/RotationController';
import ScreenTypeController from './controllers/ScreenTypeController';
import ZoomController from './controllers/ZoomController';

const MachineAddControllers = (props: ControllerProps) => {
  return (
    <div className='w-[497px] bg-white'>
      <FloorController {...props} />
      <ScreenTypeController {...props} />
      <DescriptionController {...props} />
      <PositionController {...props} />
      <CenterPositionController {...props} />
      <RotationController {...props} />
      <ZoomController {...props} />
    </div>
  );
};
export default MachineAddControllers;
