import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent, useEffect } from 'react';
import { MachineLocationForm } from 'types/feature/machine/machine';
import { changeCameraFocus } from 'utils/map/control/camera';
import { ControllerProps } from './controller.type';

const CenterPositionController = (props: ControllerProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    props.setMachineLocationForm((prev: MachineLocationForm) => ({
      ...prev,
      mapPosition: {
        ...prev.mapPosition,
        [name]: value,
      },
    }));
  };

  // 기기 등록시 입력한 지도의 센터값이 바뀔 때, 카메라 포커스를 변경
  useEffect(() => {
    changeCameraFocus({
      x: Number(props.machineLocationForm.mapPosition.centerPositionX),
      y: Number(props.machineLocationForm.mapPosition.centerPositionY),
    });
  }, [props.machineLocationForm]);
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='지도 센터 좌표'
        labelSize={120}
        control={
          <div className='flex gap-5'>
            <FormControl
              name='X'
              labelSize='none'
              control={
                <Input
                  size={120}
                  placeholder='X 좌표'
                  name='centerPositionX'
                  value={props.machineLocationForm.mapPosition.centerPositionX}
                  onChange={onChange}
                />
              }
            />
            <FormControl
              name='Y'
              labelSize='none'
              control={
                <Input
                  size={120}
                  placeholder='Y 좌표'
                  name='centerPositionY'
                  value={props.machineLocationForm.mapPosition.centerPositionY}
                  onChange={onChange}
                />
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default CenterPositionController;
