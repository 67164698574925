import CenterPositionEditController from './controllers/CenterPositionEditController';
import { ControllerProps } from './controllers/controller.type';
import DescriptionEditController from './controllers/DescriptionEditController';
import FloorEditController from './controllers/FloorEditController';
import PositionEditController from './controllers/PositionEditController';
import RotationEditController from './controllers/RotationEditController';
import ScreenTypeEditController from './controllers/ScreenTypeEditController';
import ZoomEditController from './controllers/ZoomEditController';

const MachineDetailControllers = (props: ControllerProps) => {
  return (
    <div className='w-full h-full bg-white border-l'>
      <FloorEditController {...props} />
      <ScreenTypeEditController {...props} />
      <DescriptionEditController {...props} />
      <PositionEditController {...props} />
      <CenterPositionEditController {...props} />
      <RotationEditController {...props} />
      <ZoomEditController {...props} />
    </div>
  );
};
export default MachineDetailControllers;
