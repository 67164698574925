import FormControl from 'components/common/form-control/FormControl';
import Switch from 'components/common/switch/Switch';
import Title, { TextType } from 'components/common/title/Title';
import { Point } from 'types/feature/workspace/workspace';
import HolidayForm from './HolidayForm';
import { Dispatch, SetStateAction, useState } from 'react';

type Props = {
  point: Point;
  handleChangeOption: (closedDaysUsed: boolean) => void;
};

const Holiday = ({ point, handleChangeOption }: Props) => {
  if (!point) {
    return <></>;
  }

  return (
    <>
      <Title text='휴무일 설정' textType={TextType.h3_bold} />
      <div className='flex flex-col gap-6'>
        <div className='flex items-center gap-2.5'>
          <FormControl
            name='휴무일 사용여부'
            required
            control={
              <Switch
                type='USE'
                color='BLUE'
                value={point.operation.closedDaysUsed}
                onChangeOption={handleChangeOption}
              />
            }
          />
        </div>
        {point.operation.closedDaysUsed && (
          <FormControl name='날짜 선택' required control={<HolidayForm pointId={point.id} />} />
        )}
      </div>
    </>
  );
};

export default Holiday;
