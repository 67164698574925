import { MouseEvent } from 'react';
import { TextType } from '../title/Title';

export type ButtonProps = {
  loading?: boolean;
  text?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  color: ButtonColor | string;
  size?: keyof typeof ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  children?: React.ReactElement;
  buttonStyle?: string;
  textStyle?: TextType;
  id?: string;
  iconPath?: string;
};

export const ButtonSize = {
  default: 'h-9 px-3',
  rounded: 'h-6 px-3 rounded',
  more: 'w-[47px] h-[24px] px-3 rounded pt-[1px]',
  csv: 'w-[106px] h-[36px] pl-2 pr-3',
  80: 'h-9 w-20',
  96: 'w-24 h-9',
  100: 'w-[100px]',
  120: 'w-30 h-14',
  128: 'w-32 h-9',
  360: 'w-90 h-14',
};

export enum ButtonColor {
  // 어두운 남색 버튼
  primary = `bg-button-primary-normal border-button-primary-border-normal text-gray-ea 
  hover:bg-button-primary-hover hover:border-button-primary-border-hover 
  active:bg-button-primary-press active:border-button-primary-border-press`,

  primary_disable = `bg-button-primary-disable border-button-primary-border-disable text-gray-ea`,

  // 흰색 버튼
  secondary = `bg-button-secondary-normal border-button-secondary-border-normal text-gray-44 
  hover:bg-button-secondary-hover hover:border-button-secondary-border-hover hover:font-bold 
  active:font-bold active:bg-button-secondary-press active:border-button-secondary-border-press`,

  secondary_disable = `bg-button-secondary-disable border-button-secondary-border-disable text-gray-cc`,

  // 포인트 색(파란색) 버튼
  point = `bg-primary-DA_Blue_light border-button-point-normal text-white 
  hover:bg-button-point-hover hover:border-button-point-border-hover 
  active:bg-button-point-press active:border-button-point-border-press`,

  point_disable = `bg-button-point-disable border-button-point-disable text-gray-ea`,

  // 삭제 버튼
  delete = `bg-button-delete-normal border-button-delete-border-normal text-button-delete-border-hover 
  hover:border-button-delete-border-hover hover:font-bold active:bg-button-delete-press 
  active:border-button-delete-border-press active:text-button-delete-border-press`,

  delete_disable = `border-gray-cc text-gray-cc bg-transparent`,

  // gray
  gray = `bg-transparent border-gray-cc text-[#888]
  hover:border-[#888] hover:font-bold`,

  gray_disable = `border-gray-cc text-gray-cc bg-bg-f1`,

  // csv
  csv = `bg-white border-gray-99 text-[#444]
  hover:border-[#444] hover:font-bold`,

  csv_disable = `bg-transparent border-gray-ea text-[#999]`,

  red = 'border border-primary-DA_Red text-primary-DA_Red',
}
