import { AxiosError } from 'axios';
import AlertPopup from '../../popup/AlertPopup';
import Title from 'components/common/title/Title';
import useLanguage from 'hooks/feature/language/useLanguage';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { Language } from 'types/feature/multi-lang/language';
import { handleSortOrderAsc } from 'utils/sort-order/handleSortOrderAsc';
import UpdateDisplayLanguagePopup from '../../popup/UpdateDisplayLanguagePopup';
import LanguageRows from './table/row/LanguageRows';
import { TextType } from 'types/common/text';

const LanguageSettingContainer = () => {
  const [languageList, setLanguageList] = useState<Language[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [updateDisplayLanguage, setUpdateDisplayLanguage] = useState(false);

  const { workspaceId } = useParams();
  const { getLanguages, updateLanguage } = useLanguage();

  const handleAlert = () => {
    setAlertMessage('더 이상 순서를 변경할 수 없습니다.');
    setOpenAlert(true);
  };

  const handleRow = async (dropResult: DropResult) => {
    try {
      const sourceIndex = dropResult.source.index;
      const currentItemId = languageList[sourceIndex].id;

      const sortOrder = handleSortOrderAsc(dropResult, languageList);

      if (sortOrder) {
        await updateLanguage(currentItemId, {
          sortOrder,
        });
        await fetchLanguageList();
      }
    } catch (e) {
      if (e instanceof Error) {
        handleAlert();
      }
    }
  };

  const fetchLanguageList = async () => {
    try {
      if (workspaceId) {
        const result = await getLanguages();
        if (result) {
          setLanguageList(result);
        }
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.status && e.status === 400) {
          setAlertMessage(e.message);
          setOpenAlert(true);
        }
      }
    }
  };

  useEffect(() => {
    fetchLanguageList();
  }, [updateDisplayLanguage]);

  return (
    <>
      <div className='flex flex-col justify-start w-full h-full gap-5'>
        <Title text='언어' textType={TextType.h2} />

        <section className='flex flex-col gap-10 p-10 bg-white border border-gray-ea'>
          <table className='w-full'>
            <colgroup>
              <col width='5%' />
              <col width='14%' />
              <col width='30%' />
              <col width='30%' />
              <col width='20%' />
            </colgroup>

            <thead className='border-t border-gray-44'>
              <tr className='h-10 text-sm border-b-2 whitespace-nowrap border-gray-44'>
                <th> </th>
                <th>메인언어</th>
                <th>언어</th>
                <th>
                  <div className='flex items-center justify-center gap-[10px]'>
                    <div>노출명</div>
                    <div
                      onClick={() => {
                        setUpdateDisplayLanguage(true);
                      }}
                      className='flex items-center justify-center w-6 h-6 p-[3px] rounded border border-gray-cc bg-white
                      cursor-pointer'
                    >
                      <svg xmlns='http://www.w3.org/2000/svg' width='19' height='18' viewBox='0 0 19 18' fill='none'>
                        <path
                          d='M12.4858 4L14.5 6.01425L7.3057 13.2085L4.5 14L5.29145 11.1943L12.4858 4Z'
                          stroke='#999999'
                          strokeWidth='1.2'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>사용 여부</th>
              </tr>
            </thead>

            <DragDropContext onDragEnd={handleRow}>
              <Droppable droppableId='table'>
                {provided => {
                  return (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      {languageList
                        .sort((a: Language, b: Language) => a.sortOrder - b.sortOrder)
                        .map((language, index) => {
                          return (
                            <Draggable draggableId={`drag-${index}`} index={index} key={`drag-${index}`}>
                              {draggableProvided => (
                                <LanguageRows
                                  getLanguageList={fetchLanguageList}
                                  provided={draggableProvided}
                                  language={language}
                                />
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </tbody>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </table>
        </section>
      </div>
      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
      {updateDisplayLanguage && (
        <UpdateDisplayLanguagePopup languageList={languageList} closePopup={() => setUpdateDisplayLanguage(false)} />
      )}
    </>
  );
};

export default LanguageSettingContainer;
