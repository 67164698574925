import GuideDescription from 'components/common/description/GuideDescription';
import MenuTypes from './checkbox/MenuTypes';
import { CustomPageType } from 'types/feature/menu/customPage';
import FormControl from 'components/common/form-control/FormControl';

type Props = {
  handleClickMenuType: (type: string) => void;
  selectedCustomMenuType: CustomPageType;
};

const AddMenuType = ({ handleClickMenuType, selectedCustomMenuType }: Props) => {
  return (
    <div className='flex w-full h-24 gap-3 px-10 py-3 border-b'>
      <FormControl
        name='메뉴 타입'
        // labelTextSize='h3_bold'
        control={
          <div>
            {/* 메뉴 타입 선택 */}
            <MenuTypes handleClickMenuType={handleClickMenuType} selectedCustomMenuType={selectedCustomMenuType} />

            {/* 가이드 */}
            <GuideDescription manualInfo={'메뉴 타입은 수정할 수 없습니다.'} bgColor='none' infoColor='red' />
          </div>
        }
      />
    </div>
  );
};
export default AddMenuType;
