import { IndexType } from 'types/common';

export type LangCode = 'KO' | 'EN' | 'JA' | 'CN' | 'TW';

export type Language = {
  id: string;
  code: LangCode; //LangCode;
  main: boolean; // backend api
  used: boolean;
  display: boolean;
  sortOrder: number;
  defaultYn?: boolean; // 지도에서 가져올 경우
  displayName: string;
};

export type MultiLangs = {
  [code: string]: string;
  ko: string;
  en: string;
  ja: string;
  cn: string;
  tw: string;
};

export type MultiLangsWordLimit = {
  [code: string]: number;
  ko: number;
  en: number;
  ja: number;
  cn: number;
  tw: number;
};

export const LanguageName: IndexType = {
  KO: '한국어',
  EN: '영어',
  JA: '일본어',
  CN: '중국어 간체',
  TW: '중국어 번체',
};

export enum LangNameKo {
  KO = '한국어',
  EN = '영어',
  JA = '일본어',
  CN = '중국어 간체',
  TW = '중국어 번체',
}
export const LanguageExposureName: IndexType = {
  KO: '한국어',
  EN: 'English',
  JA: '日本語',
  CN: '簡體中文',
  TW: '繁體中文',
};

export type UpdateLanguage = {
  used?: boolean;
  display?: boolean;
  sortOrder?: number;
};

export type DisplayNameUpdateData = { displayName: string };
export enum UpdateLanguageType {
  display = 'display',
  used = 'used',
}
