import { SimpleSelectOption } from 'components/common/select/SimpleSelectBox';

export const MACHINE_PLACE_TYPE_FILTERS: SimpleSelectOption[] = [
  { value: '', label: '구분 전체' },
  { value: 'CLIENT', label: '고객사' },
  { value: 'CLIENT_OFFICE', label: '고객사 사무실' },
  { value: 'DEVELOPER', label: '개발자' },
  { value: 'ETC', label: '기타' },
];

export const MACHINE_STATE_TYPE_FILTERS: SimpleSelectOption[] = [
  { value: '', label: '상태 전체' },
  { value: 'RUNNING', label: '운영중' },
  { value: 'PREPARED', label: '미배치' },
  { value: 'EXPIRED', label: '폐기' },
];

export const MACHINE_CONNECT_STATE_TYPE_FILTERS: SimpleSelectOption[] = [
  { value: '', label: '연결 상태 전체' },
  { value: 'ON', label: '성공' },
  { value: 'WARNING', label: '경고' },
  { value: 'OFF', label: '실패' },
];

export const SCREEN_TYPE_FILTERS: SimpleSelectOption[] = [
  { value: 'VERTICAL', label: '세로' },
  { value: 'HORIZONTAL', label: '가로' },
];

export const WORKSPACE_TYPE_FILTERS = [
  { id: 'RETAIL', name: 'Retail' },
  { id: 'EXHIBITION', name: 'Exhibition' },
  { id: 'OFFICE', name: 'Office' },
  { id: 'ETC', name: 'Etc' },
];

export const WORKSPACE_USE_TYPE_FILTERS = [
  { id: 'DRAFT', name: 'Draft' },
  { id: 'SERVICE', name: 'Service' },
  { id: 'ARCHIVE', name: 'Archive' },
  { id: 'TEST', name: 'Test' },
];
