import MapDraw from '../mapDraw';

type Position = {
  x: number;
  y: number;
};

/**
 * 카메라 포커스 이동
 */
export const changeCameraFocus = (position: Position) => {
  if (MapDraw.map) {
    MapDraw.map.control.moveTo({
      transition: true,
      position: { x: convertPosition(position.x), y: convertPosition(position.y) },
    });
  }
};

const convertPosition = (value: number) => {
  return Number.isNaN(value) ? 0 : value;
};

/**
 * 방향각 회전
 */
export const changeRotation = (rotation: number) => {
  if (MapDraw.map) {
    MapDraw.map.control.set({
      rotation,
      transition: true,
    });
  }
};

/**
 * 줌 변경
 */
export const changeMapZoom = (zoomLevel: number) => {
  if (MapDraw.map) {
    MapDraw.map.control.changeZoom({
      zoom: Number(zoomLevel),
      transition: true,
    });
  }
};
