import MapDraw from '../mapDraw';

type Position = {
  x: number;
  y: number;
};

// 마커 제거
export const clearMarkers = () => {
  if (MapDraw.map) {
    MapDraw.map.markers.clearAll();
  }
};

/**
 * 마커 그리기
 */
export const drawKioskMarker = async (position: Position): Promise<void> => {
  if (MapDraw.map) {
    clearMarkers();

    await MapDraw.map.markers.set({
      marker: [
        {
          x: position.x,
          y: position.y,
          iconOption: {
            anchor: {
              x: 0.5,
              y: 0.5,
            },
            width: 40,
            height: 40,
            iconUrl: '/assets/image/kiosk.png',
          },
        },
      ],
    });
  }
};
