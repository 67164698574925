import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { changeRotation } from 'utils/map/control/camera';
import { ControllerProps } from './controller.type';
import { ChangeEvent } from 'react';
import { MachineLocationForm } from 'types/feature/machine/machine';

const RotationController = (props: ControllerProps) => {
  const handleChangeRotationMap = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    changeRotation(Number(value));
    props.setMachineLocationForm((prev: MachineLocationForm) => ({
      ...prev,
      mapPosition: {
        ...prev.mapPosition,
        rotation: value,
      },
    }));
  };
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='방향각'
        control={
          <Input
            placeholder='방향각'
            type='number'
            value={props.machineLocationForm.mapPosition.rotation}
            onChange={handleChangeRotationMap}
          />
        }
      />
    </div>
  );
};

export default RotationController;
