import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import AlertModal from 'components/common/modal/alert/AlertModal';
import Title, { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handle400Error } from 'utils/error/handle400Error';
import { changeCameraFocus } from 'utils/map/control/camera';
import { ControllerProps } from './controller.type';

// center
const CenterPositionEditController = (props: ControllerProps) => {
  // hook
  const { machineId } = useParams();
  const { patchMachine } = useMachine();
  // state
  const [editMode, setEditMode] = useState(false);
  const [position, setPosition] = useState<{ centerPositionX: string; centerPositionY: string }>({
    centerPositionX: props.machine.mapPosition.centerPositionX,
    centerPositionY: props.machine.mapPosition.centerPositionY,
  });
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setPosition((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const updateCenter = async () => {
    try {
      if (machineId) {
        if (isEmpty(position.centerPositionX) || isEmpty(position.centerPositionY)) {
          setOpenAlertPopup(true);
          setAlertMessage('입력된 값이 없습니다.');
          return;
        }
        await patchMachine(machineId, {
          mapPosition: {
            centerPositionX: position.centerPositionX,
            centerPositionY: position.centerPositionY,
          },
        });
        await props.fetchMachine(machineId);
        setEditMode(false);
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };

  useEffect(() => {
    if (!props.mapLoading) {
      changeCameraFocus({
        x: Number(position.centerPositionX),
        y: Number(position.centerPositionY),
      });
    }
  }, [position.centerPositionX, position.centerPositionY, props.machine, props.mapLoading]);

  const onClickCancel = () => {
    setEditMode(false);

    const position = {
      centerPositionX: props.machine.mapPosition.centerPositionX,
      centerPositionY: props.machine.mapPosition.centerPositionY,
    };
    setPosition(position);
    changeCameraFocus({
      x: Number(position.centerPositionX),
      y: Number(position.centerPositionY),
    });
  };

  return (
    <div className='flex flex-col gap-2.5 p-5 border-b'>
      <div className='flex justify-between h-6'>
        <Title text='지도 센터 좌표' textType={TextType.h4_bold} color='text-gray-77' />
        {editMode ? (
          <div className='flex gap-2.5'>
            <div className='h-6' onClick={updateCenter}>
              <img className='h-full' src={ICON.SUCCESS_BUTTON} alt='success' />
            </div>
            <div className='h-6' onClick={onClickCancel}>
              <img className='h-full' src={ICON.CANCEL_BUTTON} alt='cancel' />
            </div>
          </div>
        ) : (
          <div className='h-6' onClick={() => setEditMode(!editMode)}>
            <img className='h-full' src={ICON.PENCIL_BUTTON} alt='edit' />
          </div>
        )}
      </div>
      <div className='flex gap-5'>
        <FormControl
          labelSize={50}
          name='X'
          control={
            editMode ? (
              <Input
                size={60}
                textStyle={TextType.h5}
                value={position.centerPositionX}
                name='centerPositionX'
                onChange={onChange}
              />
            ) : (
              <div className='flex items-center bg-bg-f9 w-[60px] h-[30px] px-2 text-h5 text-gray-99'>
                {props.machine.mapPosition.centerPositionX}
              </div>
            )
          }
        />
        <FormControl
          labelSize={50}
          name='Y'
          control={
            editMode ? (
              <Input
                size={60}
                textStyle={TextType.h5}
                value={position.centerPositionY}
                name='centerPositionY'
                onChange={onChange}
              />
            ) : (
              <div className='flex items-center bg-bg-f9 w-[60px] h-[30px] px-2 text-h5 text-gray-99 '>
                {props.machine.mapPosition.centerPositionY}
              </div>
            )
          }
        />
      </div>
      {openAlertPopup && <AlertModal message={alertMessage} closeModal={() => setOpenAlertPopup(false)} />}
    </div>
  );
};

export default CenterPositionEditController;
