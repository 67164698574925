import { IndexType } from 'types/common';
import { MultiLangs } from '../multi-lang/language';

export type CustomPageType = 'LIST' | 'CARD';

export type CustomPage = {
  id: string;
  type: CustomPageType;
  name: MultiLangs;
  active: boolean;
  mainMenuId: string;
};

export const CustomPageTypeKo: IndexType = {
  LIST: '목록형',
  CARD: '카드형',
} as const;
