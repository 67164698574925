import { TextType } from 'components/common/title/Title';
import { BadgeStyles } from './badge.type';

export type BadgeKey = keyof typeof BadgeStyles;

export type BadgeProps = {
  label: string;
  type: BadgeKey;
  cursorStyle?: 'pointer' | 'default';
  height?: string;
};

const Badge = ({ label, type, height, cursorStyle = 'default' }: BadgeProps) => {
  return (
    <div className={`flex h-full`}>
      <div
        className={`flex ${
          cursorStyle === 'pointer' ? 'cursor-pointer' : 'cursor-default'
        } items-center justify-center font-medium text-center px-5 rounded-full ${BadgeStyles[type]} ${
          height || 'h-[32px]'
        } ${TextType.h5} m-auto min-w-[60px]`}
      >
        {label}
      </div>
    </div>
  );
};

export default Badge;
