import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import useAccountInfoStore from 'stores/accountInfo';
import { ApiResponse } from 'types/api/response';
import { AccountInfo } from 'types/common/accountInfo';

const useAccountInfo = () => {
  const { api } = useApi();

  const accountInfoStore = useAccountInfoStore();

  const getAccountInfo = async () => {
    const response = await api.get<ApiResponse<AccountInfo>>(Endpoint.accountInfo);

    if (response) {
      accountInfoStore.setAccountInfo(response.data);
      return response.data;
    }
  };

  return {
    getAccountInfo,
  };
};

export default useAccountInfo;
