import PointBasicSetting from './basic/PointBasicSetting';
import PointOperationSetting from './operation/PointOperationSetting';
import PointMapPositionSetting from './map/PointMapPositionSetting';
import { useParams } from 'react-router-dom';
import usePoint from 'hooks/feature/point/usePoint';
import { useEffect, useState } from 'react';
import { Point } from 'types/feature/workspace/workspace';
import MenuSetting from './menu/MenuSetting';
import usePointStore from 'stores/point';
import useAccountInfoStore from 'stores/accountInfo';

const PointSettingContainer = () => {
  // state
  const [point, setPoint] = useState<Point | null>(null);

  // hook
  const { pointId } = useParams();
  const { getPoint } = usePoint();

  // store
  const pointStore = usePointStore();
  const accountInfoStore = useAccountInfoStore();

  /**
   * 포인트 조회
   * @param {string} pointId
   */
  const fetchPoint = async (id: string) => {
    const response = await getPoint(id);
    if (response) {
      pointStore.setCurrentPoint(response);
      setPoint(response);
    }
  };

  useEffect(() => {
    if (pointId) {
      fetchPoint(pointId);
    }
  }, [pointId]);

  if (!pointId || !point) {
    return <> </>;
  }

  return (
    <div className='w-full flex flex-col'>
      <div
        className='fixed top-[80px] z-49 w-[1200px] h-[60px] flex justify-start items-center pl-10  border-l border-l-gray-ea'
        style={{
          background: 'linear-gradient(to right, #fff, #F9F9F9)',
        }}
      >
        <div
          className={`text-h1 font-h1 leading-h1 whitespace-nowrap cursor-default`}
          style={{ textDecoration: 'underline', textUnderlineOffset: '10px' }}
        >
          {point.name.ko}
        </div>
      </div>

      <div className='flex flex-col justify-start w-full h-full gap-12'>
        {/* 기본 정보 */}
        <PointBasicSetting pointId={pointId} point={point} fetchPoint={fetchPoint} />
        {/* 운영시간 및 휴무일 */}
        <PointOperationSetting pointId={pointId} point={point} fetchPoint={fetchPoint} />
        {/* 메뉴관리 */}
        {accountInfoStore.accountInfo?.admin && <MenuSetting pointId={pointId} point={point} fetchPoint={fetchPoint} />}
        {/* 지도 기본 설정  */}
        {accountInfoStore.accountInfo?.admin && (
          <PointMapPositionSetting pointId={pointId} point={point} fetchPoint={fetchPoint} />
        )}
      </div>
    </div>
    // </div>
  );
};

export default PointSettingContainer;
