import { GlobalMenu } from 'types/common/globalMenu';
import { RoutePath } from 'types/common/paths';

export const GLOBAL_MENUS: GlobalMenu[] = [
  { id: '대시보드', path: RoutePath.dashboard },
  // { id: '기본정보 관리', path: RoutePath.basicSetting },
  {
    id: '스크린세이버',
    path: RoutePath.screensaver,
    subMenus: [
      { id: '스케줄 관리', path: RoutePath.schedule },
      {
        id: '콘텐츠 관리',
        path: RoutePath.content,
      },
    ],
  },
  {
    id: 'POI',
    path: RoutePath.tenantInfo,
    // path: RoutePath.tenantManage,
    // subMenus: [
    //   { id: '매장정보 관리', path: RoutePath.tenantInfo },
    //   { id: '변경정보 관리', path: RoutePath.tenantChangedInfo },
    // ],
  },
  {
    id: '사용자 메뉴',
    path: RoutePath.customPages,
  },
  {
    id: '기기 관리',
    path: RoutePath.machines,
  },
  {
    id: '통계',
    path: RoutePath.stats,
    subMenus: [
      { id: '전체 사용량', path: RoutePath.totalUsageStats },
      { id: '기기별 사용량', path: RoutePath.machineUsageStats },
      { id: '인기 매장 순위', path: RoutePath.tenantUsageStats },
      { id: '인기 카테고리', path: RoutePath.categoryUsageStats },
    ],
  },
];
