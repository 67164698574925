import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import Title from 'components/common/title/Title';
import AlertPopup from 'components/feature/workspace/popup/AlertPopup';
import { MENU_SETTING_GUIDE } from 'data/guide/setting';
import useMenu from 'hooks/feature/menu/useMenu';
import React, { useEffect, useState } from 'react';
import { TextType } from 'types/common/text';
import { MainMenu, MainMenuIcon, PostMainMenu } from 'types/feature/menu/menu';
import { Point } from 'types/feature/workspace/workspace';
import MenuList from './list/MenuList';
import CreateMenuPopup from 'components/feature/menu/popup/CreateMenuPopup';

type Props = {
  pointId: string;
  point: Point | null;
  fetchPoint: (id: string) => void;
};

const MenuSetting = ({ pointId, point, fetchPoint }: Props) => {
  const [openMenuPopup, setOpenMenuPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [mainMenus, setMainMenuList] = useState<MainMenu[]>([]);
  const [menuIcons, setMainMenuIconList] = useState<MainMenuIcon[]>([]);

  const { getMainMenus, getMainMenuIconTypes, postMainMenu } = useMenu();

  // 메뉴 생성 갯수 제한
  const MENU_MAX = 6;

  // 포인트 목록 조회
  const fetchPoints = async () => {
    try {
      if (pointId) {
        // 메인 메뉴 목록 조회
        await fetchMainMenus(pointId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 메뉴 생성 버튼 클릭 시
  const onClickAddMenu = () => {
    setOpenMenuPopup(true);
  };

  // 메뉴 등록
  const createMainMenu = async (pointId: string, menu: PostMainMenu) => {
    try {
      await postMainMenu(pointId, menu);
      await fetchMainMenus(pointId);
      setOpenMenuPopup(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setAlertMessage('메뉴를 추가하는 중에 문제가 발생하였습니다. \n다시 시도해주세요.');
          setOpenAlertPopup(true);
        }
      }
    }
  };

  // 메인 메뉴 목록 조회
  const fetchMainMenus = async (pointId: string) => {
    try {
      const result = await getMainMenus(pointId || '');
      setMainMenuList(result.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setAlertMessage('메뉴를 조회하는 중에 문제가 발생하였습니다. \n다시 시도해주세요.');
          setOpenAlertPopup(true);
        }
      }
    }
  };

  // 메인 메뉴 아이콘 목록 조회
  const fetchMainMenuIcons = async () => {
    const mainMenuIcons = await getMainMenuIconTypes();

    if (mainMenuIcons) {
      setMainMenuIconList(mainMenuIcons);
    }
  };

  useEffect(() => {
    fetchPoints();
    fetchMainMenuIcons();
  }, [pointId]);

  //   useEffect(() => {
  //     if (workspaceId) {
  //       fetchPoints();
  //     }
  //   }, [workspaceId]);

  if (!point) {
    return <></>;
  }

  return (
    <section className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
      <div className='flex flex-col gap-2.5'>
        <div className='flex items-center justify-start h-9'>
          <Title text='메뉴관리' textType={TextType.h2} />
        </div>
      </div>
      <div className='flex flex-col p-10 bg-white gap-9 '>
        <div className='flex flex-col gap-8'>
          <div className='flex items-center justify-between w-full'>
            <GuideDescription guides={MENU_SETTING_GUIDE} />

            {/* 메뉴는 6개까지 생성 가능 */}
            {mainMenus.length < MENU_MAX && (
              <Button text='메뉴 생성' color={ButtonColor.secondary} onClick={onClickAddMenu} />
            )}
          </div>

          {/* 메뉴 리스트 */}
          <MenuList menuIcons={menuIcons} mainMenus={mainMenus} fetchMainMenus={fetchMainMenus} />

          {/* 메뉴 등록 팝업 */}
          {openMenuPopup && (
            <CreateMenuPopup
              createMainMenu={createMainMenu}
              menuIcons={menuIcons}
              closePopup={() => setOpenMenuPopup(false)}
              mainMenus={mainMenus}
            />
          )}

          {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
        </div>
      </div>
    </section>
  );
};

export default MenuSetting;
