import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';

import { convertCurrentDay } from 'utils/date/dateUtils';

type Props = {
  day: string;
  handleDeleteDay: (day: string) => void;
};

const SelectedDay = ({ day, handleDeleteDay }: Props) => {
  return (
    <div
      onClick={() => handleDeleteDay(day)}
      className='flex items-center gap-1 pl-3 pr-2 rounded-full bg-[#4F74F9] h-9 shrink-0 cursor-pointer'
    >
      <div className={`text-white ${TextType.h4}`}>{convertCurrentDay(day)}</div>
      <div>
        <img src={ICON.DELETE_WHITE} alt='delete-white' />
      </div>
    </div>
  );
};

export default SelectedDay;
