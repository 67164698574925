import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import AlertModal from 'components/common/modal/alert/AlertModal';
import Title, { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handle400Error } from 'utils/error/handle400Error';
import { drawKioskMarker } from 'utils/map/control/marker';
import { ControllerProps } from './controller.type';

const PositionEditController = (props: ControllerProps) => {
  // hook
  const { patchMachine } = useMachine();
  const { machineId } = useParams();
  // state
  const [editMode, setEditMode] = useState(false);
  const [position, setPosition] = useState<{ positionX: string; positionY: string }>({
    positionX: props.machine.position.x,
    positionY: props.machine.position.y,
  });
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  /**
   * 기기 위치 x 좌표를 업데이트 한다
   */
  const onChangeMachinePositionX = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPosition(prev => ({
      ...prev,
      positionX: value,
    }));
  };

  /**
   * 기기 위치 y 좌표를 업데이트 한다
   */
  const onChangeMachinePositionY = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPosition(prev => ({
      ...prev,
      positionY: value,
    }));
  };

  useEffect(() => {
    if (!props.mapLoading) {
      drawKioskMarker({
        x: Number(position.positionX),
        y: Number(position.positionY),
      });
    }
  }, [position.positionX, position.positionY, props.machine, props.mapLoading]);

  /**
   * 기기 위치 좌표를 업데이트 한다
   */
  const updatePosition = async () => {
    try {
      if (machineId) {
        if (isEmpty(position.positionX) || isEmpty(position.positionY)) {
          setOpenAlertPopup(true);
          setAlertMessage('입력된 값이 없습니다.');
          return;
        }

        await patchMachine(machineId, { ...position });
        await props.fetchMachine(machineId);
        setEditMode(false);
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };

  const onClickCancel = async () => {
    setEditMode(false);

    const position = {
      positionX: props.machine.position.x,
      positionY: props.machine.position.y,
    };
    setPosition(position);
    await drawKioskMarker({
      x: Number(position.positionX),
      y: Number(position.positionY),
    });
  };

  return (
    <div className='flex flex-col gap-2.5 p-5 border-b'>
      <div className='flex justify-between h-6'>
        <Title text='기기 위치 좌표' textType={TextType.h4_bold} color='text-gray-77' />
        {editMode ? (
          <div className='flex gap-2.5'>
            <div className='h-6' onClick={updatePosition}>
              <img className='h-full' src={ICON.SUCCESS_BUTTON} alt='edit' />
            </div>
            <div className='h-6' onClick={onClickCancel}>
              <img className='h-full' src={ICON.CANCEL_BUTTON} alt='edit' />
            </div>
          </div>
        ) : (
          <div className='h-6' onClick={() => setEditMode(!editMode)}>
            <img className='h-full' src={ICON.PENCIL_BUTTON} alt='edit' />
          </div>
        )}
      </div>
      <div className='flex gap-5 h-[30px]'>
        <FormControl
          labelSize={50}
          name='X'
          control={
            editMode ? (
              <Input
                size={60}
                textStyle={TextType.h5}
                value={position.positionX}
                name='positionX'
                onChange={onChangeMachinePositionX}
              />
            ) : (
              <div className='flex items-center bg-bg-f9 w-[60px] h-[30px] px-2 text-h5 text-gray-99 '>
                {position?.positionX}
              </div>
            )
          }
        />
        <FormControl
          labelSize={50}
          name='Y'
          control={
            editMode ? (
              <Input
                size={60}
                textStyle={TextType.h5}
                value={position.positionY}
                name='positionY'
                onChange={onChangeMachinePositionY}
              />
            ) : (
              <div className='flex items-center bg-bg-f9 w-[60px] h-[30px] px-2 text-h5 text-gray-99 '>
                {position?.positionY}
              </div>
            )
          }
        />
      </div>
      {openAlertPopup && <AlertModal message={alertMessage} closeModal={() => setOpenAlertPopup(false)} />}
    </div>
  );
};

export default PositionEditController;
