export type BadgeType = {
  [key: string]: string;
};

// export const Badges: BadgeType = {
//   PRIMARY: 'bg-state-blue-bg text-state-blue',
//   SECONDARY: 'bg-state-green_bg text-state-green',
//   WAIT: 'bg-state-yellow_bg text-state-yellow',
//   SUCCESS: 'bg-state-blue_bg text-state-blue',
//   DISABLED: 'bg-state-gray_bg text-state-gray',
//   CLOSE: 'bg-state-red_bg text-state-red',
//   DISPLAY: 'bg-state-blue_bg text-state-blue',
//   OPERATE: 'bg-[#e4fbe1] text-[#44DE54]',
//   DISCARD: 'bg-[#e4fbe1] text-[#44DE54]',
//   NOT_OPERATE: 'bg-[#FFF3D4] text-[#EEB41E]',
// } as const;

export enum BadgeStyles {
  NONE = 'bg-slate-200 text-gray-500',

  // machine status
  PREPARED = 'bg-[#FFF3D4] text-[#EEB41E]',
  RUNNING = 'bg-[#f2f5ff] text-[#7b8cc8]',
  EXPIRED = 'bg-[#f9f9f9] text-[#ccc]',

  // machine connect status
  ON = 'bg-[#e4fbe1] text-[#44DE54]',
  WARNING = 'bg-[#FFF3D4] text-[#EEB41E]',
  OFF = 'bg-state-red_bg text-state-red',

  USED = 'bg-[#f2f5ff] text-[#7b8cc8]',
}
