/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-constant-condition */
import useFloor from 'hooks/feature/floor/useFloor';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Floor } from 'types/feature/floor/floor';
import { MachineLocationForm } from 'types/feature/machine/machine';
import MapDraw from 'utils/map/mapDraw';
import KioskScreenTypeGuide from '../common/KioskScreenTypeGuide';
import MapCenterSign from '../common/MapCenterSign';
import MapLoading from '../common/MapLoading';
import { Point } from 'types/feature/workspace/workspace';
import { drawKioskMarker } from 'utils/map/control/marker';

type Props = {
  machineLocationForm: MachineLocationForm;
  point: Point;
};

const MachineAddMap = ({ machineLocationForm, point }: Props) => {
  // ref
  const mapRef = useRef<HTMLDivElement>(null);
  // hook
  const { workspaceId } = useParams();
  const location = useLocation();
  const { getWorkspace } = useWorkspace();
  const { getFloors } = useFloor();
  // var
  const mapInstance = new MapDraw();
  // state
  const [mapLoading, setMapLoading] = useState(true);

  /**
   * 층 목록을 조회한다.
   * @param workspacePointId 포인트 아이디
   * @returns {Promise<Floor[]>} 층 목록
   */
  const fetchFloors = async (workspacePointId: string): Promise<Floor[]> => {
    const pointId = location.state?.point ? location.state?.point.id : workspacePointId;
    const floors = await getFloors(pointId || '');
    return floors;
  };

  /**
   * 지도를 붙인다.
   * @param {Floor[]} floors 층 목록
   */
  const appendMap = async (floors: Floor[]) => {
    if (!mapRef.current) return;
    // floorId가 있다면 그 층을 그린다
    if (location.state?.floorId) {
      const findFloor = floors.find(floor => floor.id === location.state.floorId);
      if (findFloor) {
        await mapInstance.showMachineMap(
          mapRef.current,
          findFloor.id,
          machineLocationForm,
          point.mapMinZoom,
          point.mapMaxZoom,
        );
      }
    }
    // floorId가 없다면 메인층을 그린다
    else {
      const findMainFloor = floors.find(floor => floor.main);

      if (findMainFloor) {
        await mapInstance.showMachineMap(
          mapRef.current,
          findMainFloor.id,
          machineLocationForm,
          point.mapMinZoom,
          point.mapMaxZoom,
        );
      }
    }

    await drawKioskMarker({
      x: 0,
      y: 0,
    });
  };

  /**
   * 워크스페이스와 지도 데이터를 조회한다.
   * @param {string} workspaceId 워크스페이스 아이디
   */
  const fetchWorkspaceAndMapData = async (workspaceId: string) => {
    try {
      if (!mapRef.current) return;
      const workspace = await getWorkspace(workspaceId);
      if (workspace) {
        const mapData = await MapDraw.getInstance().getMapData({
          clientId: workspace.map.temp1,
          clientSecret: workspace.map.temp2,
          version: workspace.map.version,
          serverType: 'SERVER_STUDIO4',
        });

        if (mapData) {
          MapDraw.mapData = mapData;

          // 층 목록 조회
          const floorList = await fetchFloors(workspace.points[0].id);
          // 지도 append
          await appendMap(floorList);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setMapLoading(false);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      if (mapRef.current) {
        fetchWorkspaceAndMapData(workspaceId);
      }
    }

    /**
     * cleanup
     */
    return () => {
      if (workspaceId) {
        if (mapRef.current) {
          MapDraw.cleanup();
        }
      }
    };
  }, [workspaceId]);

  useEffect(() => {
    return () => {
      setMapLoading(true);
    };
  }, []);

  return (
    <div className='w-[900px] h-[666px] shrink-0 relative overflow-hidden'>
      {mapLoading && <MapLoading />}

      <>
        <MapCenterSign />
        <KioskScreenTypeGuide screenType={machineLocationForm.screenType} />

        {/* 지도 Viewer */}
        <div ref={mapRef} className='w-full h-full' />
      </>
    </div>
  );
};

export default MachineAddMap;
