import Input from 'components/common/input/Input';
import { MenuTitleWordLimit } from 'data/length';
import useLanguage from 'hooks/feature/language/useLanguage';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Language, LanguageName, MultiLangs } from 'types/feature/multi-lang/language';

type Props = {
  menuName?: MultiLangs;
  handleChangeMenuName: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const MenuMultiLangInputs = ({ menuName, handleChangeMenuName }: Props) => {
  const { workspaceId } = useParams();
  const { getLanguages } = useLanguage();
  const [languages, setLanguages] = useState<Language[]>([]);

  const getLanguageList = async () => {
    if (workspaceId) {
      const result = await getLanguages();
      if (result) {
        const displayLanguages: Language[] = result;
        setLanguages(displayLanguages);
      }
    }
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  return (
    <div className='flex flex-col w-full gap-2.5'>
      {languages.map(language => {
        const multiLangLimit = MenuTitleWordLimit[language.code.toLowerCase()];
        return (
          <div key={language.id} className='flex items-center'>
            {/* 언어 */}
            <div className='text-sm text-gray-44 whitespace-nowrap w-[120px]'>{LanguageName[language.code]}</div>

            <Input
              // width='w-full'
              // height='h-[30px]'
              // maxLength={multiLangLimit}
              onChange={e => {
                if (e.target.value.length > multiLangLimit) {
                  return;
                }
                handleChangeMenuName(e);
              }}
              defaultValue={menuName && menuName[language.code.toLowerCase()]}
              placeholder={`${LanguageName[language.code]} 메뉴 이름을 입력해주세요.`}
              id={language.code.toLowerCase()}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MenuMultiLangInputs;
