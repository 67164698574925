import { ICON } from 'constants/icons';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { TextType } from '../title/Title';

export type SimpleSelectOption = {
  label: string;
  value: string;
  data?: any;
};

const SelectBoxWidth = {
  80: 'w-[80px]',
  100: 'w-[100px]',
  120: 'w-[120px]',
  160: 'w-[160px]',
  200: 'w-[200px]',
  240: 'w-[240px]',
  276: 'w-[276px]',
  384: 'w-[384px]',
  full: 'w-full',
};

type Props = {
  options: SimpleSelectOption[];
  onChange: ((value: string) => void) | ((value: string) => Promise<void>);
  width?: keyof typeof SelectBoxWidth;
  height?: string;
  selected: string;
  disabled?: boolean;
  textType?: TextType;
  direction?: 'UP' | 'DOWN';
  name?: string;
};

const SimpleSelectBox = ({
  selected,
  disabled,
  options,
  onChange,
  width = 120,
  height = 'h-9',
  textType = TextType.h4,
  direction = 'DOWN',
  name,
}: Props) => {
  const [openSelectBox, setOpenSelectBox] = useState(false);

  const toggleSelectBox = () => {
    if (disabled) {
      return;
    }

    setOpenSelectBox(!openSelectBox);
  };

  const handleClickOption = (option: SimpleSelectOption) => {
    toggleSelectBox();
    onChange(option.value);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenSelectBox(false)}>
      <div id={name} className='relative'>
        {/* 선택된 옵션 */}
        <button
          disabled={disabled}
          className={`${
            disabled
              ? 'bg-bg-f9 outline-gray-cc opacity-50'
              : 'bg-white cursor-pointer outline-gray-99 hover:outline-gray-44'
          } ${SelectBoxWidth[width]} ${height} outline outline-1 flex items-center justify-between px-2`}
          onClick={toggleSelectBox}
        >
          <div className={`${textType} ${disabled ? 'text-gray-cc' : 'text-gray-44'} flex items-center gap-1`}>
            <span>{options.find(option => option.value === selected)?.label || ''}</span>
          </div>

          <div>
            {disabled ? (
              <img src={ICON.ARROW} alt='disable-arrow' />
            ) : (
              <img src={openSelectBox ? ICON.ARROW_UP_ACTIVE : ICON.ARROW_DOWN_ACTIVE} alt='arrow' />
            )}
          </div>
        </button>

        {openSelectBox && (
          <ul
            className={`z-30 list-none cursor-pointer absolute 
              ${direction === 'UP' ? 'bottom-0' : 'left-0 top-0'} 
              outline outline-1 outline-gray-99 p-0 bg-white shadow-mdDrop 
              max-h-[144px] overflow-y-auto
            ${SelectBoxWidth[width]} 
            `}
          >
            {options.map(option => (
              <li
                key={option.value}
                id={option.value}
                className={`flex items-center px-2 ${height} hover:bg-state-blue_bg`}
                onClick={handleClickOption.bind(this, option)}
                value={option.data}
              >
                <div className='flex items-center gap-1'>
                  <span
                    className={`${
                      option.value === selected ? `${textType} !font-bold text-primary-DA_Blue_light` : textType
                    } `}
                  >
                    {option.label}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default SimpleSelectBox;
