import Input from 'components/common/input/Input';
import Title, { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handle400Error } from 'utils/error/handle400Error';
import { ControllerProps } from './controller.type';
import AlertModal from 'components/common/modal/alert/AlertModal';

const DescriptionEditController = (props: ControllerProps) => {
  // hook
  const { patchMachine } = useMachine();
  const { machineId } = useParams();
  // state
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState<string>(props.machine.description || '');
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDescription(value);
  };

  const updateDescription = async () => {
    try {
      if (machineId) {
        if (isEmpty(description)) {
          setOpenAlertPopup(true);
          setAlertMessage('입력된 값이 없습니다.');
          return;
        }

        await patchMachine(machineId, {
          description,
        });
        await props.fetchMachine(machineId);
        setEditMode(false);
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };

  const onClickCancel = () => {
    setEditMode(false);
    setDescription(props.machine.description);
  };

  return (
    <>
      <div className='flex flex-col gap-2.5 p-5 border-b'>
        <div className='flex justify-between h-6'>
          <Title text='상세 위치' textType={TextType.h4_bold} color='text-gray-77' />
          {editMode ? (
            <div className='flex gap-2.5'>
              <div className='h-6' onClick={updateDescription}>
                <img className='h-full' src={ICON.SUCCESS_BUTTON} alt='edit' />
              </div>
              <div className='h-6' onClick={onClickCancel}>
                <img className='h-full' src={ICON.CANCEL_BUTTON} alt='edit' />
              </div>
            </div>
          ) : (
            <div className='h-6' onClick={() => setEditMode(!editMode)}>
              <img className='h-full' src={ICON.PENCIL_BUTTON} alt='edit' />
            </div>
          )}
        </div>
        {editMode ? (
          <Input
            inputStyle='h-[30px]'
            textStyle={TextType.h5}
            placeholder='상세위치를 입력해주세요'
            value={description || ''}
            onChange={onChange}
          />
        ) : (
          <div className='px-2 h-[30px] flex items-center bg-bg-f9 w-full text-h5 text-gray-99'>
            {props.machine.description}
          </div>
        )}
      </div>
      {openAlertPopup && <AlertModal message={alertMessage} closeModal={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default DescriptionEditController;
