/* eslint-disable no-else-return */

import Title from 'components/common/title/Title';
import { Workspace } from 'types/feature/workspace/workspace';
import MobileUrl from './MobileUrl';
import ToggleMobileUsed from './ToggleMobileUsed';
import ToggleWebUsed from './ToggleWebUsed';
import UrlBorder from './UrlBorder';
import UrlIdInput from './UrlIdInput';
import WebUrl from './WebUrl';
import FormControl from 'components/common/form-control/FormControl';
import { TextType } from 'types/common/text';
import useAccountInfoStore from 'stores/accountInfo';

type Props = {
  workspace: Workspace;
  fetchWorkspace: () => Promise<void>;
};

const UrlSetting = ({ workspace, fetchWorkspace }: Props) => {
  // store
  const accountInfoStore = useAccountInfoStore();

  return (
    <section className='flex flex-col gap-10 p-10 bg-white border border-gray-ea'>
      <div className='flex flex-col w-full gap-5'>
        <div className='w-[160px] min-h-9 h-auto flex items-center'>
          <Title text='URL 설정' textType={TextType.h2} />
        </div>

        <div className='flex flex-col w-full gap-5'>
          <section className='flex h-auto'>
            <div className='w-[160px] min-h-9 h-auto flex items-center'>
              <Title text='URL ID' textType={TextType.h4} />
            </div>

            <FormControl
              name=''
              labelSize={120}
              control={<UrlIdInput fetchWorkspace={fetchWorkspace} workspace={workspace} />}
            />
          </section>

          <div className='flex flex-col gap-4'>
            <UrlBorder />

            {/* 웹 URL */}
            <section className='flex h-auto'>
              <div className='w-[160px] min-h-9 h-auto flex items-center'>
                <Title text='웹 키오스크' textType={TextType.h4} />
              </div>

              <div className='w-[600px] flex flex-col gap-5'>
                <FormControl
                  labelSize={100}
                  name='사용 유무'
                  control={
                    accountInfoStore.accountInfo?.admin ? (
                      <ToggleWebUsed workspace={workspace} fetchWorkspace={fetchWorkspace} />
                    ) : (
                      <ToggleWebUsed workspace={workspace} fetchWorkspace={fetchWorkspace} readOnly />
                    )
                  }
                />

                {/* 웹 URL */}
                {workspace.webUsed && <WebUrl workspace={workspace} />}
              </div>
            </section>

            {/* border */}
            <UrlBorder />

            {/* 모바일 URL */}
            <section className='flex h-auto'>
              <div className='w-[160px] min-h-9 h-auto flex items-center'>
                <Title text='모바일 키오스크' textType={TextType.h4} />
              </div>

              <div className='w-[600px] flex flex-col gap-5'>
                <FormControl
                  labelSize={100}
                  name='사용 유무'
                  control={
                    accountInfoStore.accountInfo?.admin ? (
                      <ToggleMobileUsed workspace={workspace} fetchWorkspace={fetchWorkspace} />
                    ) : (
                      <ToggleMobileUsed workspace={workspace} fetchWorkspace={fetchWorkspace} readOnly />
                    )
                  }
                />

                {/* 모바일 URL */}
                {workspace.mobileUsed && <MobileUrl workspace={workspace} />}
              </div>
            </section>

            {/* border */}
            <UrlBorder />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UrlSetting;
