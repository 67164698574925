import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import LoadingSpinner from 'components/common/loading/spinner/LoadingSpinner';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import SimpleSelectBox from 'components/common/select/SimpleSelectBox';
import { TextType } from 'components/common/title/Title';
import { MACHINE_PLACE_TYPE_FILTERS, MACHINE_STATE_TYPE_FILTERS } from 'constants/filters';
import { ICON } from 'constants/icons';
import useFloor from 'hooks/feature/floor/useFloor';
import useMachines from 'hooks/feature/machine/useMachine';
import useMachineSocket from 'hooks/feature/socket/useMachineSocket';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Floor } from 'types/feature/floor/floor';
import { Machine, MachinePlaceType, MachineStatus } from 'types/feature/machine/machine';
import { Point, Workspace } from 'types/feature/workspace/workspace';
import { replaceMachineId, replaceWorkspaceId } from 'utils/common/replaceId';
import MachineList from './list/MachineList';
import useAccountInfoStore from 'stores/accountInfo';

const MachineManageContainer = () => {
  // hook
  const { getWorkspace } = useWorkspace();
  const { getMachines, downloadMachinesCsv } = useMachines();
  const { getFloors } = useFloor();
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // state
  const [points, setPoints] = useState<Point[]>([]);
  const [floors, setFloors] = useState<Floor[]>([]);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPoint, setSelectedPoint] = useState<Point>();
  const [selectedFloor, setSelectedFloor] = useState<Floor | null>(null);
  const [selectedPlaceType, setSelectedPlaceType] = useState<MachinePlaceType>(location.state?.placeType || '');
  const [selectedStatus, setSelectedStatus] = useState<MachineStatus>(location.state?.status || '');
  // store
  const accountInfoStore = useAccountInfoStore();

  // 워크스페이스 조회
  const fetchWorkspace = async (workspaceId: string) => {
    const workspace = await getWorkspace(workspaceId);
    return workspace;
  };

  //  포인트 조회
  const findPoint = (workspace: Workspace) => {
    setPoints(workspace.points);
    const point = location.state?.point || workspace.points[0];
    setSelectedPoint(point);
    return point;
  };

  // 층 목록 조회
  const fetchFloors = async (pointId: string) => {
    const floors = await getFloors(pointId);
    if (floors) {
      setFloors(floors);
      return floors;
    }
  };

  const initData = async (workspaceId: string) => {
    setLoading(true);

    const workspace = await fetchWorkspace(workspaceId);
    if (workspace) {
      const point = findPoint(workspace);
      await fetchFloors(point.id);
      await fetchMachines(
        point.id, //
        selectedPlaceType,
        selectedStatus,
        selectedFloor?.id || '',
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    if (workspaceId) {
      initData(workspaceId);
    }
  }, [workspaceId]);

  /**
   * 기기 목록을 조회한다
   * @param pointId
   */
  const fetchMachines = async (
    pointId: string,
    placeType: MachinePlaceType,
    status: MachineStatus,
    floorId: string,
  ) => {
    const machines = await getMachines(
      pointId, //
      placeType,
      status,
      floorId,
    );
    setMachines(machines);
  };

  /**
   * 포인트를 변경하면 기기 목록을 다시 조회한다
   */
  const onChangePoint = async (option: CommonOptionType) => {
    const point = points.find(point => point.id === option.value);
    if (point) {
      setSelectedPoint(point);
      setSelectedFloor(null);
      await fetchFloors(point.id);
      await fetchMachines(
        point.id, //
        selectedPlaceType,
        selectedStatus,
        '',
      );
    }
  };

  /**
   * 구분 필터를 변경한다
   */
  const onChangePlaceType = async (placeType: string) => {
    setSelectedPlaceType(placeType as MachinePlaceType);
    await fetchMachines(
      selectedPoint?.id || '',
      placeType as MachinePlaceType,
      selectedStatus,
      selectedFloor?.id || '',
    );
  };

  /**
   * 상태 필터를 변경한다
   */
  const onChangeStatus = async (status: string) => {
    setSelectedStatus(status as MachineStatus);
    await fetchMachines(
      selectedPoint?.id || '', //
      selectedPlaceType,
      status as MachineStatus,
      selectedFloor?.id || '',
    );
  };

  /**
   * 층을 변경한다
   * @param {string} floorId 층 아이디
   */
  const onChangeFloor = async (floorId: string) => {
    const floor = floors.find(floor => floor.id === floorId);
    if (floor) {
      setSelectedFloor(floor);
    }
    await fetchMachines(
      selectedPoint?.id || '', //
      selectedPlaceType,
      selectedStatus,
      floorId,
    );
  };

  /**
   * socket 연결 custom hook
   * socket 이 connect / close 되었을 때 목록을 다시 fetch 하도록 처리한다
   */
  const { updatedMachine } = useMachineSocket({
    callback: async () => {
      if (!selectedPoint) return;
      if (!selectedFloor) return;
      await fetchMachines(
        selectedPoint.id, //
        selectedPlaceType,
        selectedStatus,
        selectedFloor.id,
      );
    },
    pointId: selectedPoint?.id || '',
    floorId: selectedFloor?.id || '',
  });

  /**
   * csv 다운로드
   */
  const onClickDownload = async () => {
    await downloadMachinesCsv(
      selectedPoint?.id || '', //
      selectedPlaceType,
      selectedStatus,
      selectedFloor?.id || '',
    );
  };

  /**
   * socket 이 update message 를 주면 기기 목록을 다시 조회한다
   */
  useEffect(() => {
    if (updatedMachine.machineId && selectedPoint) {
      fetchMachines(
        selectedPoint.id, //
        selectedPlaceType,
        selectedStatus,
        selectedFloor?.id || '',
      );
    }
  }, [updatedMachine]);

  /**
   * 기기 등록 페이지로 이동한다
   */
  const moveToAddPage = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.machineAdd, workspaceId), {
        state: {
          point: selectedPoint,
          placeType: selectedPlaceType || '',
          status: selectedStatus || '',
          // floorId: selectedFloor?.id,
        },
      });
    }
  };

  /**
   * 기기 상세 페이지로 이동한다
   */
  const moveToDetailPage = (machineId: string) => {
    if (!accountInfoStore?.accountInfo?.admin) return;

    if (workspaceId) {
      navigate(replaceMachineId(replaceWorkspaceId(RoutePath.machineDetail, workspaceId), machineId), {
        state: {
          point: selectedPoint,
          placeType: selectedPlaceType || '',
          status: selectedStatus || '',
          // floorId: selectedFloor?.id,
        },
      });
    }
  };

  return (
    <section className='flex flex-col gap-6'>
      {loading ? (
        <LoadingSpinner position='relative' height='36px' />
      ) : (
        points && (
          <div className='flex justify-between w-full'>
            <div className='flex gap-2.5'>
              {/* 빌딩 */}
              <CommonSelectBox
                width={200}
                options={points.map(point => ({
                  label: point.name.ko,
                  value: point.id,
                }))}
                onChangeSelect={onChangePoint}
                selected={{
                  label: selectedPoint?.name.ko || '',
                  value: selectedPoint?.id || '',
                }}
              />

              {/* 층 */}
              <SimpleSelectBox
                selected={selectedFloor?.id || ''}
                options={[
                  {
                    value: '',
                    label: '전체',
                  },
                  ...floors.map(floor => ({
                    value: floor.id,
                    label: floor.mainName || floor.name.ko,
                  })),
                ]}
                onChange={onChangeFloor}
              />

              {/* 구분 */}
              <SimpleSelectBox
                selected={selectedPlaceType}
                onChange={onChangePlaceType}
                options={[...MACHINE_PLACE_TYPE_FILTERS]}
                width={160}
              />

              {/* 상태 */}
              <SimpleSelectBox
                selected={selectedStatus}
                onChange={onChangeStatus}
                options={[...MACHINE_STATE_TYPE_FILTERS]}
                width={160}
              />

              {/* CSV 다운로드 버튼 */}
              <Button
                onClick={onClickDownload}
                size='csv'
                textStyle={TextType.h5}
                color={ButtonColor.csv}
                text='CSV 다운로드'
                iconPath={ICON.DOWNLOAD}
              />
            </div>

            {/* 기기 등록 버튼 */}
            {accountInfoStore?.accountInfo?.admin && (
              <Button onClick={moveToAddPage} textStyle={TextType.h5} size={96} color={ButtonColor.primary}>
                <div className='flex items-center justify-center h-4 gap-1 pl-2 pr-3'>
                  <img src={ICON.PLUS_WHITE} alt='plus icon' />
                  <span className={`${TextType.h5} text-gray-ea whitespace-nowrap`}>기기 등록</span>
                </div>
              </Button>
            )}
          </div>
        )
      )}

      {/* 기기 목록 */}
      <MachineList machineList={machines} moveToDetailPage={moveToDetailPage} />
    </section>
  );
};

export default MachineManageContainer;
