import { MachineLocationForm } from 'types/feature/machine/machine';

declare global {
  interface Window {
    dabeeo: any;
  }
}

class MapDraw {
  static get mapContainer() {
    return this._mapContainer;
  }

  private static _mapContainer: HTMLDivElement;

  static dabeeoMaps: any = null;

  static map: any = null;

  static mapData: any = null;

  public static getInstance() {
    if (!this.dabeeoMaps) {
      this.dabeeoMaps = new window.dabeeo.Maps() as any;
    }

    return this.dabeeoMaps;
  }

  async showMap(mapContainer: HTMLDivElement) {
    const mapOption = {
      camera: '2d',
      // floor: floorId,
      language: 'ko',
      showPoi: true,
      spriteEnable: true,
      spriteKeepRotation: true,
      showWaterMarker: false,
      framerate: 30,

      enablePoiCollisionTest: false,
      dragDistance: 50,

      /**
       * georeferencing 하지 않은 지도와 동일하게 동작
       * https://api-sample.dabeeomaps.com/multiMap
       */
      enableGeoreferencing: false,
      /**
       * poi hover시 pointer가 손가락으로 바뀜
       * https://api-sample.dabeeomaps.com/examples/pois_event
       */
      enableMouseMoveEvent: true,

      backgroundImage: {
        visible: true,
      },
    };

    MapDraw._mapContainer = mapContainer;

    MapDraw.map = await MapDraw.getInstance().showMap(mapContainer, mapOption, MapDraw.mapData);

    return MapDraw.map;
  }

  /**
   * 기기 등록에서 사용하는 showMap
   */
  // TODO: add, edit 최적화
  async showMachineMap(
    mapContainer: HTMLDivElement,
    floorId: string,
    machineLocation: MachineLocationForm,
    minZoom: string,
    maxZoom: string,
  ) {
    const mapOption = {
      camera: '2d',
      floor: floorId,
      language: 'ko',
      showPoi: true,
      spriteEnable: true,
      spriteKeepRotation: true,
      showWaterMarker: false,
      framerate: 30,
      enableMouseMoveEvent: false,
      enableGeoreferencing: false,
      enablePoiCollisionTest: false,
      dragDistance: 50,
      backgroundImage: {
        visible: true,
      },
      controlOption: {
        rotate: Number(machineLocation.mapPosition.rotation),
        zoom: Number(machineLocation.mapPosition.zoom),
        tilt: 0,
        // 지도가 위치할 좌표
        pan: {
          x: Number(machineLocation.mapPosition.centerPositionX),
          y: Number(machineLocation.mapPosition.centerPositionY),
        },
      },
    };

    MapDraw._mapContainer = mapContainer;

    MapDraw.map = await MapDraw.getInstance().showMap(mapContainer, mapOption, MapDraw.mapData);

    MapDraw.map?.control.setOption({
      controlRangeOption: {
        // 포인트의 최소/최대 줌레벨 적용
        zoom: {
          min: Number(minZoom),
          max: Number(maxZoom),
        },
      },
      mouseOption: {
        enableZoom: false,
        // enablePan: false,
        enableTilt: false,
      },
    });

    return MapDraw.map;
  }

  static cleanup() {
    if (MapDraw.map) {
      MapDraw.map.context.cleanup();
    }
  }
}

export default MapDraw;
