/* eslint-disable react/destructuring-assignment */
import Border from 'components/common/border/Border';
import CopyButton from 'components/common/copy/CopyButton';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useClipBoard from 'hooks/common/useClipBoard';
import OutsideClickHandler from 'react-outside-click-handler';
import { Poi } from 'types/feature/poi/poi';

type MobileUrl = {
  type: 'location' | 'parking';
  title: string;
  description: string;
  notice?: string;
  url: string;
};

type Props = {
  closePopup: () => void;
  poiContent: Poi;
  mobileEndpoint: string;
  parkingUsed: boolean;
  hasParking: boolean;
};

const UrlCopyPopup = (props: Props) => {
  const { copyUrlOnClipboard } = useClipBoard();

  const mobileDidBaseUrl = props.mobileEndpoint;

  const mobileUrls: MobileUrl[] = [
    {
      type: 'location',

      title: '매장 위치 확인',
      description: '층별안내 > 매장 위치 화면으로 이동',
      url: `${mobileDidBaseUrl}/?type=location&destTenantId=${props.poiContent.id}`,
    },
    {
      type: 'parking',
      title: '내 주차위치',
      description: '내 주차위치로 지정 팝업 노출',
      url: `${mobileDidBaseUrl}/?type=parkingPositioning&originTenantId=${props.poiContent.id}`,
    },
    {
      type: 'parking',
      title: '주차위치 길찾기',
      description: '내 주차위치로 길찾기 팝업 노출',
      notice: '지정된 내 주차위치 있는 경우에만 팝업 노출',
      url: `${mobileDidBaseUrl}/?type=parkingNavi&originTenantId=${props.poiContent.id}`,
    },
    {
      type: 'parking',
      title: '주차위치 확인',
      description: '내 주차위치 상세 정보 화면 노출',
      url: `${mobileDidBaseUrl}/?type=parkingDetail&destTenantId=${props.poiContent.id}`,
    },
  ];

  /**
   * 전달받은 url을 새창으로 연다
   * @param url mobile DID URL
   */
  // const openMobileUrl = (url: string) => {
  //   window.open(url);
  // };

  return (
    <section className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-30'>
      <OutsideClickHandler onOutsideClick={props.closePopup}>
        <div className='max-w-[600px] min-w-[450px] h-auto bg-white flex flex-col'>
          {/* header */}
          <div className='h-[60px] pl-10 pr-5 flex justify-between items-center border-b border-gray-44'>
            <div className={`${TextType.h3_bold} cursor-default`}>POI URL 복사</div>
            <button type='button' onClick={props.closePopup}>
              <img src={ICON.CLOSE} alt='닫기' />
            </button>
          </div>

          <div className='flex items-start gap-5 px-10 pt-6 pb-10'>
            {/* 매장 위치 확인 */}
            {mobileUrls
              .filter(url => url.type === 'location')
              .map(mobile => (
                <div key={mobile.title} className='flex flex-col items-start justify-between h-full gap-3'>
                  <div className='flex flex-col gap-[6px]'>
                    <div
                      onClick={() => {
                        copyUrlOnClipboard(mobile.url);
                      }}
                      className='flex items-center h-[18px] gap-2 cursor-pointer'
                    >
                      {/* 타이틀 */}
                      <div className={`${TextType.h4_bold} text-gray-44`}>{mobile.title}</div>
                      {/* URL 복사 */}
                      <CopyButton text={mobile.url} />
                      {/* 새 창 열기 */}
                      {/* <img src={ICON.NEW_WINDOW} alt='새 창 열기' /> */}
                    </div>

                    <div className='flex flex-col gap-[2px]'>
                      {/* 상세 설명 */}
                      <div className={`${TextType.body2} text-gray-44`}>{mobile.description}</div>
                    </div>
                    {mobile.notice && <div className={`${TextType.body3} text-gray-99`}>{mobile.notice}</div>}
                  </div>
                  {/* <div className='flex gap-2'>
                    <Button
                      onClick={() => {
                        downQrImage(`${props.tenant.name.ko}-${mobile.title}`, mobile.url);
                      }}
                      size='rounded'
                      color={ButtonColor.secondary}
                      textStyle={`${TextType.h5}`}
                    >
                      <div className='flex items-center justify-center'>
                        <img src={ICON.DOWNLOAD} alt='다운로드' />
                        <span>QR 다운로드</span>
                      </div>
                    </Button>
                  </div> */}
                </div>
              ))}
          </div>

          {props.parkingUsed && props.hasParking && (
            <>
              <Border />

              {/* 주차 관련 */}
              <div className='flex items-start gap-5 px-10 pt-6 pb-10'>
                {mobileUrls
                  .filter(url => url.type === 'parking')
                  .map(mobile => (
                    <div key={mobile.title} className='flex flex-col items-start justify-between h-full gap-3'>
                      <div className='flex flex-col gap-[6px]'>
                        <div
                          onClick={() => {
                            copyUrlOnClipboard(mobile.url);
                          }}
                          className='flex items-center h-[18px] gap-2 cursor-pointer'
                        >
                          {/* 타이틀 */}
                          <div className={`${TextType.h4_bold} text-gray-44`}>{mobile.title}</div>

                          {/* URL 복사 */}
                          <CopyButton text={mobile.url} />
                          {/* 새 창 열기 */}
                          {/* <img src={ICON.NEW_WINDOW} alt='새 창 열기' /> */}
                        </div>

                        <div className='flex flex-col gap-[2px]'>
                          {/* 상세 설명 */}
                          <div className={`${TextType.body2} text-gray-44`}>{mobile.description}</div>
                          {/* 안내 */}
                        </div>
                        {mobile.notice && <div className={`${TextType.body3} text-gray-99`}>{mobile.notice}</div>}
                      </div>
                      {/* <div className='flex gap-2'>
                            <Button
                              onClick={() => {
                                downQrImage(`${props.tenant.name.ko}-${mobile.title}`, mobile.url);
                              }}
                              size='rounded'
                              color={ButtonColor.secondary}
                              textStyle={`${TextType.h5}`}
                            >
                              <div className='flex items-center justify-center'>
                                <img src={ICON.DOWNLOAD} alt='다운로드' />
                                <span>QR 다운로드</span>
                              </div>
                            </Button>
                          </div> */}
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </OutsideClickHandler>
    </section>
  );
};
export default UrlCopyPopup;
