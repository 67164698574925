import { AxiosError } from 'axios';
import Badge from 'components/common/badge/Badge';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import Title, { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import dayjs from 'dayjs';
import useMachine from 'hooks/feature/machine/useMachine';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CONTENTS_BG } from 'styles/background';
import {
  Machine,
  MachineConnectStatusKo,
  MachinePlaceTypeKo,
  MachineStatusKo,
  MachineType,
} from 'types/feature/machine/machine';

type Props = {
  machineType: MachineType;
  machine: Machine;
  fetchMachine: (machineId: string) => Promise<Machine | undefined>;
};

const MachineDetailBasicInfo = ({ machineType, machine, fetchMachine }: Props) => {
  const { machineId } = useParams();
  const { discardMachine, resetMachine, initMachine } = useMachine();
  // state
  const [openDiscardConfirm, setOpenDiscardConfirm] = useState(false);
  const [openInitConfirm, setOpenInitConfirm] = useState(false);
  const [openResetConfirm, setOpenResetConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // var
  const isMachineRunning = machine.status === 'RUNNING';
  const isFileManagerOn = machine.connectStatus !== 'OFF';

  const handleAlert = (message: string) => {
    setOpenDiscardConfirm(false);
    setOpenInitConfirm(false);
    setOpenResetConfirm(false);
    setOpenAlert(true);
    setAlertMessage(message);
  };

  /**
   * 기기 폐기
   */
  const onClickDiscardMachine = async () => {
    try {
      if (machineId) {
        await discardMachine(machineId);
        await fetchMachine(machineId);
      }

      handleAlert('기기를 폐기했습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          handleAlert('기기 폐기에 실패했습니다.');
        }
      }
    }
  };

  /**
   * 기기 초기화
   */
  const onClickInit = async () => {
    try {
      if (machineId) {
        await initMachine(machineId);
      }

      handleAlert('기기를 초기화했습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          handleAlert('기기 초기화를 실패했습니다.');
        }
      }
    }
  };

  /**
   * 기기 재시작
   */
  const onClickReset = async () => {
    try {
      if (machineId) {
        await resetMachine(machineId);
      }

      handleAlert('기기를 재시작했습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          handleAlert('기기를 재시작을 실패했습니다.');
        }
      }
    }
  };

  return (
    <section className='flex flex-col w-full gap-10'>
      {/* 기본 정보 */}
      <div className={`${CONTENTS_BG} p-[40px] flex flex-col gap-6`}>
        <div className='flex justify-between'>
          <Title text='기본 정보' textType={TextType.h3_bold} />

          {/* 폐기 처리 버튼 */}
          {machine.status === 'RUNNING' && (
            <Button text='폐기처리' color={ButtonColor.delete} size={80} onClick={() => setOpenDiscardConfirm(true)} />
          )}
        </div>

        <section className='grid w-full grid-cols-2 grid-rows-3 gap-4'>
          {/* 구분 */}
          <FormControl
            name='구분'
            control={
              <div className={`${TextType.body1} flex justify-center`}>{MachinePlaceTypeKo[machine.placeType]}</div>
            }
          />
          {/* 최근 수정일 */}
          <FormControl
            name='최근 수정일'
            control={
              <div className={`${TextType.body1} flex justify-center`}>
                {dayjs(machine.updatedDate).format('YYYY.MM.DD')}
              </div>
            }
          />
          {/* 기기 코드 */}
          <FormControl
            name='기기 코드'
            control={<div className={`${TextType.body1} flex justify-center`}>{machine.idSuffix}</div>}
          />
          {/* 기기 아이디(웹형) / 숏코드(설치형) */}
          {machine.status !== 'EXPIRED' && (
            <FormControl
              name='아이디'
              control={
                <div className={`relative flex items-center justify-center`}>
                  <span className={`${TextType.body1}`}>
                    {machineType === 'WEB' ? machine.account?.username : machine.shortCode}
                  </span>

                  {/* 기기 초기화 버튼 */}
                  <div className='absolute top-[50%] -translate-y-[50%] right-20'>
                    {isMachineRunning && (
                      <Button
                        iconPath={ICON.REFRESH_BK}
                        text='초기화'
                        color={ButtonColor.secondary}
                        onClick={() => setOpenInitConfirm(true)}
                      />
                    )}
                  </div>
                </div>
              }
            />
          )}

          {/* 기기 상태 */}
          <FormControl
            name='기기 상태'
            control={
              <div>
                <Badge type={machine.status} label={MachineStatusKo[machine.status]} />
              </div>
            }
          />
          {/* 기기 연결 상태 */}
          <FormControl
            name='기기 연결 상태'
            control={
              <div className='relative'>
                <Badge type={machine.connectStatus} label={MachineConnectStatusKo[machine.connectStatus]} />

                {/* 기기 재시작 버튼 */}
                <div className='absolute top-[50%] -translate-y-[50%] right-20'>
                  {isFileManagerOn && (
                    <Button
                      iconPath={ICON.REFRESH_BK}
                      text='재시작'
                      color={ButtonColor.secondary}
                      onClick={() => setOpenResetConfirm(true)}
                    />
                  )}
                </div>
              </div>
            }
          />
        </section>
      </div>

      {openDiscardConfirm && (
        <ConfirmModal
          message='현재 기기를 폐기하시겠습니까?'
          onClickCancel={() => setOpenDiscardConfirm(false)}
          onClickOk={onClickDiscardMachine}
        />
      )}

      {openInitConfirm && (
        <ConfirmModal
          message='기기를 초기화 하시겠습니까?'
          onClickCancel={() => setOpenInitConfirm(false)}
          onClickOk={onClickInit}
        />
      )}

      {openResetConfirm && (
        <ConfirmModal
          message='기기를 재시작 하시겠습니까?'
          onClickCancel={() => setOpenResetConfirm(false)}
          onClickOk={onClickReset}
        />
      )}

      {openAlert && <AlertModal width='w-[380px]' message={alertMessage} closeModal={() => setOpenAlert(false)} />}
    </section>
  );
};

export default MachineDetailBasicInfo;
