import FormControl from 'components/common/form-control/FormControl';
import SimpleSelectBox from 'components/common/select/SimpleSelectBox';
import { MachineLocationForm } from 'types/feature/machine/machine';
import { changeFloor } from 'utils/map/control/floor';
import { ControllerProps } from './controller.type';

const FloorController = (props: ControllerProps) => {
  const onChange = async (floorId: string) => {
    props.setSelectedFloorId(floorId);
    await changeFloor(floorId);
    props.setMachineLocationForm((prev: MachineLocationForm) => ({
      ...prev,
      floorId,
    }));
  };

  return (
    <div className='flex w-full p-5 border-b'>
      <FormControl
        name='층'
        control={
          <SimpleSelectBox
            width={200}
            selected={props.floorId || props.floors?.[0]?.id}
            options={props.floors.map(floor => ({
              value: floor.id,
              label: floor.mainName || floor.name.ko,
            }))}
            onChange={onChange}
          />
        }
      />
    </div>
  );
};

export default FloorController;
