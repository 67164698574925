import React from 'react';
import { FLEX_CENTER } from 'styles/flex';

type ModalContainerProps = {
  children: React.ReactElement;
};

const ModalContainer = ({ children }: ModalContainerProps) => {
  // 모달 ui용 컴포넌트
  return (
    <div
      onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      className={`fixed top-0 left-0 z-50 ${FLEX_CENTER.column} w-screen h-screen bg-black bg-opacity-40`}
    >
      <div className={`${FLEX_CENTER.column} w-full h-full`}>{children}</div>
    </div>
  );
};
export default ModalContainer;
