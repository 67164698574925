import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import DateScheduleCalendar from 'components/common/calendar/date/DateScheduleCalendar';
import FormControl from 'components/common/form-control/FormControl';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import ModalContainer from 'components/common/modal/container/ModalContainer';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import SelectBox, { OptionType } from 'components/common/select/SelectBox';
import Title, { TextType } from 'components/common/title/Title';
import { DISPLAY_TYPE } from 'data/display';
import { DISPLAY_TYPE_OPTIONS } from 'data/select-box/options';
import useKeyboard from 'hooks/common/useKeyboard';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { useState } from 'react';
import useFilterStore from 'stores/filter';
import { ModalType } from 'types/common/modalType';
import { ScreenType } from 'types/feature/content/contents';
import { DisplayStatus, DisplayType, GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { convertDateToString } from 'utils/date/dateUtils';

type Props = {
  closeModal: () => void;
  screensaver: GetScreensaver;
  refetchScreensaver: (pointId: string, screenType: ScreenType) => Promise<void>;
  openDoneAlert: () => void;
  selectedPoint: CommonOptionType | undefined;
};

const ScheduleEditModal = ({ screensaver, closeModal, refetchScreensaver, openDoneAlert, selectedPoint }: Props) => {
  // ! 상시노출일 경우에는 endDate를 startDate로 넣어줌
  const initialEndDate =
    screensaver.displayType === DisplayType.ALWAYS_ON ? new Date(screensaver.startDate) : new Date(screensaver.endDate);

  const [type, setType] = useState<DisplayType>(screensaver.displayType as DisplayType);
  const [startDate, setStartDate] = useState<Date | null>(new Date(screensaver.startDate));
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { updateScreensaver } = useScreensaver();

  const filterStore = useFilterStore();

  // valid 후 confirm 노출
  const handleConfirmPopup = () => {
    if (validDisplay()) {
      setOpenConfirmModal(true);
    }
  };

  useKeyboard({ modalType: ModalType.confirm, closeModal, callback: handleConfirmPopup });

  // 노출 타입
  const handleChangeDisplayType = (option: OptionType) => {
    const displayType = option.value as DisplayType;
    setType(displayType);
    setStartDate(new Date(screensaver.startDate));
  };

  // 날짜 valid
  const validDate = (date: Date | null) => {
    if (!date) {
      setOpenAlert(true);
      setAlertMessage('날짜를 입력해주세요.');
      return false;
    }

    return true;
  };

  // 시작 날짜 valid
  const validStartDate = (type: string, date: Date | null) => {
    if (!startDate || !endDate || !date) {
      return;
    }

    if (type === 'start') {
      if (date > endDate) {
        setOpenAlert(true);
        setAlertMessage('시작일은 종료일보다 클 수 없습니다.');
        return false;
      }

      return true;
    }

    if (type === 'end') {
      if (startDate > date) {
        setOpenAlert(true);
        setAlertMessage('시작일은 종료일보다 클 수 없습니다.');
        return false;
      }

      return true;
    }
  };

  // 상시
  const handleDate = (date: Date | null) => {
    if (validDate(date)) {
      setStartDate(date);
    }
  };

  // 기간 시작일
  const handleStartDate = (startDate: Date | null) => {
    if (validDate(startDate)) {
      if (validStartDate('start', startDate)) {
        setStartDate(startDate);
      }
    }
  };

  // 기간 종료일
  const handleEndDate = (endDate: Date | null) => {
    if (validDate(startDate)) {
      if (validStartDate('end', endDate)) {
        setEndDate(endDate);
      }
    }
  };

  // 스크린세이버 노출 기간 수정
  const handleUpdate = async () => {
    try {
      if (!screensaver) return;

      const updateData = {
        displayType: type,
        startDate: convertDateToString(startDate),
        endDate: endDate ? convertDateToString(endDate) : convertDateToString(startDate),
      };

      await updateScreensaver(screensaver.id, updateData);
      await refetchScreensaver(selectedPoint?.value || '', filterStore.scheduleScreenType);

      closeModal();
      openDoneAlert();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setAlertMessage('스케줄 수정에 실패하였습니다.');
          setOpenAlert(true);
        }
      }
    }
  };

  // 기간 valid
  const validDisplay = () => {
    // 기간노출인데 종료 날짜가 없을 경우
    if (type === DisplayType.DURATION && !endDate) {
      setAlertMessage('종료날짜가 필요합니다.');
      setOpenAlert(true);

      return false;
    }

    return true;
  };

  return (
    <>
      <ModalContainer>
        <div className='flex flex-col w-auto h-auto gap-6 py-9'>
          <header className={`${TextType.h3_bold} w-full px-10`}>스케줄 수정</header>

          <Border borderColor={BorderColor.light_gray} />

          <div className='flex flex-col gap-2.5 px-10'>
            <div className='flex items-center w-full gap-2.5'>
              <span className='w-[100px]'>
                <Title textType={TextType.h4} text='타이틀' />
              </span>
              <div className={`${TextType.body1} break-all line-clamp-1`}>{screensaver.content.title}</div>
            </div>

            <div className='flex items-center w-full gap-2.5'>
              <FormControl
                name='노출 설정'
                labelSize={100}
                required
                control={
                  <div className='flex items-start justify-start gap-4'>
                    <SelectBox
                      width='w-[100px]'
                      options={[...DISPLAY_TYPE_OPTIONS]}
                      onChangeSelect={handleChangeDisplayType}
                      placeholder={DISPLAY_TYPE[screensaver.displayType]}
                    />

                    <DateScheduleCalendar
                      type={type}
                      handleDate={handleDate}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      startDate={startDate}
                      endDate={endDate}
                      disabled={screensaver.displayStatus === DisplayStatus.DISPLAY}
                    />
                  </div>
                }
              />
            </div>
          </div>

          <Border borderColor={BorderColor.light_gray} />

          <div className='flex items-center justify-center h-14 gap-2.5'>
            <Button size={120} text='취소' color={ButtonColor.secondary} onClick={closeModal} />
            <Button size={120} text='저장' color={ButtonColor.primary} onClick={handleConfirmPopup} />
          </div>
        </div>
      </ModalContainer>

      {openConfirmModal && (
        <ConfirmModal
          message='스크린세이버 스케줄을 수정하시겠습니까?'
          onClickOk={handleUpdate}
          onClickCancel={() => setOpenConfirmModal(false)}
        />
      )}

      {openAlert && <AlertModal message={alertMessage} closeModal={() => setOpenAlert(false)} />}
    </>
  );
};
export default ScheduleEditModal;
