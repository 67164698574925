import { AccountInfo } from 'types/common/accountInfo';
import { create } from 'zustand';

type AccountInfoProps = {
  accountInfo: AccountInfo | null;
  setAccountInfo: (accountInfo: AccountInfo | null) => void;
};

const useAccountInfoStore = create<AccountInfoProps>((set, get) => ({
  accountInfo: null,
  setAccountInfo: (accountInfo: AccountInfo | null) => set(() => ({ accountInfo })),
}));

export default useAccountInfoStore;
