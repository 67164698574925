import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { useParams } from 'react-router-dom';
import { ApiResponse } from 'types/api/response';
import {
  BusinessHours,
  ClosedDayObject,
  ClosedDays,
  ClosedDayTypeObject,
  CommonForm,
  Point,
  PointPutForm,
  Zoom,
} from 'types/feature/workspace/workspace';

const usePoint = () => {
  const { api } = useApi();
  const { workspaceId } = useParams();

  const getMyPoints = async () => {
    const result = await api.get<ApiResponse<Point[]>>(Endpoint.points, {
      params: {
        workspaceId,
      },
    });

    if (result.data) {
      return result.data;
    }
  };

  const getPoints = async (workspaceId: string) => {
    const result = await api.get<ApiResponse<Point[]>>(Endpoint.points, {
      params: {
        workspaceId,
      },
    });

    if (result.data) {
      return result.data;
    }
  };

  const getPoint = async (pointId: string) => {
    const result = await api.get<ApiResponse<Point>>(Endpoint.point.replace(':pointId', pointId));
    return result.data;
  };

  const patchPoint = async (id: string, point: BusinessHours | ClosedDays) => {
    await api.patch(Endpoint.points.concat(`/${id}`), point);
  };

  const patchMainPoint = async (pointId: string) => {
    const result = await api.patch<ApiResponse<any>>(`${Endpoint.mainPoint.replace(':pointId', pointId)}`);
    return result;
  };

  const putPointV2 = async (pointId: string, pointForm: PointPutForm) => {
    const result = await api.put<ApiResponse<Point>>(`${Endpoint.points}/${pointId}`, pointForm);
    return result;
  };

  const putPointMapPosition = async (pointId: string, commonForm: CommonForm) => {
    const result = await api.patch<ApiResponse<Point>>(
      Endpoint.pointMapPosition.replace(':pointId', pointId),
      commonForm,
    );
    return result;
  };

  const putPointMapWebPosition = async (pointId: string, zoom: Zoom) => {
    const result = await api.patch<ApiResponse<Point>>(Endpoint.pointMapWebPosition.replace(':pointId', pointId), zoom);
    return result;
  };

  const putPointMapMobilePosition = async (pointId: string, zoom: Zoom) => {
    const result = await api.patch<ApiResponse<Point>>(
      Endpoint.pointMapMobilePosition.replace(':pointId', pointId),
      zoom,
    );
    return result;
  };

  const patchPointOperation = async (id: string, point: BusinessHours | ClosedDays) => {
    await api.patch(Endpoint.pointOperation.replace(':pointId', id), point);
  };

  const patchClosedDayType = async (id: string, type: ClosedDayTypeObject) => {
    const result = await api.patch(Endpoint.pointClosedDayType.replace(':pointId', id), type);
    return result;
  };

  const addClosedDay = async (id: string, type: ClosedDayObject) => {
    await api.post(Endpoint.pointClosedDay.replace(':pointId', id), type);
  };

  const deleteClosedDay = async (id: string, type: ClosedDayObject) => {
    await api.post(Endpoint.pointDeleteClosedDay.replace(':pointId', id), type);
  };

  const getCalender = async (pointId: string, targetMonth: string) => {
    const result = await api.get<ApiResponse<any>>(Endpoint.pointCalendar.replace(':pointId', pointId), {
      params: {
        targetMonth,
      },
    });

    if (result.data) {
      return result.data;
    }
  };

  const getHoliDays = async (pointId: string, type: string) => {
    const result = await api.get<ApiResponse<any>>(Endpoint.pointClosedDay.replace(':pointId', pointId), {
      params: {
        type,
      },
    });

    if (result.data) {
      return result.data;
    }
  };

  const patchOperationUsed = async (id: string, closedDayUsed: boolean) => {
    await api.patch(Endpoint.pointOperationUsed.replace(':pointId', id), { closedDayUsed });
  };

  return {
    patchPoint,
    getMyPoints,
    getPoints,
    getPoint,
    patchMainPoint,
    putPointV2,
    putPointMapPosition,
    putPointMapMobilePosition,
    putPointMapWebPosition,
    patchPointOperation,
    patchClosedDayType,
    addClosedDay,
    deleteClosedDay,
    getCalender,
    getHoliDays,
    patchOperationUsed,
  };
};

export default usePoint;
