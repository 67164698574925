import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import Title, { TextType } from 'components/common/title/Title';
import useFloor from 'hooks/feature/floor/useFloor';
import useMachine from 'hooks/feature/machine/useMachine';
import usePoint from 'hooks/feature/point/usePoint';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { ScreenType } from 'types/feature/content/contents';
import { Floor } from 'types/feature/floor/floor';
import { Machine, MachineType } from 'types/feature/machine/machine';
import { Point, Workspace } from 'types/feature/workspace/workspace';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import MachineDetailBasicInfo from './MachineDetailBasicInfo';
import MachineDetailControllers from './MachineDetailControllers';
import MachineDetailMap from './MachineDetailMap';
import { ControllerProps } from './controllers/controller.type';
import useAccountInfoStore from 'stores/accountInfo';

const MachineDetailContainer = () => {
  // hook
  const { workspaceId, machineId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { getFloors } = useFloor();
  const { getMachine } = useMachine();
  const { getWorkspace } = useWorkspace();
  const { getPoint } = usePoint();
  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [point, setPoint] = useState<Point>();
  const [floorList, setFloorList] = useState<Floor[]>([]);
  const [machine, setMachine] = useState<Machine>();
  const [screenType, setScreenType] = useState<ScreenType>('HORIZONTAL');
  const [mapLoading, setMapLoading] = useState(true);
  // store
  const accountInfoStore = useAccountInfoStore();

  useEffect(() => {
    if (!workspaceId) return;
    if (!accountInfoStore.accountInfo?.admin) {
      navigate(replaceWorkspaceId(RoutePath.dashboard, workspaceId));
    }
  }, [accountInfoStore.accountInfo]);

  /**
   * 기기 목록으로 돌아간다
   */
  const moveToMachineList = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.machines, workspaceId), {
        state: {
          ...location.state,
        },
      });
    }
  };

  /**
   * 워크스페이스랑를 조회한다
   */
  const fetchWorkspace = async (workspaceId: string) => {
    try {
      const workspace = await getWorkspace(workspaceId);
      if (workspace) {
        setWorkspace(workspace);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response && e.response.status === 400) {
          console.error('워크스페이스를 조회할 수 없습니다.', e);
        }
      }
    }
  };

  /**
   * 층 목록을 조회한다.
   * @param {string} pointId
   */
  const fetchFloors = async (pointId: string) => {
    const floors = await getFloors(pointId);
    if (floors) {
      setFloorList(floors);
    }
  };

  /**
   * 포인트를 조회한다.
   * @param {string} pointId
   */
  const fetchPoint = async (pointId: string) => {
    const point = await getPoint(pointId);
    if (point) {
      setPoint(point);
    }
  };

  /**
   * 기기를 조회한다.
   */
  const fetchMachine = async (machineId: string) => {
    const machine = await getMachine(machineId);
    if (machine) {
      setMachine(machine);
      setScreenType(machine.screenType);

      await fetchFloors(machine.pointId);
      await fetchPoint(machine.pointId);

      return machine;
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspace(workspaceId);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (machineId) {
      fetchMachine(machineId);
    }
  }, [machineId]);

  if (!machine || !workspace || !point) {
    return <> </>;
  }

  // props
  const controllerProps: ControllerProps = {
    machine,
    floors: floorList,
    fetchMachine,
    screenType,
    setScreenType,
    mapLoading,
    point,
  };

  return (
    <div className='w-full pb-20'>
      {/* 기본 정보 */}
      <MachineDetailBasicInfo
        machineType={workspace.machineType as MachineType}
        machine={machine}
        fetchMachine={fetchMachine}
      />

      {/* 기기위치 정보 */}
      <section className='flex flex-col gap-5 py-10 border-gray-cc'>
        <div className='flex items-center gap-2.5'>
          <Title text='기기 위치 정보' textType={TextType.h3_bold} />
          <div className='text-xs'>
            현재 기기가 위치한 빌딩은 <b>{point.name.ko || ''}</b>입니다.
          </div>
        </div>

        {machine.status === 'EXPIRED' && <Title text='폐기된 기기는 수정할 수 없습니다.' textType={TextType.h4} />}

        <section className='h-[666px] flex border relative'>
          {/* 폐기된 기기는 비활성화하는 스크린 */}
          {machine.status === 'EXPIRED' && <div className='absolute z-20 w-full h-full opacity-25 bg-gray-cc' />}
          {/* 지도 */}
          <MachineDetailMap
            workspace={workspace}
            floorList={floorList}
            machine={machine}
            setMapLoading={setMapLoading}
            mapLoading={mapLoading}
            screenType={screenType}
            point={point}
          />
          {/* 설정들 */}
          <MachineDetailControllers {...controllerProps} />
        </section>
      </section>

      <div className='flex justify-center gap-2.5 pt-10'>
        <Button text='목록으로' color={ButtonColor.secondary} size={120} onClick={moveToMachineList} />
      </div>
    </div>
  );
};
export default MachineDetailContainer;
