import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ModalContainer from 'components/feature/workspace/modal/ModalContainer';
import AlertNoKeybordPopup from './AlertNoKeybordPopup';
import Title from 'components/common/title/Title';
// import useHandleKeyDown from 'hooks/common/useHandleKeyDown';
import useLanguage from 'hooks/feature/language/useLanguage';
import { ChangeEvent, useEffect, useState } from 'react';
import { DisplayNameUpdateData, Language, LanguageExposureName, LanguageName } from 'types/feature/multi-lang/language';
import { TextType } from 'types/common/text';

type Props = {
  languageList: Language[];
  closePopup: () => void;
};

// TODO: 텍스트팝업
const UpdateDisplayLanguagePopup = ({ languageList, closePopup }: Props) => {
  // state
  const [disabled, setDisabled] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [currentLangs, setCurrentLangs] = useState<Language[]>(languageList);

  // hook
  const { updateLanguageDisplayName } = useLanguage();

  /**
   * @desc 저장 validation
   * @returns
   */
  const validate = () => {
    const isDisplayNameEmpty = currentLangs.some((lang: Language) => lang.displayName.length === 0);

    if (isDisplayNameEmpty) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  };

  /**
   * @desc 노출 언어 저장 로직
   */
  const handleClickUpdateDisplayName = async () => {
    if (currentLangs) {
      currentLangs.map(async (lang: Language) => {
        const id = lang.id;
        const payload: DisplayNameUpdateData = { displayName: lang.displayName };

        try {
          await updateLanguageDisplayName(id, payload);
        } catch (error) {
          setOpenAlertMessage(true);
          setAlertMessage('언어 노출명을 수정 할 수 없습니다.');
        } finally {
          closePopup();
        }
      });
    }
  };

  /**
   * @desc 입력값에 따른 updatedLangs
   * @param e
   */
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    let newData = currentLangs.filter((p: Language) => p.code === name)[0];
    newData = { ...newData, displayName: value };

    setCurrentLangs(prev => {
      const updatedLangs = prev.map(lang =>
        lang.code === newData.code ? { ...lang, displayName: newData.displayName } : lang,
      );
      return updatedLangs;
    });
  };

  useEffect(() => {
    // displayName 가 빈 텍스트 일 때 기본값을 넣어준다.
    setCurrentLangs(prev => {
      const updatedLangs = prev.map(lang =>
        lang.displayName === '' ? { ...lang, displayName: LanguageExposureName[lang.code] } : lang,
      );
      return updatedLangs;
    });
  }, []);

  useEffect(() => {
    validate();
  }, [currentLangs]);

  // const { handleKeyDown } = useHandleKeyDown({
  //   closeModal: closePopup,
  //   callback: handleClickUpdateDisplayName,
  // });

  return (
    <>
      <ModalContainer>
        <div
          className='w-[480px]  bg-white flex flex-col'
          // tabIndex={-1} // 포커스를 받을 수 있도록 설정
          // onKeyDown={handleKeyDown}
        >
          <div className='w-full px-10 border-b py-7'>
            <Title text='언어 노출명 수정' textType={TextType.h3_bold} />
          </div>
          <div className='px-10 border-b py-7 flex flex-col gap-[10px]'>
            {currentLangs &&
              currentLangs
                .sort((a: Language, b: Language) => a.sortOrder - b.sortOrder)
                .map(language => {
                  return (
                    <div key={language.id} style={{ display: 'flex', gap: '10px' }}>
                      <div className='flex items-center w-[100px] h-[36px] gap-[4px] text-gray-44 text-[14px] font-medium leading-[20px] tracking-[-0.28px]'>
                        {LanguageName[language.code]}
                      </div>
                      <div className='flex items-center h-[36px] px-[8px] gap-[10px] flex-[1_0_0] border border-gray-99 bg-white'>
                        <input
                          type='text'
                          value={language.displayName}
                          className='text-gray-44 text-[14px] font-normal leading-[20px] tracking-[-0.28px]'
                          onChange={e => onChange(e)}
                          name={language.code}
                          style={{ width: '100%', height: '100%', border: 'none' }}
                        />
                      </div>
                    </div>
                  );
                })}
          </div>
          <div className='flex justify-center gap-3 px-10 py-7 '>
            <Button text='취소' color={ButtonColor.secondary} onClick={closePopup} />
            <Button
              disabled={disabled}
              text='저장'
              color={ButtonColor.primary}
              onClick={handleClickUpdateDisplayName}
            />
          </div>
        </div>
      </ModalContainer>
      {openAlertMessage && <AlertNoKeybordPopup message={alertMessage} onClick={() => setOpenAlertMessage(false)} />}
    </>
  );
};

export default UpdateDisplayLanguagePopup;
