import { ChangeEvent, RefObject } from 'react';

export type InputProps = {
  min?: string;
  max?: string;
  placeholder?: string;
  type?: 'text' | 'password' | 'number' | 'time';
  readOnly?: boolean;
  defaultValue?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: 'username' | 'current-password';
  id?: string;
  inputRef?: RefObject<HTMLInputElement>;
  name?: string;
  textStyle?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  inputStyle?: string;
  size?: keyof typeof InputSize;
  useWordLimit?: boolean;
  limit?: number;
};

export const InputSize = {
  default: 'w-full',
  60: 'w-[60px] h-[30px]',
  100: 'w-[100px] h-[30px]',
  120: 'w-[120px]',
  128: 'w-[128px]',
  96: 'w-24',
  200: 'w-[200px]',
  224: 'w-56',
  288: 'w-72',
  360: 'w-90',
  382: 'w-[382px]',
  384: 'w-96',
};

export enum InputText {
  default = 'text-body1 font-body leading-body1 placeholder:text-body1 placeholder:font-body1 leading-body1',
}
