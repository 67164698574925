import { DEFAULT_TEXT } from 'styles/text';

const HolidayGuide = () => {
  return (
    <div className='h-9 flex gap-2.5 items-center'>
      <span className={DEFAULT_TEXT}>
        <span className='text-gray-77'>** 달력을 클릭하여 휴무일을 설정해주세요.</span>
      </span>
    </div>
  );
};

export default HolidayGuide;
