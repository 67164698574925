import React, { useState } from 'react';
import Title from 'components/common/title/Title';
import BusinessHour from '../business-hour/BusinessHour';
import { TextType } from 'types/common/text';
import { Point } from 'types/feature/workspace/workspace';
import PointTab from '../basic/PointTab';
import Border from 'components/common/border/Border';
import Holiday from '../holiday/Holiday';
import usePoint from 'hooks/feature/point/usePoint';

type Props = {
  pointId: string;
  point: Point | null;
  fetchPoint: (id: string) => void;
};

const PointOperationSetting = ({ pointId, point, fetchPoint }: Props) => {
  // const [isHoliday, setHoliday] = useState<boolean>();
  const { patchOperationUsed } = usePoint();

  const handleChangeOption = async (closedDaysUsed: boolean) => {
    try {
      if (!point) return;

      await patchOperationUsed(pointId, closedDaysUsed);
      await fetchPoint(pointId);
    } catch (error) {
      console.error(error);
    }
  };

  if (!point) {
    return <> </>;
  }

  return (
    <section className='flex flex-col gap-10 p-10 bg-white border border-gray-ea'>
      <Title text='운영시간 및 휴무일' textType={TextType.h2} />
      {/* 포인트 탭 */}
      <div className='flex flex-col p-10 bg-white gap-9 '>
        {/* 운영시간 */}
        <BusinessHour point={point} pointId={pointId} fetchPoint={fetchPoint} />
        <Border />
      </div>
      <div className='flex flex-col p-10 bg-white gap-9 '>
        {/* 휴무일 */}
        <Holiday point={point} handleChangeOption={handleChangeOption} />
      </div>
    </section>
  );
};

export default PointOperationSetting;
