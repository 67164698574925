import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import Title from 'components/common/title/Title';
import AlertPopup from 'components/feature/workspace/popup/AlertPopup';
import ConfirmPopup from 'components/feature/workspace/popup/ConfirmPopup';
import usePoint from 'hooks/feature/point/usePoint';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { TextType } from 'types/common/text';
import { Zoom } from 'types/feature/workspace/workspace';
import { validNumber } from 'utils/common/validNumber';
import { validDefaultZoom, validMinMaxZoom } from 'utils/common/validZoom';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  mobileForm: Zoom;
  setMobileForm: Dispatch<SetStateAction<Zoom | null>>;
  fetchPoint: (id: string) => void;
  pointId: string;
};

const PointMobileMapPosition = ({ mobileForm, setMobileForm, pointId, fetchPoint }: Props) => {
  // state
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { putPointMapMobilePosition } = usePoint();

  const onSaveMobile = async () => {
    try {
      if (!mobileForm) return;

      // 줌값 유효성 처리
      const minZoom = Number(mobileForm.mapMinZoom);
      const maxZoom = Number(mobileForm.mapMaxZoom);
      const defaultZoom = Number(mobileForm.zoom);
      if (!validMinMaxZoom(minZoom, maxZoom)) {
        setOpenAlert(true);
        setAlertMessage(`지도 zoom 기본값은 최소값과\n최대값 사이의 숫자만 가능합니다.`);
        setOpenConfirm(false);
        return;
      }

      if (!validDefaultZoom(defaultZoom, minZoom, maxZoom)) {
        setOpenAlert(true);
        setAlertMessage(`zoom값은 15 ~ 22 사이의 값만 입력 가능합니다.`);
        setOpenConfirm(false);
        return;
      }

      await putPointMapMobilePosition(pointId, mobileForm);
      await fetchPoint(pointId);
      setOpenConfirm(false);
      setOpenAlert(true);
      setAlertMessage('지도 기본 설정값을 저장하였습니다.');
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlert(true);
        setAlertMessage('지도 기본 설정값을 수정할 수 없습니다.');
      });
    }
  };

  const onChangeMobileForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }

    setMobileForm(prev => {
      if (!prev) return null;
      return { ...prev, [name]: value };
    });
  };

  return (
    <section className='flex flex-col items-start gap-8 px-10 py-6 border border-[#CCC] w-full'>
      <Title text='모바일' textType={TextType.h3_bold} />

      <div className='flex flex justify-between w-full'>
        <div className='flex flex-col gap-5'>
          <FormControl
            name='줌 레벨'
            control={
              <div className='flex gap-10'>
                <div className='flex gap-[10px] items-center'>
                  <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최소값</div>

                  <Input
                    size={96}
                    name='mapMinZoom'
                    placeholder='15~22'
                    onChange={onChangeMobileForm}
                    min='0'
                    max='24'
                    value={mobileForm.mapMinZoom}
                  />
                </div>

                <div className='flex gap-[10px] items-center'>
                  <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>기본값</div>
                  <Input
                    size={96}
                    name='zoom'
                    placeholder='15~22'
                    onChange={onChangeMobileForm}
                    min='0'
                    max='24'
                    value={mobileForm.zoom}
                  />
                </div>

                <div className='flex gap-[10px] items-center'>
                  <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최대값</div>
                  <Input
                    size={96}
                    placeholder='15~22'
                    name='mapMaxZoom'
                    onChange={onChangeMobileForm}
                    min='0'
                    max='24'
                    value={mobileForm.mapMaxZoom}
                  />
                </div>
              </div>
            }
          />
        </div>

        <div className={`flex justify-center`}>
          <Button text='저장' type='button' color={ButtonColor.primary} onClick={() => setOpenConfirm(true)} />
        </div>
      </div>
      {openConfirm && (
        <ConfirmPopup
          message='지도 기본 설정을 저장하시겠습니까?'
          onClickOk={onSaveMobile}
          onClickCancel={() => {
            setOpenConfirm(false);
            setOpenAlert(false);
          }}
        />
      )}

      {/* 성공/실패 팝업 */}
      {openAlert && <AlertPopup onClick={() => setOpenAlert(false)} message={alertMessage} />}
    </section>
  );
};

export default PointMobileMapPosition;
