import MapInfo from './map/MapInfo';
import NaviSetting from './navi/NaviSetting';
import WorkspaceSetting from './workspace/WorkspaceSetting';
import { ChangeEvent, useEffect, useState } from 'react';
import { Workspace } from 'types/feature/workspace/workspace';
import { useParams } from 'react-router-dom';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import UrlSetting from './url/UrlSetting';
import { SelectOption } from 'types/common/selectOption';
import useAccountInfoStore from 'stores/accountInfo';

const BasicSetting = () => {
  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [mainBuildingId, setMainBuildingId] = useState<string>('');
  const [buildingOptions, setBuildingOptions] = useState<SelectOption[]>([]);

  // hook
  const { getWorkspace } = useWorkspace();
  const { workspaceId } = useParams();

  // store
  const accountInfoStore = useAccountInfoStore();

  /**
   * 메인 빌딩을 변경한다.
   */
  const onChangeMainBuilding = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setMainBuildingId(value);
  };

  // 워크스페이스 정보 조회
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);

      if (result) {
        setWorkspace(result);
      }
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspace();
    }
  }, [workspaceId]);

  // building id, options
  useEffect(() => {
    if (workspace && workspace.points) {
      const options = workspace.points.map(point => ({ id: point.id, name: point.name.ko, main: point.main }));
      const filteredBuilding = options.filter(option => option.main === true)?.[0];
      setMainBuildingId(filteredBuilding.id);
      setBuildingOptions(options);
    }
  }, [workspace]);

  if (!workspace) return <> </>;

  return (
    <div className='flex flex-col justify-start w-full h-full gap-12'>
      {/* 지도 정보 */}
      <MapInfo />

      {/* 워크스페이스 */}
      <WorkspaceSetting
        onChangeMainBuilding={onChangeMainBuilding}
        workspace={workspace}
        buildingOptions={buildingOptions}
        buildingId={mainBuildingId}
        fetchWorkspace={fetchWorkspace}
      />

      {/* URL 설정 */}
      <UrlSetting workspace={workspace} fetchWorkspace={fetchWorkspace} />

      {/* 모의보행 설정 */}
      {accountInfoStore.accountInfo?.admin && <NaviSetting />}
    </div>
  );
};

export default BasicSetting;
