import QRCode from 'qrcode';
import { useRef } from 'react';
import useToastStore from 'stores/toast';

const useQrDownload = () => {
  // ref
  const shareToastTimerRef = useRef(null);

  // store
  const toastStore = useToastStore();

  // 태그 생성 후 파일 다운로드
  const downloadFile = (title: string, url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = `${title}.png`;
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // HTML Canvas 요소에 QR 코드 그리기 (브라우저 환경)
  const generateQr = (qrUrl: string, callback: (dataUrl: string) => void) => {
    QRCode.toDataURL(qrUrl, (error, url) => {
      if (error) {
        return console.error('QR 이미지 생성 에러', error);
      }

      callback(url);
    });
  };

  // QR 다운로드
  const downQrImage = (title: string, qrUrl: string) => {
    generateQr(qrUrl, (dataUrl: string) => {
      downloadFile(title, dataUrl);

      // toast open
      toastStore.setToastPopupMessage('QR 이미지를 다운로드했습니다.');
      toastStore.setIsToastOpen(true);
      // toast 용 타이머 ref 설정
      toastStore.setToastTimerRef(shareToastTimerRef);
    });
  };

  return { downQrImage };
};
export default useQrDownload;
