import { FileType } from 'types/common/file/file';
import { ChangedStatus } from 'types/common/status';
import { ScreenTypeCode } from 'types/feature/content/contents';
import { DisplayType } from 'types/feature/screensaver/getScreensaver';

export const SCREEN_TYPE_OPTIONS = [
  { label: '세로형', value: ScreenTypeCode.VERTICAL },
  { label: '가로형', value: ScreenTypeCode.HORIZONTAL },
];

export const FILE_TYPE_OPTIONS = [
  { label: '이미지', value: FileType.IMAGE },
  { label: '동영상', value: FileType.VIDEO },
];

export const DISPLAY_TYPE_OPTIONS = [
  { label: '상시', value: DisplayType.ALWAYS_ON },
  { label: '기간', value: DisplayType.DURATION },
];

export const CHANGED_STATUS_OPTIONS = [
  { label: '전체', value: ChangedStatus.ALL },
  { label: '추가', value: ChangedStatus.ADD },
  { label: '수정', value: ChangedStatus.MODIFY },
];
