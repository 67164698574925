import { Floor } from 'types/feature/floor/floor';
import { ScreenType } from '../content/contents';

export type MachineType = 'WEB' | 'DESKTOP';

export enum MachineTypeKo {
  WEB = '웹형',
  DESKTOP = '설치형',
}

export type MachinePlaceType = 'CLIENT' | 'CLIENT_OFFICE' | 'DEVELOPER' | 'ETC';

export enum MachinePlaceTypeKo {
  CLIENT = '고객사',
  CLIENT_OFFICE = '고객사 사무실',
  DEVELOPER = '개발자',
  ETC = '기타',
}

export type MachineStatus = 'PREPARED' | 'RUNNING' | 'EXPIRED' | 'NONE';

export enum MachineStatusKo {
  PREPARED = '미배치',
  RUNNING = '운영중',
  EXPIRED = '폐기',
  NONE = 'none',
}

export type MachineConnectStatus = 'ON' | 'WARNING' | 'OFF';

export enum MachineConnectStatusKo {
  ON = 'ON',
  WARNING = 'CHECK',
  OFF = 'OFF',
}

export type MachineAccount = {
  username: string;
  password: string;
};

export type MachineMapPosition = {
  rotation: string;
  centerPositionX: string;
  centerPositionY: string;
  zoom: string;
};

export type OptionalMachineMapPosition = Partial<MachineMapPosition>;

export type Machine = {
  id: string;
  idSuffix: string;
  name: string;
  description: string;
  pointId: string;
  position: {
    x: string;
    y: string;
  };
  connected: boolean;
  floor: Floor;
  type: MachineType;
  placeType: MachinePlaceType;
  status: MachineStatus;
  connectStatus: MachineConnectStatus;
  updatedDate: string;
  account?: MachineAccount;
  shortCode: string;
  screenType: ScreenType;
  mapPosition: MachineMapPosition;
};

export type UpdatedMachine = {
  machineId: string;
  connected: boolean;
};

export type MachineLocationForm = {
  name: string;
  description: string;
  positionX: string;
  positionY: string;
  screenType: ScreenType;
  mapPosition: MachineMapPosition;
  floorId: string;
};

export type MachinePostForm = {
  pointId: string;
} & MachineLocationForm;

export type PatchMachine = {
  description?: string;
  positionX?: string;
  positionY?: string;
  screenType?: ScreenType;
  mapPosition?: OptionalMachineMapPosition;
};
