import { Dispatch, SetStateAction, useState } from 'react';
import { WorkspaceForm } from 'types/feature/workspace/workspace';
import Badge from '../../../common/badge/Badge';

type Props = {
  zoomButtonUsed: boolean;
  setWorkspaceForm: Dispatch<SetStateAction<WorkspaceForm>>;
};

const ToggleZoomButtonUsed = ({ zoomButtonUsed, setWorkspaceForm }: Props) => {
  const [toggleZoomButtonUsed, setToggleZoomButtonUsed] = useState(zoomButtonUsed);

  const handleToggleMobileUsed = () => {
    setToggleZoomButtonUsed(!toggleZoomButtonUsed);
    setWorkspaceForm((prev: WorkspaceForm) => ({
      ...prev,
      zoomButtonUsed: !toggleZoomButtonUsed,
    }));
  };

  return (
    <div
      className={`
      w-20 
      flex justify-start items-center
      `}
      onClick={handleToggleMobileUsed}
    >
      <Badge
        height='h-9'
        label={toggleZoomButtonUsed ? '사용' : '미사용'}
        type={toggleZoomButtonUsed ? 'USED' : 'NONE'}
        cursorStyle='pointer'
      />
    </div>
  );
};

export default ToggleZoomButtonUsed;
