import React from 'react';
import Title from 'components/common/title/Title';
import { TextType } from 'types/common/text';

type Props = {
  headerText: string;
};

const MenuPopupHeader = ({ headerText }: Props) => {
  return (
    <div className='flex h-[60px] px-10 w-full border-b border-[#222] items-center'>
      <Title text={headerText} textType={TextType.h3_bold} />
    </div>
  );
};
export default MenuPopupHeader;
