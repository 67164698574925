import SimpleSelectBox from 'components/common/select/SimpleSelectBox';
import { useState } from 'react';
import { Floor } from 'types/feature/floor/floor';
import { changeFloor } from 'utils/map/control/floor';

type Props = {
  floors: Floor[];
  floorId: string;
};

const FloorSelectBox = (props: Props) => {
  const [floorId, setFloorId] = useState(props.floorId);

  const onChange = async (value: string) => {
    setFloorId(value);
    await changeFloor(value);
  };

  return (
    <div className='absolute z-50 bg-white bottom-6 right-5'>
      <SimpleSelectBox
        direction='UP'
        options={props.floors.map(floor => ({
          value: floor.id,
          label: floor.name.ko,
        }))}
        onChange={onChange}
        selected={floorId}
      />
    </div>
  );
};

export default FloorSelectBox;
