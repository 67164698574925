import SettingNavigation from 'components/feature/navigation/SettingNavigation';
import useScroll from 'hooks/common/useScroll';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
const SettingContainer = () => {
  const location = useLocation();
  const [currentMenu, setCurrentMenu] = useState(RoutePath.basicSetting);

  useScroll({ currentMenu });

  useEffect(() => {
    setCurrentMenu(location.pathname as RoutePath);
  }, [location.pathname]);

  return (
    <div className='flex flex-col w-full h-full '>
      {/* navi */}
      <div className='relative top-0 left-0 flex items-center justify-center w-full h-full'>
        <div className='flex w-[1400px]'>
          <div className='flex w-[200px]'>
            <SettingNavigation setCurrentMenu={setCurrentMenu} />
          </div>
          <div className='flex flex-col w-full min-h-[860px] p-10'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingContainer;
