import LOADING_ITEM_1 from 'assets/icon/loading/loading-item-1.svg';
import LOADING_ITEM_2 from 'assets/icon/loading/loading-item-2.svg';
import LOADING_ITEM_3 from 'assets/icon/loading/loading-item-3.svg';

import INTRO from 'assets/intro.svg';
import LOGO_GRAY from 'assets/logo/logo-gray.svg';
import LOGO_P_GRAY from 'assets/logo/logo-p-gray.svg';
import LOGO_P_WHITE from 'assets/logo/logo-p-white.svg';
import LOGO_P from 'assets/logo/logo-p.svg';
import LOADING from 'assets/loading/loading.gif';
import LOADING_BACKGROUND from 'assets/icon/loading/loading-background.svg';
import LOADING_ARROW from 'assets/icon/loading/loading-arrow.svg';

import THUMBNAIL_DEFAULT from 'assets/images/thumb_default.png';
import MAP from 'assets/images/map.svg';
import MAP_CENTER from 'assets/images/center.svg';

import ARROW_UP_SQUARE from 'assets/select/arrow-up-square.svg';
import ARROW_DOWN_SQUARE from 'assets/select/arrow-up-square.svg';

import PREVIEW_MENU_LOGO from 'assets/setting/preview-logo.svg';
import PREVIEW_MAP from 'assets/setting/preview-map.svg';
import PREVIEW_SCREENSAVER from 'assets/setting/preview-screensaver.svg';
import PREVIEW_TENANT_LIST from 'assets/setting/preview-tenant-list.svg';
import PREVIEW_TENANT_POPUP from 'assets/setting/preview-tenant-popup.svg';
import SERVICE_LOGO from 'assets/setting/service-logo.png';
import TRASH from 'assets/base/trash.svg';
import DEFAULT_THUMBNAIL from 'assets/default/default-thumbnail.svg';

export const IMAGES = {
  INTRO,
  LOGO_P_WHITE,
  LOGO_GRAY,
  LOGO_P,
  LOGO_P_GRAY,

  LOADING_ITEM_1,
  LOADING_ITEM_2,
  LOADING_ITEM_3,
  LOADING_BACKGROUND,
  LOADING_ARROW,

  LOADING,
  THUMBNAIL_DEFAULT,
  MAP,
  MAP_CENTER,

  ARROW_UP_SQUARE,
  ARROW_DOWN_SQUARE,

  PREVIEW_MENU_LOGO,
  PREVIEW_MAP,
  PREVIEW_TENANT_POPUP,
  PREVIEW_TENANT_LIST,
  PREVIEW_SCREENSAVER,
  SERVICE_LOGO,

  TRASH,
  DEFAULT_THUMBNAIL,
};
