import React, { ChangeEvent } from 'react';

type RadioProps = {
  value: string;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const RadioSimple = ({ value, label, disabled, checked, onChange }: RadioProps) => {
  return (
    <>
      <input
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        type='radio'
        id={label}
        value={value}
        name='radio'
      />
      {label && <label htmlFor={label}>{label}</label>}
    </>
  );
};
export default RadioSimple;
