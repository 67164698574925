import FormControl from 'components/common/form-control/FormControl';
import SimpleSelectBox from 'components/common/select/SimpleSelectBox';
import { SCREEN_TYPE_FILTERS } from 'constants/filters';
import { ScreenType } from 'types/feature/content/contents';
import { MachineLocationForm } from 'types/feature/machine/machine';
import { ControllerProps } from './controller.type';

const ScreenTypeController = (props: ControllerProps) => {
  const onChange = (value: string) => {
    props.setMachineLocationForm((prev: MachineLocationForm) => ({
      ...prev,
      screenType: value as ScreenType,
    }));
  };

  return (
    <div className='p-5 border-b'>
      <FormControl
        name='기기 타입'
        control={
          <SimpleSelectBox
            width={200}
            // height='h-[30px]'
            options={SCREEN_TYPE_FILTERS}
            selected={props.machineLocationForm.screenType}
            onChange={onChange}
          />
        }
      />
    </div>
  );
};

export default ScreenTypeController;
