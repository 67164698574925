import FormControl from 'components/common/form-control/FormControl';
import { ControllerProps } from './controller.type';
import { TextType } from 'components/common/title/Title';

const FloorEditController = (props: ControllerProps) => {
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='층'
        labelColor='text-gray-77'
        labelStyle={TextType.h4_bold}
        control={
          <div
            className={`
            h-[30px] flex items-center ${TextType.body1}
            `}
          >
            {props.machine.floor.name.ko}
          </div>
        }
      />
    </div>
  );
};

export default FloorEditController;
