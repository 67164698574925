import LOGOUT from 'assets/header/logout.svg';
import ARROW_DOWN_ACTIVE from 'assets/icon/arrow/arrow-down-active.svg';
import ARROW_LEFT from 'assets/icon/arrow/arrow-left.svg';
import ARROW_NEXT_ABLE from 'assets/icon/arrow/arrow-next-able.svg';
import ARROW_NEXT_DISABLE from 'assets/icon/arrow/arrow-next-disable.svg';
import ARROW_PREV_DISABLE from 'assets/icon/arrow/arrow-prev-disable.svg';
import ARROW_RIGHT_NO_PADDING from 'assets/icon/arrow/arrow-right-thin.svg';
import ARROW_RIGHT from 'assets/icon/arrow/arrow-right.svg';
import ARROW_UP_ACTIVE from 'assets/icon/arrow/arrow-up-active.svg';
import ARROW_EMPTY_DOWN from 'assets/icon/arrow/arrow-empty-down.svg';
import ARROW from 'assets/icon/arrow/arrow.svg';
import DELETE_WHITE from 'assets/icon/delete-white.svg';
import DELETE from 'assets/icon/delete.svg';
import HOME from 'assets/icon/home.svg';
import INFO_GRAY from 'assets/icon/info-gray.svg';
import INFO_RED from 'assets/icon/info-red.svg';
import INFO from 'assets/icon/info.svg';
import PLUS_WHITE from 'assets/icon/plus-white.svg';
import PENCIL_WHITE from 'assets/button/pencil-white.svg';
import REFRESH_GRAY from 'assets/icon/refresh-gray.svg';
import REFRESH_BK from 'assets/icon/refresh-black.svg';
import CANCEL from 'assets/icon/cancel.svg';
import BLACK_CHECK_BOX from 'assets/icon/success.svg';
import TRASH from 'assets/icon/trash.svg';
import CALENDAR from 'assets/icon/calendar-black.svg';
import CONTENT_IMAGE from 'assets/icon/content/content-image.svg';
import CONTENT_VIDEO from 'assets/icon/content/content-video.svg';
import ADD_BUTTON from 'assets/button/add-black.svg';
import CLOSE_BUTTON from 'assets/button/close-white.svg';
import DELETE_BUTTON from 'assets/button/trash-white.svg';
import CALENDAR_BUTTON from 'assets/button/calendar-white.svg';
import DRAGGABLE_BUTTON from 'assets/button/draggable-btn.svg';
import CHECK_BLUE_CIRCLE from 'assets/icon/check-blue-circle.svg';
import LOCATION_MAP from 'assets/icon/location.svg';
import RELOAD_MACHINE from 'assets/icon/refresh-machine.svg';
import RELOAD_MACHINE_DISABLED from 'assets/icon/refresh-machine-disabled.svg';
import CLOSE from 'assets/icon/close.svg';
import ALERT from 'assets/icon/alert.svg';
import NO_CHANGED_TENANT from 'assets/icon/no-changed-tenant.svg';
import CARD from 'assets/icon/custom-page/card.svg';
import LIST from 'assets/icon/custom-page/list.svg';
import PREV_BTN from 'assets/icon/button/prev-btn.svg';
import NEXT_BTN from 'assets/icon/button/next-btn.svg';
import CLOCK from 'assets/icon/clock.svg';
import DASHBOARD_MAP from 'assets/icon/map/dashboard-map.svg';
import ARROW_INCREASE from 'assets/icon/arrow/arrow-increase.svg';
import ARROW_DECREASE from 'assets/icon/arrow/arrow-decrease.svg';
import DOWNLOAD from 'assets/icon/download.svg';
import TOAST_COMPLETE from 'assets/icon/toast/toast-complete.svg';
import WORKSPACE from 'assets/icon/workspace.svg';
import LOGOUT2 from 'assets/icon/logout.svg';
import HOME2 from 'assets/icon/home2.svg';
import SETTING from 'assets/icon/setting.svg';
import COPY_BUTTON from 'assets/button/copy-button.svg';
import SUCCESS_BUTTON from 'assets/button/success.svg';
import CANCEL_BUTTON from 'assets/button/close-white.svg';
import PENCIL_BUTTON from 'assets/button/pencil-white.svg';
import CURRENT_POSITION from 'assets/setting/current-position.svg';
import DOWNLOAD_BUTTON from 'assets/base/download-button.svg';

export const ICON = {
  SUCCESS_BUTTON,
  CANCEL_BUTTON,
  PENCIL_BUTTON,

  TOAST_COMPLETE,

  LOGOUT,

  ARROW,
  ARROW_UP_ACTIVE,
  ARROW_DOWN_ACTIVE,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_RIGHT_NO_PADDING,
  ARROW_EMPTY_DOWN,

  ARROW_PREV_DISABLE,
  ARROW_NEXT_ABLE,
  ARROW_NEXT_DISABLE,

  INFO,
  INFO_RED,
  DELETE_WHITE,
  DELETE,

  HOME,
  REFRESH_GRAY,
  REFRESH_BK,
  PLUS_WHITE,
  INFO_GRAY,

  PENCIL_WHITE,
  CANCEL,
  BLACK_CHECK_BOX,
  TRASH,
  CALENDAR,

  CONTENT_IMAGE,
  CONTENT_VIDEO,

  DELETE_BUTTON,
  ADD_BUTTON,
  CLOSE_BUTTON,
  CALENDAR_BUTTON,
  DRAGGABLE_BUTTON,

  CHECK_BLUE_CIRCLE,

  LOCATION_MAP,
  RELOAD_MACHINE,
  RELOAD_MACHINE_DISABLED,

  CLOSE,
  ALERT,

  NO_CHANGED_TENANT,

  CARD,
  LIST,

  PREV_BTN,
  NEXT_BTN,

  CLOCK,

  DASHBOARD_MAP,

  ARROW_INCREASE,
  ARROW_DECREASE,

  DOWNLOAD,

  WORKSPACE,
  LOGOUT2,
  HOME2,
  SETTING,

  CURRENT_POSITION,
  DOWNLOAD_BUTTON,
  COPY_BUTTON,
};
