import { ICON } from 'constants/icons';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Poi } from 'types/feature/poi/poi';
import { Workspace } from 'types/feature/workspace/workspace';
import { hasParkingInCategories } from 'utils/common/hasParkingInCategories';
import TenantCustomField from './custom-field/TenantCustomField';
import TenantDescription from './description/TenantDescription';
import TenantDetailImage from './image/TenantDetailImage';
import KeywordsViewer from './keywords/KeywordsViewer';
import TenantPopupTitle from './title/TenantPopupTitle';
import UrlCopyPopup from './UrlCopyPopup';

type Props = {
  poiContent: Poi;
  floor: string;
  building: string;
};

const Badge = ({ popupType }: { popupType: string }) => {
  return (
    <div
      className='flex h-[24px] px-[10px] justify-center items-center rounded-[2px] bg-state-gray_bg'
      style={{ position: 'absolute', top: '10px', right: '10px' }}
    >
      <span className='overflow-hidden text-gray-77 text-ellipsis text-[14px]'>{popupType}</span>
    </div>
  );
};
const PoiContentPreview = ({ poiContent, floor, building }: Props) => {
  // hook
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();

  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [isOpenUrlCopy, setIsOpenUrlCopy] = useState(false);

  const fetchWorkspace = async (workspaceId: string) => {
    const result = await getWorkspace(workspaceId);
    if (result) {
      setWorkspace(result);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspace(workspaceId);
    }
  }, [workspaceId]);

  if (!workspace) {
    return <> </>;
  }

  return (
    <>
      {
        /* 기본형 혹은 축약형 팝업 */
        poiContent.popupType === 'SMALL' ? (
          <div className='flex flex-col items-center bg-white border' style={{ height: '200px', position: 'relative' }}>
            {/* 모바일 URL 복사 버튼 */}
            {workspace.mobileUsed && (
              <div className='absolute top-2.5 left-2.5'>
                <div
                  onClick={() => setIsOpenUrlCopy(true)}
                  className='flex w-[24px] h-[24px] p-1.5 justify-center items-center rounded-[3px] border border-gray-cc bg-white cursor-pointer'
                >
                  <img src={ICON.COPY_BUTTON} alt='복사' className='h-full' />
                </div>
              </div>
            )}

            <Badge popupType={'축약형'} />
            {/* 가로형 제목 */}
            <TenantPopupTitle
              name={poiContent.name}
              floor={floor}
              building={building}
              categories={poiContent.categories}
            />
          </div>
        ) : (
          <div className='flex flex-col items-center bg-white border' style={{ height: '100%', position: 'relative' }}>
            {/* 모바일 URL 복사 버튼 */}
            {workspace.mobileUsed && (
              <div className='absolute top-2.5 left-2.5'>
                <div
                  onClick={() => setIsOpenUrlCopy(true)}
                  className='flex w-[24px] h-[24px] p-1.5 justify-center items-center rounded-[3px] border border-gray-cc bg-white cursor-pointer'
                >
                  <img src={ICON.COPY_BUTTON} alt='복사' className='h-full' />
                </div>
              </div>
            )}

            <Badge popupType={'기본형'} />

            {/* 가로형 제목 */}
            <TenantPopupTitle
              name={poiContent.name}
              floor={floor}
              building={building}
              categories={poiContent.categories}
            />
            {/* 이미지  */}
            <TenantDetailImage mainLogoUri={poiContent.subLogoUri} />

            <div className='flex flex-col items-center w-full'>
              <div
                className='h-full w-full flex flex-col overflow-y-auto mt-[20px] mb-[20px]'
                style={{ padding: '0 20px 0px 20px', height: '155px' }}
              >
                {/*  상세 정보 */}
                {poiContent?.description && <TenantDescription desc={poiContent.description} />}
                {/* 커스텀 필드 */}
                <TenantCustomField customFields={poiContent.customField} />
                {/* 커스텀 필드 SNS  */}
                {/* <TenantSnsField customFields={poiContent.customField} /> */}
              </div>
              <div className='w-full mb-[20px] px-[20px]'>
                {/* 키워드 */}
                {poiContent.keywords.length > 0 && <KeywordsViewer keywords={poiContent.keywords} />}
              </div>
            </div>
          </div>
        )
      }

      {/* URL 복사 버튼 */}
      {isOpenUrlCopy && (
        <UrlCopyPopup
          poiContent={poiContent}
          closePopup={() => {
            setIsOpenUrlCopy(false);
          }}
          mobileEndpoint={workspace.mobileEndpoint}
          parkingUsed={workspace.parkingUsed}
          hasParking={hasParkingInCategories(poiContent.categories)}
        />
      )}
    </>
  );
};

export default PoiContentPreview;
