import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent, useEffect } from 'react';
import { MachineLocationForm } from 'types/feature/machine/machine';
import { ControllerProps } from './controller.type';
import { drawKioskMarker } from 'utils/map/control/marker';

const PositionController = (props: ControllerProps) => {
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    props.setMachineLocationForm((prev: MachineLocationForm) => ({
      ...prev,
      [name]: value,
    }));
  };

  // 기기 등록시 입력한 기기의 위치값이 바뀔 때, 마커 위치를 변경
  useEffect(() => {
    drawKioskMarker({
      x: Number(props.machineLocationForm.positionX),
      y: Number(props.machineLocationForm.positionY),
    });
  }, [props.machineLocationForm]);
  return (
    <div className='flex items-start p-5 border-b'>
      <FormControl
        name='기기 위치 좌표'
        labelSize={120}
        control={
          <div className='flex gap-5'>
            <FormControl
              name='X'
              labelSize='none'
              control={
                <Input
                  size={120}
                  placeholder='X 좌표'
                  name='positionX'
                  value={props.machineLocationForm.positionX}
                  onChange={onChange}
                />
              }
            />
            <FormControl
              name='Y'
              labelSize='none'
              control={
                <Input
                  size={120}
                  placeholder='Y 좌표'
                  name='positionY'
                  value={props.machineLocationForm.positionY}
                  onChange={onChange}
                />
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default PositionController;
