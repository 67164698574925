import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import AlertModal from 'components/common/modal/alert/AlertModal';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handle400Error } from 'utils/error/handle400Error';
import { changeRotation } from 'utils/map/control/camera';
import { ControllerProps } from './controller.type';

const RotationEditController = (props: ControllerProps) => {
  // hook
  const { patchMachine } = useMachine();
  const { machineId } = useParams();
  // state
  const [editMode, setEditMode] = useState(false);
  const [rotation, setRotation] = useState(props.machine.mapPosition.rotation);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    changeRotation(Number(value));
    setRotation(value);
  };

  const updateRotation = async () => {
    try {
      if (isEmpty(rotation)) {
        setOpenAlertPopup(true);
        setAlertMessage('입력된 값이 없습니다.');
        return;
      }
      if (machineId) {
        await patchMachine(machineId, {
          mapPosition: {
            rotation,
          },
        });
        await props.fetchMachine(machineId);
        setEditMode(false);
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };

  const onClickCancel = () => {
    setEditMode(false);

    const rotation = props.machine.mapPosition.rotation;
    setRotation(rotation);
    changeRotation(Number(rotation));
  };

  return (
    <div className='flex items-center justify-between p-5 border-b'>
      <FormControl
        labelColor='text-gray-77'
        labelStyle={TextType.h4_bold}
        name='방향각'
        control={
          editMode ? (
            <Input size={100} textStyle={TextType.h5} value={rotation} type='number' onChange={onChange} />
          ) : (
            <div className='flex items-center justify-start bg-bg-f9 w-[100px] h-[30px] px-2 text-h5 text-gray-99 '>
              {props.machine.mapPosition.rotation}
            </div>
          )
        }
      />

      {editMode ? (
        <div className='flex gap-2.5'>
          <div className='h-6' onClick={updateRotation}>
            <img className='h-full' src={ICON.SUCCESS_BUTTON} alt='edit' />
          </div>
          <div className='h-6' onClick={onClickCancel}>
            <img className='h-full' src={ICON.CANCEL_BUTTON} alt='edit' />
          </div>
        </div>
      ) : (
        <div className='h-6' onClick={() => setEditMode(!editMode)}>
          <img className='h-full' src={ICON.PENCIL_BUTTON} alt='edit' />
        </div>
      )}
      {openAlertPopup && <AlertModal message={alertMessage} closeModal={() => setOpenAlertPopup(false)} />}
    </div>
  );
};

export default RotationEditController;
