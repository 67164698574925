import GuideDescription from 'components/common/description/GuideDescription';
import Title from 'components/common/title/Title';
import { ZOOM_SETTING_GUIDES } from 'data/guide/setting';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommonForm, Point, Workspace, Zoom } from 'types/feature/workspace/workspace';
import PointKioskMapPosition from './PointKioskMapPosition';
import PointMobileMapPosition from './PointMobileMapPosition';
import PointTab from '../basic/PointTab';
import { TextType } from 'types/common/text';
import PointCommonMapPosition from './PointCommonMapPosition';

type Props = {
  pointId: string;
  point: Point | null;
  fetchPoint: (id: string) => void;
};
const PointMapPositionSetting = ({ pointId, point, fetchPoint }: Props) => {
  // state
  const [commonForm, setCommonForm] = useState<CommonForm | null>(null);
  const [webForm, setWebForm] = useState<Zoom | null>(null);
  const [mobileForm, setMobileForm] = useState<Zoom | null>(null);
  const [workspace, setWorkspace] = useState<Workspace>();

  // hook
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();

  /**
   * @desc 워크스페이스 정보 조회 : workspace mobileUsed 유뮤를 확인하기 위함
   */
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);
      if (result) {
        setWorkspace(result);
      }
    }
  };

  useEffect(() => {
    if (point) {
      const { mapMinZoom, mapMaxZoom, mapPosition, machinePositionX, machinePositionY, mobile } = point;
      setCommonForm({
        machinePositionX: machinePositionX,
        machinePositionY: machinePositionY,
        mapPosition: {
          rotation: mapPosition.rotation,
          centerPositionX: mapPosition?.centerPositionX || '',
          centerPositionY: mapPosition?.centerPositionY || '',
        },
      });
      setWebForm({
        mapMinZoom,
        mapMaxZoom,
        zoom: mapPosition.zoom,
      });
      setMobileForm({
        mapMinZoom: mobile.mapMinZoom,
        mapMaxZoom: mobile.mapMaxZoom,
        zoom: mobile.mapPosition.zoom,
      });
    }
  }, [point]);

  useEffect(() => {
    fetchWorkspace();
  }, []);

  if (!mobileForm || !webForm || !commonForm || !point) {
    return <> </>;
  }

  return (
    <section className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
      <div className='flex flex-col gap-2.5'>
        <div className='flex items-center justify-start h-9'>
          <Title text='지도 기본 설정' textType={TextType.h2} />
        </div>
      </div>
      <GuideDescription guides={ZOOM_SETTING_GUIDES} width='w-[500px]' />

      {/* 포인트 탭 */}
      {/* <PointTab point={point} /> */}

      <div className='flex flex-col gap-8'>
        {/* 공통 */}
        <PointCommonMapPosition
          commonForm={commonForm}
          setCommonForm={setCommonForm}
          pointId={point.id}
          fetchPoint={fetchPoint}
        />
        {/* 키오스크 */}
        <PointKioskMapPosition webForm={webForm} setWebForm={setWebForm} pointId={point.id} fetchPoint={fetchPoint} />
        {/* 모바일  */}
        {workspace?.mobileUsed && (
          <PointMobileMapPosition
            mobileForm={mobileForm}
            setMobileForm={setMobileForm}
            pointId={point.id}
            fetchPoint={fetchPoint}
          />
        )}
      </div>
    </section>
  );
};
export default PointMapPositionSetting;
