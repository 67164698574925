import React from 'react';
import { TableHeader } from 'types/common/tableHeader';

type Props = {
  tableHead: TableHeader;
  ableToScroll?: boolean;
};

const CommonTableHeader = ({ tableHead, ableToScroll = false }: Props) => {
  return (
    <>
      <colgroup>
        {tableHead.map(({ width }, index) => (
          <col key={index} width={`${width}px`} />
        ))}
      </colgroup>

      <thead
        className={`${
          ableToScroll ? 'table w-full h-12 border-b-2 border-gray-44' : 'h-12 border-b-2 border-gray-44'
        } cursor-default`}
      >
        <tr className={`whitespace-nowrap text-gray-77 text-[13px] font-bold`}>
          {tableHead.map(({ label, direction, width }, index) => (
            <th
              key={index}
              style={{ width: width }}
              className={`${direction === 'left' && 'text-left'} text-inherit px-2.5`}
            >
              {label}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
};
export default CommonTableHeader;
