import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import CommonSelectBox from 'components/common/select/CommonSelectBox2';
import Textarea from 'components/common/textarea/Textarea';
import Title from 'components/common/title/Title';
import { typeOptions, useTypeOptions } from 'data/workspace/Workspace';
import usePoint from 'hooks/feature/point/usePoint';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useState } from 'react';
import ToggleParkingButtonUsed from './ToggleParkingButtonUsed';
import ToggleZoomButtonUsed from './ToggleZoomButtonUsed';
import AlertModal from 'components/common/modal/alert/AlertModal';
import { TextType } from 'types/common/text';
import { Workspace, WorkspaceBasicForm } from 'types/feature/workspace/workspace';
import { SelectOption } from 'types/common/selectOption';
import ConfirmPopup from 'components/feature/workspace/popup/ConfirmPopup';
import AlertPopup from 'components/feature/workspace/popup/AlertPopup';
import useAccountInfoStore from 'stores/accountInfo';

type Props = {
  workspace: Workspace;
  buildingOptions: SelectOption[];
  buildingId: string;
  fetchWorkspace: () => Promise<void>;
  onChangeMainBuilding: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const WorkspaceSetting = ({ workspace, buildingOptions, buildingId, fetchWorkspace, onChangeMainBuilding }: Props) => {
  // state
  const [isConfirm, setIsConfirm] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [basicForm, setBasicForm] = useState<WorkspaceBasicForm>({
    name: workspace.name || '',
    description: workspace.description || '',
    zoomButtonUsed: workspace.zoomButtonUsed || false,
    type: workspace.type || 'RETAIL',
    useType: workspace.useType || 'DRAFT',
    parkingUsed: workspace.parkingUsed || false,
  });

  // hook
  const { putWorkspaceBasic } = useWorkspace();
  const { patchMainPoint } = usePoint();

  // store
  const accountInfoStore = useAccountInfoStore();

  // 저장
  const onClickSave = () => {
    if (!basicForm.name) {
      setIsFail(true);
      setIsConfirm(false);
      return;
    }

    setIsConfirm(true);
  };

  // 워크스페이스 수정
  const updateWorkspace = async () => {
    try {
      await putWorkspaceBasic(workspace?.id || '', basicForm);
      await patchMainPoint(buildingId);
      await fetchWorkspace();

      setIsConfirm(false);
      setIsSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setIsFail(true);
        }
      }
    }
  };

  /**
   * 입력창 onChange (name, description)
   */
  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value, name } = e.target;

    setBasicForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  /**
   * 선택창 onChange (type, useType)
   */
  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value, id } = e.target;
    setBasicForm(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  const capitalizeFirstLetter = (str: string) => {
    if (!str) return ''; // 빈 문자열 처리
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <section className='flex flex-col gap-10 p-10 bg-white border border-gray-ea'>
      <Title text='워크스페이스' textType={TextType.h2} />

      <div className='flex flex-col gap-3'>
        <FormControl
          name='이름'
          required
          control={
            <>
              {accountInfoStore.accountInfo?.admin ? (
                <Input
                  size={288}
                  name='name'
                  placeholder='워크스페이스 이름을 입력하세요'
                  value={basicForm.name}
                  onChange={onChange}
                />
              ) : (
                <Input readOnly size={288} value={basicForm.name} />
              )}
            </>
          }
        />

        <FormControl
          name='상태'
          required
          control={
            accountInfoStore.accountInfo?.admin ? (
              <CommonSelectBox
                selectId='useType'
                width='w-[276px]'
                onChange={onChangeSelect}
                selected={basicForm.useType}
                options={useTypeOptions.map(({ id, name }) => {
                  return {
                    id,
                    value: name,
                  };
                })}
              />
            ) : (
              <Input readOnly size={288} value={capitalizeFirstLetter(basicForm.useType)} />
            )
          }
        />
        <FormControl
          name='타입'
          required
          control={
            accountInfoStore.accountInfo?.admin ? (
              <CommonSelectBox
                selectId='type'
                width='w-[276px]'
                onChange={onChangeSelect}
                selected={basicForm.type}
                options={typeOptions.map(({ id, name }) => {
                  return {
                    id,
                    value: name,
                  };
                })}
              />
            ) : (
              <Input readOnly size={288} value={capitalizeFirstLetter(basicForm.type)} />
            )
          }
        />
        <FormControl
          name='메인 빌딩'
          required
          control={
            <div className='flex flex-col gap-2.5'>
              {accountInfoStore.accountInfo?.admin ? (
                <CommonSelectBox
                  selectId='mainBuilding'
                  width='w-72'
                  onChange={onChangeMainBuilding}
                  selected={buildingId}
                  options={buildingOptions.map(({ id, name }) => {
                    return {
                      id,
                      value: name,
                    };
                  })}
                />
              ) : (
                <Input
                  readOnly
                  size={288}
                  value={buildingOptions.filter(buildingOption => buildingOption.id === buildingId)[0]?.name || ''}
                />
              )}
              {accountInfoStore.accountInfo?.admin && (
                <GuideDescription
                  manualInfo={'URL 접속 시, 메인으로 노출할 메인 빌딩을 설정합니다.'}
                  bgColor='none'
                  infoColor='default'
                />
              )}
            </div>
          }
        />

        <FormControl
          name='상세 정보'
          control={
            accountInfoStore.accountInfo?.admin ? (
              <Textarea
                name='description'
                value={basicForm.description}
                placeholder='상세 정보를 입력하세요.'
                onChange={onChange}
                width='w-[518px]'
              />
            ) : (
              <Textarea readOnly name='description' value={basicForm.description} width='w-[518px]' />
            )
          }
        />
        <FormControl
          name='주차위치 지정'
          control={
            accountInfoStore.accountInfo?.admin ? (
              <ToggleParkingButtonUsed zoomButtonUsed={basicForm.parkingUsed} setWorkspaceForm={setBasicForm} />
            ) : (
              <ToggleParkingButtonUsed
                readOnly
                zoomButtonUsed={basicForm.parkingUsed}
                setWorkspaceForm={setBasicForm}
              />
            )
          }
        />
        <FormControl
          name='줌버튼'
          control={
            accountInfoStore.accountInfo?.admin ? (
              <ToggleZoomButtonUsed zoomButtonUsed={basicForm.zoomButtonUsed} setWorkspaceForm={setBasicForm} />
            ) : (
              <ToggleZoomButtonUsed readOnly zoomButtonUsed={basicForm.parkingUsed} setWorkspaceForm={setBasicForm} />
            )
          }
        />
      </div>
      <div className='flex justify-end'>
        {accountInfoStore.accountInfo?.admin && (
          <Button text='저장' color={ButtonColor.point} size={80} onClick={onClickSave} />
        )}
      </div>
      {/* 확인 */}
      {isConfirm && (
        <ConfirmPopup
          message='워크스페이스 정보를 수정하시겠습니까?'
          onClickCancel={() => setIsConfirm(false)}
          onClickOk={updateWorkspace}
        />
      )}
      {/* 성공 */}
      {isSuccess && <AlertPopup message='수정되었습니다.' onClick={() => setIsSuccess(false)} />}
      {/* 실패 */}
      {isFail && <AlertPopup onClick={() => setIsFail(false)} message='워크스페이스 이름을 입력해주세요.' />}
    </section>
  );
};
export default WorkspaceSetting;
