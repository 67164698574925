import { PoiCategory } from 'types/feature/poi/poi';

type Props = { name: string; floor: string; building: string; categories: PoiCategory[] };

const TenantPopupTitle = ({ name, floor, building, categories }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: '12px 30px 3px 30px',
          color: '#444',
          fontSize: '24px',
          fontWeight: '700',
        }}
      >
        {name}
      </div>

      <div className='px-[30px] pt-[3px] pb-[12px]'>
        <div className='flex items-center gap-2 py-[4px]'>
          {/* 층 */}
          {floor.length > 0 && <div className='text-gray-44 text-[12px] font-bold'>{floor}</div>}

          {/* 부스 번호 - 전시일 경우 노출 */}
          {/* <div className='flex items-center'>
            <DotSpan /> <div className='text-gray-44 text-[12px] font-bold'>부스번호</div>
          </div> */}

          {(building.length > 0 || categories.length > 0) && <div className='w-[2px] h-[12px] bg-gray-cc' />}

          {/* 포인트 */}
          {building.length > 0 && (
            <div className='flex items-center text-[12px] text-gray-99'>
              <div>{building}</div>
            </div>
          )}

          {/* 카테고리 */}
          {categories && categories.length > 0 && (
            <div className='flex items-center text-[12px] text-gray-99 border px-2 h-[26px] border-gray-77'>
              <span className='text-gray-99 text-[14px] font-medium'>{categories[0].title.ko}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TenantPopupTitle;
